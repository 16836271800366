import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
const Card = ({ data }) => {
  const [start_time, setStartTime] = useState(null);
  const [filteredEvents, setFilteredEvents] = useState();

  useEffect(() => {
    if (data && data.length > 0) {
        const defaultStartTime = data[0].start_time;
      setStartTime(defaultStartTime);
    }
  }, [data]);

  useEffect(() => {
    if (data && data.length > 0 && start_time) {
      const filtered = data.filter((event) => event.start_time === start_time);
      console.log(filtered, "Filtered Events");
      setFilteredEvents(filtered);
    }
  }, [data, start_time]);



  return (
    <div className="gap-2 mt-5 mx-10 font-poppins">
      <div className="mb-10">
        <div className="flex justify-between cursor-pointer items-center mb-2">
          {/*<p className="font-poppins xl:text-base font-semibold text-sm">
            YOUR RECENT EVENTS
          </p>
          <Link to="/RecentEvent">
            <p className="text-[#707dff] underline xl:text-base font-poppins text-sm">
              See More
            </p>
          </Link>*/}
        </div>
        <div className="">
          <div className=" rounded-md ">
            <div className="grid xl:grid-cols-5 md:grid-cols-3 grid-cols-1 gap-4">
              {data && data.map((EventData, index) => (
                <Link to={`/eventDetail/${EventData.event_id}`}>
                  <div
                    key={index}
                    className="card-container shadow-md  bg-white  rounded-md hover:shadow-2xl flex flex-col max-h-[300px] gap-4 overflow-hidden"
                  >
                    <div className="card-image h-40 bg-cover bg-center" style={{backgroundImage: `url(${EventData?.thumbnail_path})`}}/>
                    {/*<img
                      src={EventData?.thumbnail_path
                      }
                      className="object-cover w-full rounded-md  h-[150px] "
                      alt={EventData.thumbnail_path}
                    />*/}
                    {/*<div className="flex flex-col p-3">*/}
                    <div className="card-body h-full flex flex-col p-4">
                      {/*<p className="font-poppins text-base text-center font-medium leading-5   h-[40px] flex justify-center items-center text-[#333333]">*/}
                      <p className="font-poppins text-base text-center font-bold mt-1">
                        {EventData.event_name}
                      </p>
                      {/*<p className="font-poppins text-sm text-center font-medium leading-6 mb-4 ">*/}
                      <p className="font-poppins text-sm text-center text-gray-600 mt-2">
                        {EventData.start_date} at {EventData.start_time}
                      </p>
                      {/*<p className="font-poppins text-sm text-center font-medium leading-6 mb-4 ">*/}
                      <p className="font-poppins text-sm text-center text-gray-600 mt-1">
                        {EventData.venue_name}
                      </p>
                      {/*<p className=" font-poppins text-sm text-center font-medium outline h-[40px] outline-[#707DFF] leading-9  rounded-full">
                        {" "}
                        £ {EventData.ticket_price}
                      </p>*/}
                      <p className="font-poppins text-sm text-center font-bold mt-2">
                        {EventData.ticket_price === "0.00" ? "Free" : `£${EventData.ticket_price}`}
                      </p>
                    </div>
                  </div>
                </Link>
              ))}

              <div></div>
            </div>
          </div>
        </div>
      </div>
      {/*<div className="mb-10">
        <div className="flex justify-between cursor-pointer items-center mb-2">
          <p className="font-poppins xl:text-base font-semibold text-sm">
            YOUR COMING EVENTS
          </p>
          <Link to="/upComing">
            <p className="text-[#707dff] underline xl:text-base font-poppins text-sm">
              See More
            </p>
          </Link>
        </div>

        <div className="">
          <div className=" rounded-md ">
            <div className="grid xl:grid-cols-5 md:grid-cols-3 grid-cols-1 gap-4">
              {data.map((EventData, index) => (
                <Link to={`/eventDetail/${index}`}>
                  <div
                    key={index}
                    className="shadow-md  bg-white  rounded-md hover:shadow-2xl gap-4"
                  >
                    <img
                      src={EventData?.thumbnail_path}
                      className="object-cover w-full rounded-md  h-[150px] "
                      alt={EventData.thumbnail_path}
                    />
                    <div className="flex flex-col p-3">
                      <p className="font-poppins text-base text-center font-medium leading-5   h-[40px] flex justify-center items-center text-[#333333]">
                        {EventData.event_name}
                      </p>
                      <p className="font-poppins text-sm text-center font-medium leading-6 mb-4 ">
                        {EventData.description}
                      </p>
                      <p className=" font-poppins text-sm text-center font-medium outline h-[40px] outline-[#707DFF] leading-9  rounded-full">
                        {" "}
                        £ {EventData.ticket_price}
                      </p>
                    </div>
                  </div>
                </Link>
              ))}

              <div></div>
            </div>
          </div>
        </div>
      </div>

      --/* top events by other organizers --
      <div className="mb-10">
        <div className="flex justify-between cursor-pointer items-center mb-2">
          <p className="font-poppins xl:text-base font-semibold text-sm">
            TOP EVENTS BY OTHER ORGANIZERS
          </p>
          <Link to="/topevent">
            <p className="text-[#707dff] underline xl:text-base font-poppins text-sm">
              See More
            </p>
          </Link>
        </div>

        <div className="">
          <div className=" rounded-md ">
            <div className="grid xl:grid-cols-5 md:grid-cols-3 grid-cols-1 gap-4">
              {/* {topEvent.slice(0, 5).map((event, index) => (
                <Link to={`/topUpComingEvent/${event.id}`} key={event.id}>
                  <div
                    className="shadow-md  bg-white  rounded-md hover:shadow-2xl"
                    key={index}
                  >
                    <img
                      src={event.image}
                      className="object-cover w-full rounded-md  h-[150px] "
                      alt={event.name}
                    />
                    <div className="flex flex-col p-3">
                      <p className="font-poppins text-base text-center font-medium leading-5   h-[40px] flex justify-center items-center text-[#333333]">
                        {event.name}
                      </p>
                      <p className="font-poppins text-sm text-center font-medium leading-6 ">
                        {event.time}
                      </p>
                      <p className="font-poppins text-sm text-center font-normal leading-6">
                        {event.location}
                      </p>
                      <p className=" font-poppins text-sm text-center font-medium outline  outline-[#707DFF] leading-9  rounded-full">
                        {event.price}
                      </p>
                    </div>
                  </div>
                </Link>
              ))} --}
              <div></div>
            </div>
          </div>
        </div>
      </div>

      {/* top events by other organizers --}
      <div className="mb-10">
        <div className="flex justify-between cursor-pointer items-center mb-2">
          <p className="font-poppins xl:text-base font-semibold text-sm">
            YOUR EXPIRED EVENTS
          </p>
          <Link to="/expire">
            <p className="text-[#707dff] underline xl:text-base font-poppins text-sm">
              See More
            </p>
          </Link>
        </div>

        <div className="">
          <div className=" rounded-md ">
            <div className="grid xl:grid-cols-5 md:grid-cols-3 grid-cols-1 gap-4">
              {/* {expireEvent.slice(0, 5).map((event, index) => (
                <Link to={`/expireEvent/${event.id}`} key={event.id}>
                  <div
                    className="shadow-md  bg-white  rounded-md hover:shadow-2xl"
                    key={index}
                  >
                    <img
                      src={event.image}
                      className="object-cover w-full rounded-md  h-[150px] "
                      alt={event.name}
                    />
                    <div className="flex flex-col p-3">
                      <p className="font-poppins text-base text-center font-medium leading-5   h-[40px] flex justify-center items-center text-[#333333]">
                        {event.name}
                      </p>
                      <p className="font-poppins text-sm text-center font-medium leading-6 ">
                        {event.time}
                      </p>
                      <p className="font-poppins text-sm text-center font-normal leading-6">
                        {event.location}
                      </p>
                      <p className=" font-poppins text-sm text-center font-medium outline  outline-[#707DFF] leading-9  rounded-full">
                        {event.price}
                      </p>
                    </div>
                  </div>
                </Link>
              ))} --}
              <div></div>
            </div>
          </div>
        </div>
      </div>*/}
    </div>
  );
};

export default Card;

// import React, { useState, useEffect } from 'react';
// import upComingEvent from '../../pages/Event/comingEvent/ComingEvent';

// const Card = () => {
//   const [events, setEvents] = useState([]);

//   useEffect(() => {
//     setEvents(upComingEvent);
//   }, []);

//   return (
//     <div>
//       {events.map((event, index) => (
//         <div key={index} className="event-item">
//           <img src={event.image} alt={event.name} />
//           <h3>{event.name}</h3>
//           <p>{event.time}</p>
//           <p>{event.location}</p>
//           <p>{event.price}</p>
//         </div>
//       ))}
//     </div>
//   );
// };

// export default Card;
