import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './redux/store';
import { GoogleOAuthProvider } from '@react-oauth/google';

const root = ReactDOM.createRoot(document.getElementById('root'));
//const clientId = process.env.REACT_APP_CLIENT_ID;
root.render(
  
  <Provider store={store}>
    <GoogleOAuthProvider clientId="743091634162-ee0k95etdgnhhfchkn4li4vq7per08bm.apps.googleusercontent.com">
         <App />
    </GoogleOAuthProvider>
  </Provider>
  

  
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
