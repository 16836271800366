import {
    FETCH_SUBCATEGORIES_REQUEST,
    FETCH_SUBCATEGORIES_SUCCESS,
    FETCH_SUBCATEGORIES_FAILURE,
  } from "../constant/subCategoryConstants";
  
  const initialState = {
    loading: false,
    subcategories: [],
    error: "",
  };
  
  const subCategoryReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_SUBCATEGORIES_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case FETCH_SUBCATEGORIES_SUCCESS:
        return {
          loading: false,
          subcategories: action.payload,
          error: "",
        };
      case FETCH_SUBCATEGORIES_FAILURE:
        return {
          loading: false,
          subcategories: [],
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default subCategoryReducer;
  