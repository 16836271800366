import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";

import "react-datepicker/dist/react-datepicker.css";
import { useFormik } from "formik";
import * as Yup from "yup"; // for form validation

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { venueRegister } from "../../redux/venue/VenueAction/VenueAction";

const RegisterVenueForm = () => {
  const dispatch = useDispatch();

  const initialValues = {
    venue_name: "",
    venue_capacity: "",
    flat_house_number: "",
    street_address: "",
    city_town: "",
    post_code: "",
    country: "",
    country: "",
  };

  const handleSubmit = (values, { resetForm, setSubmitting }) => {
    const formData = new FormData();
    formData.append("venue_name", values.venue_name);
    formData.append("venue_capacity", values.venue_capacity);
    formData.append(" flat_house_number", values.flat_house_number); // Use the selected category
    formData.append("street_address", values.street_address);
    formData.append("city_town", values.city_town);
    formData.append("post_code", values.post_code);
    formData.append("subcategory_id", values.subcategory_id);
    formData.append("county", values.county);
    formData.append("country", values.country);
    dispatch(venueRegister(formData))
      .then(() => {
        resetForm();
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <div className="w-full h-full font-poppins text-[#565656]">
      <div className="xl:flex  xl:gap:10 gap-10 flex flex-col xl:flex-row lg:flex-row ">
        <div className="w-full  min-w-0   ">
          {/* Right Section: Login Form */}
          <div className="flex flex-col w-full">
            <Formik initialValues={initialValues} onSubmit={handleSubmit}>
              {({ values, setFieldValue, isSubmitting, isValid, dirty }) => (
                <Form className="flex gap-20">
                  <div>
                    <div className="mb-4">
                      <p className="font-normal font-poppins text-base tracking-wider">
                        Venue Name
                      </p>
                      <p className="font-normal font-poppins text-sm tracking-wider"></p>

                      <Field
                        type="text"
                        name="venue_name"
                        placeholder="Event title goes here"
                        className="w-full text-sm p-3 rounded-full pl-4 font-poppins tracking-wide border border-[#0875E1] mt-2"
                      />
                    </div>

                    <div className="mb-4">
                      <p className="font-normal font-poppins text-base tracking-wider">
                        Venue Capacity
                      </p>

                      <Field
                        type="number"
                        name="venue_capacity"
                        placeholder="Egs:0.00"
                        className="w-full text-sm p-3 rounded-full pl-4 font-poppins tracking-wide border border-[#0875E1] mt-2"
                      />
                    </div>

                    <div className="mb-4">
                      <p className="font-normal font-poppins text-base tracking-wider">
                        Flat Number House *
                      </p>

                      <Field
                        type="text"
                        name="flat_house_number"
                        placeholder="Organizer name please"
                        className="w-full text-sm p-3 rounded-full pl-4 font-poppins tracking-wide border border-[#0875E1] mt-2 "
                      />
                    </div>
                    <div className="font-poppins text-base text-wider tracking-wider mb-4">
                      <p className="font-normal font-poppins text-base tracking-wider">
                        Street Address*
                      </p>
                      <Field
                        type="text"
                        name="street_address"
                        placeholder="0"
                        className="w-[96px] text-center text-sm p-3 rounded-full pl-4 font-poppins tracking-wide border border-[#0875E1] mt-2"
                      />

                      {/* Additional fields based on country selection */}
                    </div>
                    <div className="mb-4">
                      <p className="font-normal font-poppins text-base tracking-wider">
                        City/Town
                      </p>
                      <Field
                        type="text"
                        name="city_town"
                        placeholder="0"
                        className="w-[96px] text-center text-sm p-3 rounded-full pl-4 font-poppins tracking-wide border border-[#0875E1] mt-2"
                      />
                      {/* <Field
                        type="text"
                        name="category"
                        placeholder="Add your title"
                        className="w-full text-sm p-3 rounded-full pl-4 font-poppins tracking-wide border border-[#0875E1] mt-2"
                      /> */}
                      {/* <div className="flex">
                        {profile && profile.length > 0 ? (
                          profile.map((category) => (
                            <div key={category.category_id}>
                              <p
                                type="button"
                                onClick={() =>
                                  handleButtonClick(category, setFieldValue)
                                }
                                className={`py-2 px-3  m-1 border rounded-full ${
                                  selectedCategory === category
                                    ? "border-red-500"
                                    : "border-gray-300"
                                }`}
                              >
                                {category.category_name}
                              </p>
                            </div>
                          ))
                        ) : (
                          <p>No categories available.</p>
                        )}
                      </div> */}
                    </div>

                    <div className="mb-4">
                      <p className="font-normal font-poppins text-base tracking-wider">
                        post_code
                      </p>
                      <Field
                        type="number"
                        name="post_code"
                        placeholder="Egs:4460"
                        className="w-[96px] text-center text-sm p-3 rounded-full pl-4 font-poppins tracking-wide border border-[#0875E1] mt-2"
                      />
                    </div>

                    <div className="mb-4">
                      <p className="font-normal font-poppins text-base tracking-wider">
                        Country
                      </p>
                      <Field
                        type="text"
                        name="country"
                        placeholder="0"
                        className="w-[96px] text-center text-sm p-3 rounded-full pl-4 font-poppins tracking-wide border border-[#0875E1] mt-2"
                      />
                    </div>

                    <div className="mb-4">
                      <p className="font-normal font-poppins text-base tracking-wider">
                        County
                      </p>
                      <Field
                        type="text"
                        name="county"
                        placeholder="0"
                        className="w-[96px] text-center text-sm p-3 rounded-full pl-4 font-poppins tracking-wide border border-[#0875E1] mt-2"
                      />
                    </div>
                    {/* <div className="mb-4">
                      <p className="font-normal font-poppins text-base tracking-wider">
                        Capacity*
                      </p>

                      <Field
                        type="number"
                        name="capacity"
                        placeholder="0"
                        className="w-[96px] text-center text-sm p-3 rounded-full pl-4 font-poppins tracking-wide border border-[#0875E1] mt-2"
                      />
                    </div>
                  */}

                    <div className="flex justify-center items-center mb-4">
                      <button
                        type="submit"
                        className="h-10 bg-[#0875E1] rounded-full cursor-pointer w-36 p-2 font-poppins text-base tracking-wide text-white"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>

      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </div>
  );
};

export default RegisterVenueForm;
