import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom';
import { fetchEventTickets } from '../../../redux/CreateTicket/createTicketAction/getTicketAction';
import Navbar from '../../layout/Navbar/Navbar';
import { FaTicketAlt } from 'react-icons/fa';
import { FaMoneyBill1Wave } from 'react-icons/fa6';

function ViewTicket() {

    const { id } = useParams();
    const dispatch = useDispatch();
    const eventTickets = useSelector((state) => state.getTickets);
    const { loading, data, error } = eventTickets;
    console.log(eventTickets);

    useEffect(() => {
        if(id) {
            dispatch(fetchEventTickets(id))
        }
    }, [dispatch, id]);

  return (
    <div className="w-full h-full font-poppins text-[#565656] bg-[#f2f4f7]">
        <Navbar linkTo="/home" title="View tickets" />
    <div className="gap-2 mt-20 font-poppins">
        <div className="flex flex-col justify-between cursor-pointer items-center mb-2 bg-gray-300 md:bg-[#f2f4f7] w-full">
            {data?.length > 0 ? data.map((ticket) => (<div className='flex justify-between w-full'>
              <button
                className="bg-pink-200 hover:bg-pink-300 transform hover:scale-105 transition-transform duration-300 flex items-center justify-center rounded-full w-full py-2 m-5"
                >
                    {ticket.total_quantity} {ticket.ticket_category} Tickets <FaTicketAlt className="mx-2" />
                </button>
                <button
                className="bg-teal-200 hover:bg-teal-300 transform hover:scale-105 transition-transform duration-300 flex items-center justify-center rounded-full w-full py-2 m-5"
                >
                    £{Math.round(ticket.total_quantity * ticket.price)} Revenue Expected <FaMoneyBill1Wave className="ml-2" />
                </button>
                </div>
            )) : 
            <h3 className='text-center opacity-50'>No tickets created. </h3>
            }
        </div>
    </div>
    </div>
  )
}

export default ViewTicket
