import { GET_ORGANISER_EVENT_FAILURE, GET_ORGANISER_EVENT_REQUEST, GET_ORGANISER_EVENT_SUCCESS } from "../organiserEventAction/GetOrganiserEventConstant";
  
  
  const initialState = {
    loading: false,
    data: null,
    error: null,
  };
  
  const getOrganiserEventReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_ORGANISER_EVENT_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case GET_ORGANISER_EVENT_SUCCESS:
        return {
          ...state,
          loading: false,
          data: action.payload,
        };
      case GET_ORGANISER_EVENT_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default getOrganiserEventReducer;
  