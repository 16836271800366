import React, { useState, useEffect } from "react";
import {
  IoAlarmOutline,
  IoCreateOutline,
  IoLocationOutline,
  IoShareSocialOutline,
} from "react-icons/io5";
import Vector from "../../../../assets/icons/Vector.png";
import cash from "../../../../assets/icons/cash.png";

import { Link, useParams } from "react-router-dom";

const ExpireEvent = () => {
  const { id } = useParams();
  const [event, setEvent] = useState(null);

  //upcoming Event Detail
  useEffect(() => {
    fetch(`http://localhost:4000/expireEvent/${id}`)
      .then((response) => response.json())

      .then((data) => setEvent(data))

      .catch((error) => console.error("Error fetching data:", error));
  }, [id]);

  if (!event) {
    return <div>Loading...</div>;
  }

  return (
    <div className="w-full p-5">
      <div className="flex justify-center gap-2 font-poppins">
        <div className="w-full max-w-5xl">
          <div className="flex justify-between mb-5">
            <p className="text-base xl:text-lg font-poppins font-semibold leading-7 tracking-wider w-full max-w-lg">
              {event.event}
            </p>
            <div className="flex">
              <Link to={`/ExpireForm/${event.id}`}>
                <IoCreateOutline size={30} color="#0875E1" />
              </Link>
              <IoShareSocialOutline size={30} color="#0875E1" />
            </div>
          </div>
          <div className="flex flex-col gap-4">
            <div>
              <img
                src={event.image}
                alt={event.name}
                className="object-cover w-full rounded-xl max-h-[200px] lg:max-h-full"
              />
            </div>

            {/* Amount section */}
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-5 font-poppins font-semibold">
              <div className="shadow-xl h-[100px] text-center flex items-center justify-center p-2 rounded-xl hover:shadow-2xl">
                <p className="font-poppins text-base xl:text-base tracking-wider">
                  £10/Ticket
                </p>
              </div>
              <div className="shadow-xl h-[100px] text-center flex items-center justify-center p-2 rounded-xl hover:shadow-2xl">
                <div className="flex flex-col xl:flex-row items-center gap-2">
                  <p className="font-poppins text-base xl:text-base tracking-wider">
                    £ 25
                  </p>
                  <div className="flex items-center gap-2">
                    <img
                      src={Vector}
                      className="w-[25px] h-[25px] object-contain"
                      alt="sold"
                    />
                    <p className="font-poppins text-base xl:text-base tracking-wider">
                      Sold
                    </p>
                  </div>
                  <p className="font-poppins text-base xl:text-base tracking-wider">
                    £10/Ticket
                  </p>
                </div>
              </div>
              <div className="shadow-xl h-[100px] text-center flex items-center justify-center p-2 rounded-xl hover:shadow-2xl">
                <div className="flex flex-col xl:flex-row items-center gap-2">
                  <p className="font-poppins text-base tracking-wider">£240</p>
                  <img
                    src={cash}
                    className="w-[25px] h-[25px] object-contain"
                    alt="cash"
                  />
                  <p className="font-poppins text-base tracking-wider">
                    Revenue
                  </p>
                </div>
              </div>
            </div>

            {/* Text section */}
            <div>
              <p className="leading-9 text-base tracking-wider font-lighter font-poppins">
                This will be your event’s title. Your title will be used to help
                create your event’s summary, description, category, and tags -
                so be specific! This will be your event’s title. Your title will
                be used to help create your event’s summary, description,
                category, and tags - so be specific! This will be your event’s
                title. Your title will be used to help create your event’s
                summary, description, category, and tags - so be specific! This
                will be your event’s title. Your title will be used to help
                create your event’s summary, description, category, and tags -
                so be specific!
              </p>
            </div>

            {/* Image section */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
              <img
                src={event.image}
                alt="event.image"
                className="rounded-md w-full max-h-screen"
              />
              <img
                src={event.image}
                alt="rectangle1"
                className="rounded-md w-full max-h-screen"
              />
            </div>

            {/* Location section */}
            <div className="font-poppins">
              <p className="mb-4 text-base">Event Category:</p>
              <div className="flex flex-col gap-4">
                <div className="flex flex-col xl:flex-row justify-between gap-3">
                  <div className="flex gap-2 items-center">
                    <p className="text-base tracking-wider">Country:</p>
                    <p className="font-semibold text-base xl:text-base">
                      {event.country}
                    </p>
                  </div>
                  <p className="text-base tracking-wider">
                    Category:
                    <span className="font-semibold text-base xl:text-base">
                      {event.organizer}
                    </span>
                  </p>
                </div>

                <div className="flex gap-4 items-center font-poppins">
                  <IoLocationOutline size={25} />
                  <p className="text-base tracking-wider">Venue:</p>
                  <p className="font-semibold text-base xl:text-base">
                    {/* Hackney Bridge, London Central Somewhere */}
                    {event.line_1}
                  </p>
                </div>
                <div className="flex gap-4 items-center font-poppins">
                  <IoAlarmOutline size={25} />
                  <p className="text-base tracking-wider">Time:</p>
                  <p className="font-semibold text-base xl:text-base">
                    {event.startingTime} -{event.endingTime}
                  </p>
                </div>
                <div className="flex gap-4 items-center font-poppins">
                  <p className="text-base tracking-wider">Capacity:</p>
                  <p className="font-semibold text-base xl:text-base">
                    {event.capacity}
                  </p>
                </div>
                <div className="flex gap-4 items-center font-poppins">
                  <p className="text-base tracking-wider">External Links:</p>
                  <p className="font-semibold text-base xl:text-base">
                    {event.anyExternalLink}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExpireEvent;
