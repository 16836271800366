import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";

import user from "../../assets/site-images/user.png";
import logo from "../../assets/site-images/eventfullsunday.png";
import LeftSection from "../../components/layout/LeftSection/LeftSection";
import apiClient from "../../apiClient/apiClient";

const SuccessfulVerification = () => {
    // Extract uidb64 and token from the URL using useParams
    const { uid, token } = useParams();

    useEffect(() => {
        // Perform the activation API call only if uidb64 and token exist
        if (uid && token) {
            apiClient
                .post(`activate/${uid}/${token}/`)
                .then((response) => {
                    console.log("Account activated successfully:", response);
                })
                .catch((error) => {
                    console.error("Error activating account:", error);
                });
        }
    }, [uid, token]);

    return (
        <div className="flex flex-col lg:flex-row h-screen">
            {/* Left Section */}
            <LeftSection logo={logo} mainImage={user} mainText="Eventfull Sundays" />

            {/* Right Section: Email Confirmation Message */}
            <div className="flex justify-center items-center w-full lg:w-1/2 bg-white p-6 lg:p-8">
                <div className="bg-white p-6 lg:p-8 rounded-lg shadow-lg w-full max-w-md">
                    <div className="text-center">
                        <p className="text-2xl font-semibold mb-6">Account Verified</p>
                        <p className="text-lg text-gray-700 mb-4">
                            You've successfully verified your account on Eventfull Sunday!
                        </p>
                        <p className="text-sm text-gray-600 mb-6">
                            You can log into your account using the button below.
                        </p>
                        <div className="mb-4">
                            <Link
                                to="/login"
                                className="block p-3 bg-blue-600 text-white rounded-full hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-600 w-full text-center"
                            >
                                Go to Login
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SuccessfulVerification;
