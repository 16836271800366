import React, { useState, useEffect } from "react";

import axios from "axios";

const Expire = () => {
  const [expire, setExpire] = useState();

  //    For expire event
 
  return (
    <div>
     <p>Exire</p>
    </div>
  );
};

export default Expire;
