// src/components/layout/Navbar/Navbar.jsx

import React, { useState, useEffect } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { Formik, Field, Form } from "formik";
import event from "../../../assets/site-images/eventfullsunday.png";
import {
  IoPersonCircleSharp,
  IoSearch,
  IoLocationSharp,
  IoAddCircleOutline,
  IoFilter,
  IoClose,
} from "react-icons/io5";
import { useSelector, useDispatch } from "react-redux";
import { getProfile } from '../../../redux/auth/action/Profile';
import { motion, AnimatePresence } from "framer-motion";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FilterModal from "../../pages/Organizer/FilterModal";

const Navbar = ({ onFilter, filtersApplied, resetFilters }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const categories = [
    "music",
    "tech",
    "sports",
    "business",
    "arts",
    "shows",
  ];
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const profileState = useSelector((state) => state.profile);
  const firstName = profileState?.profile?.first_name;
  const token = localStorage.getItem("access");

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        await dispatch(getProfile());
      } catch (error) {
        console.error("Error fetching profile:", error);
      }
    };

    if (token) {
      fetchProfile();
    }
  }, [dispatch, token]);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  // Handle search form submission
  const handleSearchSubmit = (values, { resetForm }) => {
    const filterCriteria = {
      name: values.search,
    };
    onFilter(filterCriteria);
    resetForm();
  };

  // Render categories for the navbar
  const renderCategories = () => (
    <div className="hidden lg:flex flex-wrap justify-center bg-white px-2">
      {categories.map((category) => (
        <NavLink
          key={category}
          to={`/${category}`}
          className="hidden md:flex text-gray-500 p-1 px-2 md:px-1 lg:px-2 mx-1 mt-2 rounded-full border-2 border-[#1399FF] text-xs font-poppins"
        >
          {category.charAt(0).toUpperCase() + category.slice(1)}
        </NavLink>
      ))}
      <button
        onClick={() => window.open("https://maps.google.com", "_blank")}
        className="hidden md:flex text-gray-500 p-1 px-2 md:px-1 lg:px-2 mx-1 mt-2 rounded-full border-2 border-[#1399FF] text-xs font-poppins items-center"
      >
        <IoLocationSharp className="mr-1" /> Search Nearby Location
      </button>
    </div>
  );

  // Render profile links
  const renderProfileLinks = () => (
    <div className="flex items-center space-x-4">
      <a
        href="https://events.eventfullsunday.co.uk/"
        target="_blank"
        rel="noopener noreferrer"
        className="font-poppins text-base text-black font-semibold ml-2"
      >
        BLOGS
      </a>
      <button
        onClick={handleCreate}
        className="flex items-center space-x-1 text-blue-500 hover:text-blue-700"
      >
        <IoAddCircleOutline size={25} className="text-black" />
        <span className="hidden sm:inline font-poppins text-black">Create</span>
      </button>
      <Link to="/dashboard" className="flex items-center ml-4">
        <IoPersonCircleSharp
          size={25}
          className="cursor-pointer text-black"
        />
        {firstName ? (
          <p className="ml-1 font-poppins text-base text-nowrap text-black font-medium">
            {firstName}
          </p>
        ) : (
          <div className="h-4 bg-gray-200 rounded mb-2 animate-pulse"></div>
        )}
      </Link>
    </div>
  );

  // Render authentication links
  const renderAuthLinks = () => (
    <div className="flex items-center space-x-3 ms-2">
      {/*<Link
        to="/organizer"
        className="font-poppins text-base text-black font-semibold"
      >
        ORGANISER?
      </Link>*/}
      <a
        href="https://events.eventfullsunday.co.uk/"
        target="_blank"
        rel="noopener noreferrer"
        className="font-poppins text-base text-black font-semibold"
      >
        BLOGS
      </a>
      <Link
        to="/register"
        className="font-poppins text-base text-black font-semibold"
      >
        SIGNUP
      </Link>
      <Link
        to="/login"
        className="font-poppins text-base text-black font-semibold"
      >
        LOGIN
      </Link>
    </div>
  );

  // Render the Create button
  const handleCreate = () => {
    // If you have additional checks, include them here
    if (profileState?.profile?.company_name === "") {
      toast.error("Complete your profile to start creating events");
    } else {
      navigate("/createEventForm");
    }
  };

  // Render mobile menu for smaller screens
  const renderMobileMenu = () => (
    <motion.div
      className="md:hidden bg-white w-full shadow-md z-20 origin-top overflow-hidden"
      initial={{ height: 0 }}
      animate={{ height: "auto" }}
      exit={{ height: 0 }}
      transition={{ duration: 0.3 }}
    >
      <div className="flex flex-wrap justify-center gap-2 mt-2">
        {categories.map((category) => (
          <NavLink
            key={category}
            to={`/${category}`}
            className="text-gray-500 px-4 py-2 text-center rounded-full border-2 border-[#1399FF] text-sm font-poppins hover:bg-blue-100 hover:text-blue-600 transition duration-200"
          >
            {category.charAt(0).toUpperCase() + category.slice(1)}
          </NavLink>
        ))}
        <button
          onClick={() => window.open("https://maps.google.com", "_blank")}
          className="text-gray-500 px-4 py-2 text-center rounded-full border-2 border-[#1399FF] text-sm font-poppins flex items-center justify-center hover:bg-blue-100 hover:text-blue-600 transition duration-200"
        >
          <IoLocationSharp className="mr-1" /> Search Nearby Location
        </button>
      </div>
      <div className="flex flex-col items-center gap-4 mt-4 mb-3">
        {localStorage.getItem("access")
          ? renderProfileLinks()
          : renderAuthLinks()}
      </div>
    </motion.div>
  );

  // Render the search form in the navbar
  const renderSearchForm = () => (
    <Formik initialValues={{ search: "" }} onSubmit={handleSearchSubmit}>
      {({ getFieldProps }) => (
        <Form className="flex items-center w-full max-w-2xl relative">
          <IoSearch className="absolute left-4 text-gray-500 w-5 h-5" />
          <Field
            type="text"
            {...getFieldProps("search")}
            placeholder="Search events, communities, browse #hashtags"
            className="w-full p-2 pl-12 border-2 border-[#1399FF] rounded-full focus:outline-none focus:ring-2 focus:ring-blue-600 font-poppins"
          />
          {/* Modify Filter Button */}
          <button
            type="button"
            onClick={filtersApplied ? resetFilters : () => setIsFilterOpen(true)}
            className="absolute right-4 text-gray-500 hover:text-blue-600 focus:outline-none"
            aria-label={filtersApplied ? "Clear Filters" : "Filter Events"}
          >
            {filtersApplied ? <IoClose size={20} /> : <IoFilter size={20} />}
          </button>
        </Form>
      )}
    </Formik>
  );

  // Menu icon variants for animation
  const menuIconVariants = {
    closed: { rotate: 0 },
    open: { rotate: 45, y: 5 },
  };

  const menuIconMiddleVariants = {
    closed: { opacity: 1 },
    open: { opacity: 0 },
  };

  const menuIconBottomVariants = {
    closed: { rotate: 0 },
    open: { rotate: -45, y: -7 },
  };

  // Render the animated menu icon
  const renderMenuIcon = () => (
    <button
      onClick={toggleMenu}
      className="flex flex-col items-center justify-center w-8 h-8 md:hidden focus:outline-none"
    >
      <motion.span
        className="block w-6 h-[2px] bg-black"
        animate={isMenuOpen ? "open" : "closed"}
        variants={menuIconVariants}
        transition={{ duration: 0.3 }}
      />
      <motion.span
        className="block w-6 h-[2px] bg-black my-1"
        animate={isMenuOpen ? "open" : "closed"}
        variants={menuIconMiddleVariants}
        transition={{ duration: 0.3 }}
      />
      <motion.span
        className="block w-6 h-[2px] bg-black"
        animate={isMenuOpen ? "open" : "closed"}
        variants={menuIconBottomVariants}
        transition={{ duration: 0.3 }}
      />
    </button>
  );

  return (
    <nav className="relative bg-white shadow-md">
      <div className="flex max-w-[1200px] mx-auto px-4 justify-between items-center py-4 min-h-[120px] sm:min-h-auto">
        <Link to="/" className="min-w-[80px] mr-4">
          <img src={event} className="w-20 h-20 rounded-md" alt="Event" />
        </Link>

        {/* Middle section */}
        <div className="hidden md:flex flex-grow flex-col items-center w-full md:w-auto lg:w-auto">
          <div className="flex items-center justify-center w-full max-w-2xl">
            {renderSearchForm()}
          </div>
          {renderCategories()}
        </div>

        {/* Search form for mobile view */}
        <div className="flex-grow mx-4 md:hidden">
          {renderSearchForm()}
        </div>

        {renderMenuIcon()}

        <div className="hidden md:flex items-center gap-6">
          {localStorage.getItem("access")
            ? renderProfileLinks()
            : renderAuthLinks()}
        </div>
      </div>

      {/* Mobile menu */}
      <AnimatePresence>
        {isMenuOpen && renderMobileMenu()}
      </AnimatePresence>

      {/* Filter Modal */}
      <FilterModal
        isOpen={isFilterOpen}
        onClose={() => setIsFilterOpen(false)}
        onFilter={onFilter}
      />

      {/* Toast notifications */}
      <ToastContainer position="top-right" autoClose={5000} />
    </nav>
  );
};

export default Navbar;
