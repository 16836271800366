import apiClient from "../../../apiClient/apiClient"
import { GET_TICKET_FAILURE, GET_TICKET_REQUEST, GET_TICKET_SUCCESS } from "./createTicketConstant"

export const fetchEventTickets = (id) => async (dispatch)=> {
    dispatch({
        type: GET_TICKET_REQUEST
    })
    try {
        const token=localStorage.getItem('access')
        console.log('from',token)
        const config={
            headers:{
                Authorization:`Bearer ${token}`
            }
        }
        const response=await apiClient.get(`api/events/${id}/tickets/`,config)
        dispatch({
            type: GET_TICKET_SUCCESS,
            payload: response.data
        })
    } catch (error) {
        dispatch({
            type: GET_TICKET_FAILURE,
            payload: error.message
        })
    }
}