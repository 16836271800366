// Profile.jsx
import React from "react";
import userImage from "../../../assets/site-images/user.png"; // Adjust the import path as needed

const Updates = () => {
  return (
    <div className="w-full bg-white p-6 rounded-lg shadow-lg">
      <div className="flex flex-col items-center">
        <img
          src={userImage}
          alt="User"
          className="w-24 h-24 rounded-full mb-4"
        />
        <h2 className="text-lg font-semibold">Today</h2>

      </div>
    </div>
  );
};

export default Updates;
