import React, { useState } from "react";
import { IoPersonCircle, IoLogOutOutline } from "react-icons/io5";
import { FaHistory, FaBell, FaLock, FaCog, FaTachometerAlt } from "react-icons/fa";
import Navbar from "../../layout/Navbar/Navbar";
import Footer from "../../layout/Footer/Footer"; 
import Profile from "./Profile"; 
import Updates from "./Updates";
import PrivacyPolicy from "./PrivacyPolicy";
import Settings from "./Settings";
import { useNavigate } from "react-router-dom"; 

const Dashboard = () => {
  const [selectedSection, setSelectedSection] = useState("profile");
  const navigate = useNavigate(); 

  const renderContent = () => {
    switch (selectedSection) {
      case "profile":
        return <Profile />;
      case "updates":
        return <Updates />;
      case "privacy-policy":
        return <PrivacyPolicy />;
      case "settings":
        return <Settings />;
      default:
        return null;
    }
  };

  // Logout function
  const handleLogout = () => {
    localStorage.removeItem("access");
    localStorage.removeItem("refresh");
    navigate("/login");
  };

  return (
    <div className="flex flex-col min-h-screen">
      <Navbar />

      <div className="flex flex-grow flex-col-reverse sm:flex-row bg-gray-100">
        {/* Sidebar */}
        <div className="w-full sm:w-2/5 lg:1/5 xl:w-1/4 bg-white shadow-lg p-4 md:p-8 h-full">
          <div className="flex flex-col justify-between h-full">
            <div>
              <button
                onClick={() => setSelectedSection("profile")}
                className={`flex items-center mb-6 p-4 text-lg transition duration-300 ease-in-out w-full ${selectedSection === "profile"
                  ? "text-white bg-blue-500 rounded-lg"
                  : "text-gray-700 hover:bg-gray-200 rounded-lg"
                  }`}
              >
                <IoPersonCircle className="w-6 h-6 mr-4 sm:mr-2 md:mr-4" />
                <span>Profile</span>
              </button>
            
              <button
                onClick={() => setSelectedSection("updates")}
                className={`flex items-center mb-6 p-4 text-lg transition duration-300 ease-in-out w-full ${selectedSection === "updates"
                  ? "text-white bg-blue-500 rounded-lg"
                  : "text-gray-700 hover:bg-gray-200 rounded-lg"
                  }`}
              >
                <FaBell className="w-6 h-6 mr-4 sm:mr-2 md:mr-4" />
                <span>Updates</span>
              </button>
              <button
                onClick={() => setSelectedSection("privacy-policy")}
                className={`flex items-center mb-6 p-4 text-lg transition duration-300 ease-in-out w-full ${selectedSection === "privacy-policy"
                  ? "text-white bg-blue-500 rounded-lg"
                  : "text-gray-700 hover:bg-gray-200 rounded-lg"
                  }`}
              >
                <FaLock className="w-6 h-6 mr-4 sm:mr-2 md:mr-4" />
                <span>Privacy Policy</span>
              </button>
              <button
                onClick={() => setSelectedSection("settings")}
                className={`flex items-center p-4 text-lg transition duration-300 ease-in-out w-full ${selectedSection === "settings"
                  ? "text-white bg-blue-500 rounded-lg"
                  : "text-gray-700 hover:bg-gray-200 rounded-lg"
                  }`}
              >
                <FaCog className="w-6 h-6 mr-4 sm:mr-2 md:mr-4" />
                <span>Settings</span>
              </button>
            </div>

            <div className="mt-8">
              <button
                onClick={handleLogout}
                className="flex items-center mb-6 p-4 text-lg transition duration-300 ease-in-out w-full text-white bg-red-500 rounded-lg hover:bg-red-600"
              >
                <IoLogOutOutline className="w-6 h-6 mr-4 sm:mr-2 md:mr-4" />
                <span>Logout</span>
              </button>
            </div>

            <div className="text-center mt-8">
              <p className="text-sm text-gray-600">
                &copy; 2022 eventfullsundays.co.uk
              </p>
            </div>
          </div>
        </div>

        {/* Main Content */}
        <div className="w-full sm:w-3/5 lg:w-4/5 xl:w-3/4 p-4 md:p-2">{renderContent()}</div>
      </div>

      <Footer />
    </div>
  );
};

export default Dashboard;
