import apiClient from "../../../apiClient/apiClient";
import { ACTIVATION_LINK_REQUEST, ACTIVATION_LINK_SUCCESS, ACTIVATION_LINK_FAILURE } from '../constant/ResendLinkConstant';

export const resendLinkAction = (formData) => async (dispatch) => {
    dispatch({ type: ACTIVATION_LINK_REQUEST });
  
    try {
      const token = localStorage.getItem("access");
      console.log("token", token);
  
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };
  
      const response = await apiClient.post(
        "api/resend-activation/",
        formData,
        config
      );
  
      dispatch({
        type: ACTIVATION_LINK_SUCCESS,
        payload: response.data,
      });
      console.log('Link sent');
      window.location.href = "/update"; // Redirect after success (optional)
    } catch (error) {
      dispatch({
        type: ACTIVATION_LINK_FAILURE,
        payload: error.message,
      });
    }
  };