import { VERIFIED_ORGANISER_FAILURE, VERIFIED_ORGANISER_REQUEST, VERIFIED_ORGANISER_SUCCESS } from "./verifiedOrganiserConstants";
const initialState = {
    loading: false,
    data: null,
    error: null
}

const verifiedOrganiserReducer = (state=initialState, action) => {
    switch(action.type) {
        case VERIFIED_ORGANISER_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case VERIFIED_ORGANISER_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload
            }
        case VERIFIED_ORGANISER_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        default:
            return state;
    }
}

export default verifiedOrganiserReducer;