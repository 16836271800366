// src/actions/venueActions.js


import apiClient from '../../../apiClient/apiClient';
import { GET_VENUE_DETAIL_FAILURE, GET_VENUE_DETAIL_REQUEST, GET_VENUE_DETAIL_SUCCESS } from '../VenueConstant/VenueDetailConstant';

export const fetchVenueDetail = (id) => async (dispatch) => {
    dispatch({ type: GET_VENUE_DETAIL_REQUEST });

    try {
        const token = localStorage.getItem('access');
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };

        const response = await apiClient.get(`/register-venue/${id}`, config);
        dispatch({
            type: GET_VENUE_DETAIL_SUCCESS,
            payload: response.data,
        });
    } catch (error) {
        dispatch({
            type: GET_VENUE_DETAIL_FAILURE,
            payload: error.message,
        });
    }
};
