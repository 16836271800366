import apiClient from "../../../apiClient/apiClient";
import { GET_ORGANISER_EVENT_FAILURE, GET_ORGANISER_EVENT_REQUEST, GET_ORGANISER_EVENT_SUCCESS } from "./GetOrganiserEventConstant"

export const fetchOrganiserEvents = (id) => async (dispatch) => {
    dispatch({
        type: GET_ORGANISER_EVENT_REQUEST
    })
    try {
        const token = localStorage.getItem('access');
        if (!token) {
            throw new Error("No token found");
        }
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };



        if (id) {
            const response = await apiClient.get(`api/events/organizer/${id}`, config);
            console.log("[ORGANIZER DEBUG] Organizer events: ", response.data);

            dispatch({
                type: GET_ORGANISER_EVENT_SUCCESS,
                payload: response.data,
            })
        } else {
            dispatch({
                type: GET_ORGANISER_EVENT_FAILURE,
                payload: "No ID provided.",
            })
        }



    } catch (error) {
        let errorMessage;
        if (error.response && error.response.data && error.response.data.detail) {
            errorMessage = error.response.data.detail;
        } else {
            errorMessage = error.message;
        }

        dispatch({
            type: GET_ORGANISER_EVENT_FAILURE,
            payload: errorMessage,
        })
    }
}