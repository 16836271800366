import { combineReducers } from "redux";
import userReducer from "./auth/reducer/LoginReducer";
import registerReducer from "./auth/reducer/RegisterReducer";
import passwordReducer from "./auth/reducer/ForgotPasswordReducer";
import { profileReducer, sendResetVerificationEmailReducer } from "./auth/reducer/ProfileReducer";
import organizationReducer from "./auth/reducer/OrganizationProfileReducer";
import passwordResetReducer from "./auth/reducer/ResetReducer";
// import CreateCategoryReducer from "./createEvent/CreateCategoryReducer/CreateCategoryReducer";
// import CreateSubCategoryReducer from "./createEvent/CreateSubCategoryReducer/CreateSubCategoryReducer";
// import subCategoryReducer from "./createEvent/CreateSubCategoryReducer/CreateSubCategoryReducer";
import getCreateEventReducer from "./createEvent/createEventReducer/GetCreateEventReducer";
import eventReducer from "./createEvent/createEventReducer/EventDetailReducer";

import venueReducer from "./venue/VenuReducer/VenueReducer";
import getVenueReducer from "./venue/VenuReducer/GetVenueRedcuer";
import venueDetailReducer from "./venue/VenuReducer/VenueDetailReducer";
import venueUpdateReducer from "./venue/VenuReducer/VenueUpdataReducer";
import createEventReducer from "./createEvent/EditEvent/EditEventReducer";
import getOrganiserEventReducer from "./OrganiserEvent/organiserEventReducer/GetOrganiserEventReducer";
import createTicketReducer from "./CreateTicket/ticketReducer/createTicketReducer";
import getEventTicketReducer from "./CreateTicket/ticketReducer/getTicketReducer";
import verifiedOrganiserReducer from "./OrganiserEvent/OrganiserVerification/verifiedOrganiserReducer";
import googleAuthReducer from "./auth/reducer/googleLoginReducer";
import activationLinkReducer from "./auth/reducer/ResendLinkReducer";
import categoryReducer from "./auth/reducer/categoryReducer";
import subCategoryReducer from "./auth/reducer/subCategoryReducer";
import { subscriptionReducer } from "./auth/reducer/SubscriptionReducer";

const rootReducer = combineReducers({
  user: userReducer,
  register: registerReducer,
  // event:eventReducer
  profile: profileReducer,
  resetVerification: sendResetVerificationEmailReducer,
  organization: organizationReducer,
  getEvent: getCreateEventReducer,
  eventItems: eventReducer,
  venueRegister: venueReducer,
  getVenue: getVenueReducer,
  venueDetail: venueDetailReducer,
  venueEdit: venueUpdateReducer,
  update: createEventReducer,
  getOrganiserEvent: getOrganiserEventReducer,
  addTicket: createTicketReducer,
  getTickets: getEventTicketReducer,
  verified: verifiedOrganiserReducer,
  googleAuth: googleAuthReducer,
  resendLink: activationLinkReducer,
  passwordForgot: passwordReducer,
  passwordResetDetail: passwordResetReducer,
  category: categoryReducer,
  subcategory: subCategoryReducer,
  subscriptionDetail: subscriptionReducer,
});

export default rootReducer;
