// Profile.jsx
import React from "react";

const Settings = () => {
  return (
    <div className="w-full bg-white p-6 rounded-lg shadow-lg">
     <p>Today</p>
    </div>
  );
};

export default Settings;
