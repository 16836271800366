// Home.jsx
import React, { useEffect, useState } from "react";
import CardSlider from './Organizer/CardSlider';
import { fetchGetItems } from "../../redux/createEvent/createEventAction/GetEventAction";
import { useDispatch, useSelector } from "react-redux";
import Navbar from "../layout/Navbar/Navbar";
import { fetchOrganiserEvents } from "../../redux/OrganiserEvent/organiserEventAction/GetOrganiserEventAction";
import Footer from "../layout/Footer/Footer";
import { Helmet } from "react-helmet";

const Home = () => {
  const dispatch = useDispatch();

  const categoryState = useSelector((state) => state.getEvent); // Assuming getEvent contains all events
  const profileState = useSelector((state) => state.profile);
  const yourEvents = useSelector((state) => state.getOrganiserEvent);

  const { data, loading, error } = categoryState; // 'data' should contain all events
  const [filteredData, setFilteredData] = useState([]);
  const [filtersApplied, setFiltersApplied] = useState(false); // New state variable

  useEffect(() => {
    dispatch(fetchGetItems()); // Fetch all events
    //dispatch(getProfile());
  }, [dispatch]);

  useEffect(() => {
    if (profileState) {
      console.log("[PROFILE DEBUG] Profile state: ", profileState);
      dispatch(fetchOrganiserEvents(profileState?.profile?.id)); // Fetch user's events
    }
  }, [dispatch, profileState]);

  // Optional: Verify that 'data' includes all events
  useEffect(() => {
    console.log("[ALL EVENTS] ", data);
  }, [data]);

  const handleFilter = (filterCriteria) => {
    // Apply filter criteria to the events
    const { name, description, country, subCategory, date, startTime, endTime, venueName, venueAddress, ticketPrice, isFreeTicket, capacity } = filterCriteria;

    let filtered = data;

    if (name) {
      filtered = filtered.filter(event => event.event_name.toLowerCase().includes(name.toLowerCase()));
    }

    if (description) {
      filtered = filtered.filter(event => event.description.toLowerCase().includes(description.toLowerCase()));
    }

    if (country) {
      filtered = filtered.filter(event => event.event_origin_country === country);
    }

    if (subCategory) {
      filtered = filtered.filter(event => event.category_name === subCategory);
    }

    if (date) {
      filtered = filtered.filter(event => event.start_date === date);
    }

    if (startTime) {
      filtered = filtered.filter(event => event.start_time === startTime);
    }

    if (endTime) {
      filtered = filtered.filter(event => event.end_time === endTime);
    }

    if (venueName) {
      filtered = filtered.filter(event => event.venue_name && event.venue_name.toLowerCase().includes(venueName.toLowerCase()));
    }

    if (venueAddress) {
      filtered = filtered.filter(event => event.venue_address && event.venue_address.toLowerCase().includes(venueAddress.toLowerCase()));
    }

    if (isFreeTicket) {
      filtered = filtered.filter(event => event.ticket_price === '0.00' || event.ticket_price === 0 || event.ticket_price === null);
    } else if (ticketPrice) {
      filtered = filtered.filter(event => parseFloat(event.ticket_price) <= parseFloat(ticketPrice));
    }

    if (capacity) {
      filtered = filtered.filter(event => parseInt(event.capacity) >= parseInt(capacity));
    }

    setFilteredData(filtered);
    setFiltersApplied(true); // Set filtersApplied to true when filters are applied
  };

  const resetFilters = () => {
    setFilteredData([]);
    setFiltersApplied(false); // Reset filtersApplied to false
  };

  // Define the event types within the component or import them if defined elsewhere
  const eventTypeChoices = [
    { key: 'trending', label: 'Trending Event in UK' },
    { key: 'community', label: 'Community Events' },
    { key: 'new_listing', label: 'New Listing' },
    { key: 'most_popular_free', label: 'Most Popular Free Events' },
    { key: 'latest', label: 'Latest Events' },
    { key: 'virtual', label: 'Virtual Events' },
    { key: 'festivals', label: 'Festivals and Carnivals' },
    { key: 'concerts', label: 'Concerts & Gigs' },
    { key: 'workshops', label: 'Workshops & Seminars' },
    { key: 'last_minute_deals', label: 'Last-Minute Deals' },
    { key: 'local_favorites', label: 'Local Favorites' },
    { key: 'nightlife', label: 'Nightlife & Parties' },
    { key: 'sports', label: 'Sports Events' },
    { key: 'cultural', label: 'Cultural & Heritage Events' },
    { key: 'art_exhibitions', label: 'Art & Exhibitions' },
    { key: 'business_networking', label: 'Business & Networking Events' },
    { key: 'popular_external', label: 'Popular Across Other Platforms' },
  ];

  if (error) return <p>{error}</p>;

  return (
    <div className="min-h-screen flex flex-col font-poppins bg-[#f2f4f7]">
      <Helmet>
          <title>EventfullSunday.co.uk : Celebrate Diversity, Connect Communities, Create Memories - Bringing Together in the UK.</title>
          
          <meta 
          name="description" 
          content="Promote and manage your events with ease on EventfullSunday.co.uk, the platform that connects communities across the UK. Whether you’re organizing live music, cultural festivals, sports events, or theatre performances, our platform helps you reach the right audience, maximize revenue with dynamic pricing, and ensure seamless ticketing and event management. " 
          />

          <meta 
          name="keywords" 
          content="event organizers, organize events in UK, cultural festivals, live music events, sports event promotion, theatre event management, community events, ticketing platform for organizers, UK event promotion, dynamic pricing for events, grow your event audience" 
          />
          <meta property="og:url" content="https://organizer.eventfullsunday.co.uk/" />

          <link rel="canonical" href="https://organizer.eventfullsunday.co.uk/" />
      </Helmet>
      {/* Navbar */}
      <Navbar
        onFilter={handleFilter}
        filtersApplied={filtersApplied} // Pass filtersApplied to Navbar
        resetFilters={resetFilters}     // Pass resetFilters to Navbar
      />

      {/* Main Content */}
      <main className="flex-grow">
        {/* Container for max-width and centered alignment */}
        <div className="max-w-[1200px] mx-auto px-4">
          {filtersApplied ? (
            <>
              <p className="font-poppins text-lg font-semibold mt-7">SEARCH RESULTS</p>
              {filteredData.length > 0 ? (
                <CardSlider data={filteredData} />
              ) : (
                <p className="mt-2 text-sm opacity-80 text-center"><i>No events match your filters.</i></p>
              )}
            </>
          ) : (
            <>
              <p className="font-poppins text-lg font-semibold mt-7">YOUR EVENTS</p>
              {yourEvents && yourEvents?.data?.length > 0 ? (
                <CardSlider className="relative" data={yourEvents?.data} />
              ) : (
                <p className="mt-2 text-sm opacity-80 text-center"><i>You don't have any created events.</i></p>
              )}

              {/* Dynamic Event Type Sections */}
              {eventTypeChoices.map((type) => {
                const eventsOfType = data.filter(event => event.event_type === type.key);
                if (eventsOfType.length === 0) return null; // Optionally, display a message for no events in this category
                return (
                  <div key={type.key}>
                    <p className="font-poppins text-lg font-semibold mt-7 uppercase">{type.label}</p>
                    <CardSlider className="relative" data={eventsOfType} />
                  </div>
                );
              })}
            </>
          )}
        </div>
      </main>

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default Home;
