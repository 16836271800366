// Profile.jsx
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import {
  getUserProfile,
  updateUserProfile,
  sendResetVerificationEmail,
} from "../../../redux/auth/action/userProfileActions";
import { updateOrganizerProfile } from "../../../redux/auth/action/OrganizationProfile";
import { fetchOrganiserEvents } from "../../../redux/OrganiserEvent/organiserEventAction/GetOrganiserEventAction";
import { useNavigate } from "react-router-dom";
import { FiEdit2 } from "react-icons/fi";
import { VscUnverified, VscVerified } from "react-icons/vsc";
import userImage from "../../../assets/site-images/user.png";
import ProfileUpdateModal from "./ProfileUpdateModal";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Profile = () => {
  const dispatch = useDispatch();
  const profileState = useSelector((state) => state.profile);
  const resetVerificationState = useSelector((state) => state.resetVerification);
  const yourEvents = useSelector((state) => state.getOrganiserEvent); // Ensure this selector is correct
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCooldown, setIsCooldown] = useState(false);
  const [hasDisplayedToast, setHasDisplayedToast] = useState(true);
  const navigate = useNavigate();

  // Fetch profile data
  useEffect(() => {
    dispatch(getUserProfile());

    let timer;
    if (isCooldown) {
      timer = setTimeout(() => {
        setIsCooldown(false);
      }, 10000); // 10 seconds cooldown
    }
    return () => clearTimeout(timer); // Cleanup on unmount
  }, [dispatch, isCooldown]);

  useEffect(() => {
    if (profileState) {
      console.log("[PROFILE DEBUG] Profile state: ", profileState);
      dispatch(fetchOrganiserEvents(profileState?.profile?.id)); // Fetch user's events
    }
  }, [dispatch, profileState]);

  useEffect(() => {
    if (
      resetVerificationState.data &&
      !resetVerificationState.loading &&
      !hasDisplayedToast
    ) {
      console.log(resetVerificationState.data);
      toast.success(
        "You've successfully requested an account verification email!"
      );
      setHasDisplayedToast(true); // Update to indicate that toast has been displayed
    }
    if (
      resetVerificationState.error &&
      !hasDisplayedToast
    ) {
      toast.error(
        "There was a problem with sending a verification email. Please try again later."
      );
      setHasDisplayedToast(true); // Update to indicate that toast has been displayed
    }
  }, [
    resetVerificationState.data,
    resetVerificationState.error,
    resetVerificationState.loading,
    hasDisplayedToast,
  ]);

  useEffect(() => {
    if (
      profileState.profile &&
      typeof profileState.profile.is_verified !== "undefined"
    ) {
      // Force re-render by updating local state or simply relying on Redux state changes
      // This ensures that verifiedStatus reflects the latest is_verified value
    }
  }, [profileState.profile]);

  if (!localStorage.getItem("access")) {
    navigate("/login"); // Navigate to login page if there's no logged in profile
  }

  if (!profileState.profile) {
    return <div>Loading...</div>;
  }

  const {
    first_name,
    last_name,
    email,
    phone_number,
    profile_picture,
    is_verified,
    company_name,
    description,
    business_email,
    business_phone,
    flat_house_number,
    street_address,
    city_town,
    county,
    company_logo,
    country,
    post_code,
  } = profileState.profile || {};

  // Helper function to safely assign values or default to empty string
  const safeValue = (value) => (value === null || value === undefined ? "" : value);

  const initialValuesFull = {
    first_name: safeValue(first_name),
    last_name: safeValue(last_name),
    email: safeValue(email),
    phone_number: safeValue(phone_number),
    company_name: safeValue(company_name),
    description: safeValue(description),
    business_email: safeValue(business_email),
    business_phone: safeValue(business_phone),
    flat_house_number: safeValue(flat_house_number),
    street_address: safeValue(street_address),
    city_town: safeValue(city_town),
    county: safeValue(county),
    post_code: safeValue(post_code),
    country: safeValue(country),
    company_logo: company_logo || "",
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  // Handle image file selection
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Create FormData object to hold the profile data
      const formData = new FormData();
      formData.append("first_name", first_name);
      formData.append("last_name", last_name);
      formData.append("email", email);
      formData.append("phone_number", phone_number);
      formData.append("profile_picture", file);

      console.log(formData);

      dispatch(updateUserProfile(formData));
    }
  };

  const handleResendVerificationEmail = () => {
    dispatch(sendResetVerificationEmail(email));
    setHasDisplayedToast(false);
    setIsCooldown(true);
  };

  const verifiedStatus = () => {
    if (profileState.loading) {
      return <span className="text-gray-500">Loading...</span>;
    } else {
      if (is_verified && is_verified === true) {
        console.log("verified!!");

        return (
          <span className="flex">
            <span className="text-green-600">Verified</span>
            <VscVerified size={20} className="ms-1 my-[2px] text-green-600" />
          </span>
        );
      } else if (is_verified && is_verified === false) {
        console.log("not verified!!");

        return (
          <>
            <button
              className="flex items-center"
              onClick={handleResendVerificationEmail}
              disabled={isCooldown}
              title="Click to resend a verification link to your email"
            >
              <span
                className={`flex items-center mx-auto underline transition duration-200 ${isCooldown
                  ? "text-gray-500 no-underline opacity-80"
                  : "text-red-500 hover:text-red-800 hover:brightness-90"
                  }`}
              >
                <span>Unverified</span>
                <VscUnverified size={20} className="ms-1 my-[2px]" />
              </span>
            </button>
            {isCooldown && (
              <span className="text-red-500 text-xs mt-1">
                You can request a new email in 10 seconds.
              </span>
            )}
          </>
        );
      }
    }
  };

  return (
    <div className="flex flex-col w-full justify-center sm:justify-start p-4 sm:p-0 lg:ms-2 bg-gray-100">
      <ToastContainer position="top-right" autoClose={3000} />

      {/* Your Events Section */}
      <div className="bg-white p-6 mb-4 rounded-lg shadow-lg w-full xl:w-[90%]">
        <h2 className="text-lg font-semibold mb-4">Your Events</h2>
        {yourEvents && yourEvents.data && yourEvents.data.length > 0 ? (
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
            {yourEvents.data.map((event) => (
              <div
                key={event.event_id}
                className="border rounded-lg p-4 flex flex-col justify-between bg-gray-100"
              >
                {/* Event Name */}
                <Link
                  to={`/event/${event.event_id}`}
                  className="text-blue-600 hover:underline font-medium mb-2"
                >
                  {event.event_name}
                </Link>

                {/* Venue and Location */}
                <p className="text-gray-600 mb-4">{[
                  event?.venue_name,
                  event?.venue_address,
                  event?.venue_post_code
                ]
                  .filter(Boolean)
                  .join(', ') || "Unknown Venue"}</p>

                {/* Action Button */}
                <button
                  onClick={() => navigate("/createTickets")}
                  className="mt-auto text-blue-500 hover:text-blue-700 underline self-start"
                >
                  Create Tickets
                </button>
              </div>
            ))}
          </div>
        ) : (
          <p className="text-gray-500 text-center">You have no events.</p>
        )}
      </div>


      <div className="flex flex-col xl:flex-row xl:space-x-4 mb-5">
        {/* Additional Profile Info Box */}
        <div className="bg-white p-6 mb-4 xl:mt-0 rounded-lg shadow-lg w-full md:w-96">
          <h2 className="text-lg font-semibold mb-4">Additional Information</h2>
          <div className="space-y-3">
            {/* Company Logo */}
            <div>
              <p className="text-sm font-medium text-gray-700">Company Logo</p>
              {company_logo ? (
                <img
                  src={company_logo}
                  alt="Company Logo"
                  className="mt-2 w-24 h-24 object-cover rounded-full border-2"
                />
              ) : (
                <p className="text-gray-500">No logo uploaded.</p>
              )}
            </div>

            {/* Company Name */}
            <div>
              <p className="text-sm font-medium text-gray-700">Company Name</p>
              <p className="text-gray-900">
                {company_name.charAt(0).toUpperCase() +
                  company_name.slice(1) ||
                  "N/A"}
              </p>
            </div>

            {/* Description */}
            <div>
              <p className="text-sm font-medium text-gray-700">Description</p>
              <p className="text-gray-900">
                {description.charAt(0).toUpperCase() + description.slice(1) ||
                  "N/A"}
              </p>
            </div>

            {/* Business Email */}
            <div>
              <p className="text-sm font-medium text-gray-700">Business Email</p>
              <p className="text-gray-900">{business_email || "N/A"}</p>
            </div>

            {/* Business Phone */}
            <div>
              <p className="text-sm font-medium text-gray-700">Business Phone</p>
              <p className="text-gray-900">{business_phone || "N/A"}</p>
            </div>

            {/* Flat/House Number */}
            <div>
              <p className="text-sm font-medium text-gray-700">
                Flat/House Number
              </p>
              <p className="text-gray-900">{flat_house_number || "N/A"}</p>
            </div>

            {/* Street Address */}
            <div>
              <p className="text-sm font-medium text-gray-700">Street Address</p>
              <p className="text-gray-900">{street_address || "N/A"}</p>
            </div>

            {/* City/Town */}
            <div>
              <p className="text-sm font-medium text-gray-700">City/Town</p>
              <p className="text-gray-900">{city_town || "N/A"}</p>
            </div>

            {/* County */}
            <div>
              <p className="text-sm font-medium text-gray-700">County</p>
              <p className="text-gray-900">{county || "N/A"}</p>
            </div>

            {/* Postcode */}
            <div>
              <p className="text-sm font-medium text-gray-700">Postcode</p>
              <p className="text-gray-900">{post_code || "N/A"}</p>
            </div>

            {/* Country */}
            <div>
              <p className="text-sm font-medium text-gray-700">Country</p>
              <p className="text-gray-900">{country || "N/A"}</p>
            </div>
          </div>
        </div>

        {/* Profile Info Box */}
        <div className="bg-white p-6 rounded-lg shadow-lg w-full md:w-96 h-[290px]">
          <div className="flex flex-col items-center relative">
            {/* Profile picture with clickable upload and edit icon */}
            <label htmlFor="profile-pic-upload" className="relative w-24 h-24 cursor-pointer">
              {/* The image with hover darken effect */}
              <img
                src={profile_picture || userImage}
                alt="User"
                className="w-24 h-24 rounded-full mb-4 object-cover hover:brightness-90 transition-all duration-200"
              />
              {/* The edit icon */}
              <div className="flex justify-center items-center absolute bottom-0 right-0 bg-blue-500 rounded-full w-[25px] h-[25px]">
                <FiEdit2 size={15} className="text-white" />
              </div>
              {/* Hidden file input */}
              <input
                id="profile-pic-upload"
                type="file"
                accept="image/*"
                className="hidden"
                onChange={handleFileChange}
              />
            </label>

            <h2 className="text-lg font-semibold">{`${first_name} ${last_name}`}</h2>
            <p className="text-gray-500 mb-2">{email}</p>
            <p className="text-gray-500 mb-4 flex flex-col justify-center items-center">
              {verifiedStatus()}
            </p>
            <div className="flex space-x-2">
              <button
                onClick={() => setIsModalOpen(true)}
                className="w-40 p-2 bg-[#1399FF] text-white rounded-full"
              >
                Update
              </button>
              <button
                onClick={() => navigate("/forgotpassword")}
                className="w-40 p-2 bg-red-500 text-white rounded-full"
              >
                Change Password
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Profile Update Modal */}
      <ProfileUpdateModal
        isOpen={isModalOpen}
        onRequestClose={handleCloseModal}
        initialValues={initialValuesFull}
      />
    </div >
  );
};

export default Profile;
