import { CREATE_TICKET_FAILURE, CREATE_TICKET_REQUEST, CREATE_TICKET_SUCCESS } from "../createTicketAction/createTicketConstant";

const initialState = {
    loading: false,
    ticket: null,
    error: null,
  };
  
  const createTicketReducer = (state = initialState, action) => {
    switch (action.type) {
      case CREATE_TICKET_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case CREATE_TICKET_SUCCESS:
        return {
          ...state,
          loading: false,
          event: action.payload,
        };
      case CREATE_TICKET_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default createTicketReducer;
  