// src/ProtectedRoute/OrganizerRoute.jsx

import React, { useEffect } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getProfile } from "../redux/auth/action/Profile";
import { fetchOrganiserEvents } from "../redux/OrganiserEvent/organiserEventAction/GetOrganiserEventAction";
import Shimmer from '../components/layout/Shimmer';
import { toast } from 'react-toastify';

const OrganizerRoute = ({ children }) => {
    const { id } = useParams(); // Event ID from the URL
    const dispatch = useDispatch();
    const token = localStorage.getItem("access");

    // Selector for organizer's events
    const {
        loading: organiserEventsLoading,
        data: organiserEvents,
        error: organiserEventsError,
    } = useSelector((state) => state.getOrganiserEvent);

    // Selector for user profile
    const {
        loading: profileLoading,
        profile,
        error: profileError,
    } = useSelector((state) => state.profile);

    // // Fetch user profile if not already loaded
    useEffect(() => {
        if (token && !profile && !profileLoading && !profileError) {
            dispatch(getProfile());
        }
    }, [dispatch, token, profile, profileLoading, profileError]);

    // Fetch organizer's events if profile is loaded and events are not yet fetched
    useEffect(() => {
        if (
            token &&
            profile &&
            profile.id &&
            !organiserEvents &&
            !organiserEventsLoading &&
            !organiserEventsError
        ) {
            dispatch(fetchOrganiserEvents(profile.id));
        }
    }, [dispatch, token, profile, organiserEvents, organiserEventsLoading, organiserEventsError]);

    // Handle unauthenticated access
    if (!token) {
        return <Navigate to="/login" replace />;
    }

    // Handle loading states
    if (organiserEventsLoading && profileLoading) {
        return (
            <div className="flex justify-center items-center min-h-screen">
                <Shimmer width="200px" height="50px" borderRadius="rounded" />
            </div>
        );
    }

    // Handle errors
    if (organiserEventsError || profileError) {
        toast.error("Failed to verify organizer status.");
        return <Navigate to="/not-authorized" replace />;
    }

    // Ensure that organizer events are loaded
    if (!organiserEvents) {
        // Optionally, display a loading indicator or a message
        return (
            <div className="flex justify-center items-center min-h-screen">
                <Shimmer width="200px" height="50px" borderRadius="rounded" />
            </div>
        );
    }

    // Determine if the current user is the organizer of the event
    console.log("[PROTECTED ROUTE TESTING] Checking if user is organizer for event id:", id);
    console.log("[PROTECTED ROUTE TESTING] Organizer Events:", organiserEvents);
    const isOrganizer = organiserEvents?.some(
        (eventItem) => String(eventItem.event_id) === String(id)
    );
    console.log("[PROTECTED ROUTE TESTING] Is Organizer:", isOrganizer);

    if (!isOrganizer) {
        return <Navigate to="/not-authorized" replace />;
    }

    return children;
};

export default OrganizerRoute;
