import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate, Link } from "react-router-dom";
import { EventGetItems } from "../../../../redux/createEvent/createEventAction/EventDetailAction";
import { getUserProfile } from "../../../../redux/auth/action/userProfileActions";
import { MdCategory } from "react-icons/md";
import Navbar from "../../../layout/Navbar/Navbar";
import Footer from "../../../layout/Footer/Footer";
import { toast, ToastContainer } from "react-toastify";
import { FaTicketAlt, FaCalendarAlt, FaCar, FaBus, FaWalking, FaBicycle } from 'react-icons/fa';
import { CiLocationOn, CiClock2, CiShare2, CiEdit } from "react-icons/ci";
import { IoChevronDownOutline, IoChevronUpOutline } from "react-icons/io5";
import Shimmer from '../../../layout/Shimmer';


const HomeDetail = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const accessToken = localStorage.getItem("access");
  const { loading: eventItemsLoading, data, error } = useSelector((state) => state.eventItems);
  const { loading: profileLoading, profile, error: profileError } = useSelector((state) => state.profile);
  const { loading: organiserEventsLoading, data: organiserEvents, error: organiserEventsError } = useSelector((state) => state.getOrganiserEvent);
  const { is_verified } = profile || {};
  const [showMap, setShowMap] = useState(false);
  const [mapLoading, setMapLoading] = useState(false);

  useEffect(() => {
    if (id) {
      dispatch(EventGetItems(id));
      if (accessToken) {
        dispatch(getUserProfile());
        // dispatch(fetchOrganiserEvents())
      }
    }

  }, [accessToken, dispatch, id]);

  const defaultImage = "https://via.placeholder.com/300x200.png?text=Default+Image";

  if (error) return <p>Error: {error}</p>;
  if (profileError) return <p>Error: {profileError}</p>;

  const handleGetTicketsClick = () => {
    if (profileLoading) {
      toast.info("Loading your profile information. Please wait...");
      return;
    }

    if (!accessToken) {
      toast.error("You need to be logged in in order to purchase tickets.");
      return;
    }

    if (accessToken && typeof is_verified === 'undefined') {
      toast.error("Failed to load profile information. Please try again.");
      return;
    }

    if (accessToken && is_verified) {
      console.log("User is verified.");
      // navigate("/readyfortickets");
      toast.info("Ticket purchase for organizers is currently unavailable. Please log in as a user to do so.")
    } else if (accessToken && is_verified === false) {
      toast.error(
        <>
          <p>You must be verified before you can purchase tickets.</p>
          <p>Please check your email for a link to verify your account.</p>
        </>
      );
      return;
    } else {
      console.log("User is not logged in.");
      navigate("/login");
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { weekday: 'short', day: 'numeric', month: 'long', year: 'numeric' };
    return new Intl.DateTimeFormat('en-GB', options).format(date);
  };

  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: 'Check out this awesome link!',
          text: 'I found this amazing website you should see.',
          url: data?.share_link || 'https://your-website.com',
        });
      } catch (error) {
        console.error('Error sharing:', error);
      }
    } else {
      alert('Web Share API is not supported in your browser.');
    }
  };

  const isOrganizer = organiserEvents?.some((eventItem) => eventItem?.event_id === data?.event_id);
  const isButtonDisabled = profileLoading || typeof is_verified === 'undefined';

  // Construct location query for Google Maps
  const locationQuery = `${data?.venue_name || ""} ${data?.venue_address || ""} ${data?.venue_post_code || ""} ${data?.event_origin_country || ""}`

  // Helper function to format the price
  const formatPrice = (price) => {
    if (price === '0.00' || price === 0 || price === null) return 'Free';
    const num = parseFloat(price);
    if (Number.isInteger(num)) {
      return `From £${num}`;
    }
    return `From £${num.toFixed(2)}`;
  };

  return (
    <div className="flex flex-col min-h-screen bg-[#f2f4f7]">
      <Navbar />
      <ToastContainer position="top-right" autoClose={3000} />
      <div className="flex-grow p-5 flex justify-center">
        <div className="w-full max-w-5xl flex flex-col font-poppins">

          <div className="relative">
            {/* Venue and Time */}
            <div className="flex flex-col lg:flex-row justify-between items-center mt-4 mb-2 pr-0 lg:pr-16">
              {eventItemsLoading ? (
                <div className="flex flex-col lg:flex-row gap-2 w-full md:w-auto">
                  <Shimmer width="200px" height="20px" borderRadius="rounded" />
                  <Shimmer width="150px" height="20px" borderRadius="rounded" />
                </div>
              ) : (
                <div className="flex flex-col lg:flex-row">
                  <p className="m-0 flex justify-center items-center">
                    <CiLocationOn className="mr-1" size={20} />{' '}
                    {data?.venue_name || 'N/A'}, {data?.venue_address || "N/A"}, {data?.event_origin_country || 'N/A'}, {data?.venue_post_code || "N/A"}
                  </p>
                  <p className="ms-3 mt-md-0 mb-0 flex justify-center items-center">
                    <CiClock2 className="mr-1" size={20} />{' '}
                    {data?.start_time ? formatDate(data.start_date) : 'N/A'},{' '}
                    {data?.start_time ? data.start_time.replace(/:00$/, '') : 'N/A'} Onwards
                  </p>
                </div>
              )}
            </div>

            {/* Updated Buttons Container */}
            <div className="flex justify-end space-x-2 mt-2 md:absolute md:right-2 md:bottom-1">
              {eventItemsLoading ? (
                <Shimmer width="21px" height="21px" borderRadius="rounded-full" />
              ) : (
                <>
                  {isOrganizer && (
                    <Link to={`/editForm/${data?.event_id}`}>
                      <button className="p-1 rounded-lg button-hover-effect" title="Edit Event">
                        <CiEdit size={21} className="text-black" />
                      </button>
                    </Link>
                  )}
                  <button className="p-1 rounded-lg button-hover-effect" onClick={handleShare} title="Share Event">
                    <CiShare2 size={21} className="text-black" />
                  </button>
                </>
              )}
            </div>
          </div>

          {/* Thumbnail Image */}
          {eventItemsLoading ? (
            <div className="relative w-full aspect-16/9 overflow-hidden mb-4">
              <Shimmer width="100%" height="500px" borderRadius="rounded-xl" />
            </div>
          ) : (
            <>
              <div
                className="relative rounded-xl overflow-hidden mb-4 w-full"
                style={{ paddingBottom: '56.25%' }}
              >
                <img
                  src={data?.thumbnail_path}
                  alt="Event Thumbnail"
                  className="absolute top-0 left-0 w-full h-full object-cover"
                />
              </div>
            </>
          )}

          {/* Event Name */}
          {eventItemsLoading ? (
            <Shimmer width="60%" height="24px" borderRadius="rounded" className="mb-2" />
          ) : (
            <h1 className="text-2xl font-bold mb-2">{data?.event_name}</h1>
          )}

          {/* Event Price */}
          {eventItemsLoading ? (
            <Shimmer width="30%" height="20px" borderRadius="rounded" className="mb-3" />
          ) : (
            <p className="text-lg text-gray-700 mb-3">
              {formatPrice(data?.ticket_price)}
            </p>
          )}

          {/* Event Category/s */}
          <div className={`flex ${data?.subcategory_name ? "mb-2" : "mb-3"} gap-2`}>
            {
              eventItemsLoading ? (
                <>
                  <Shimmer width="90px" height="35px" borderRadius="rounded-full" />
                  {/* <Shimmer width="90px" height="35px" borderRadius="rounded-full" /> */}
                </>
              ) : data?.category_name ? (
                <button className="
                    min-w-[90px]
                    max-w-full
                    h-[30px]
                    sm:h-[35px]
                    sm:max-w-xs
                    flex
                    items-center
                    justify-center
                    text-xs
                    sm:text-sm
                    text-black
                    py-2
                    rounded-full"
                  style={{
                    border: "2px solid rgba(255, 0, 0, 0.25)"
                    ,
                    //  backgroundColor: "rgba(255, 0, 0, 0.05)"
                  }}
                >
                  {data.category_name}
                </button>

                // COULD BE USED LATER / HANDLES MULTIPLE CATEGORIES ARRAY
                // ) : data?.category_names ? (
                //   data.category_names.map((category) => (
                //     <button className="
                //     min-w-[90px]
                //     max-w-full
                //     h-[30px]
                //     sm:h-[35px]
                //     sm:max-w-xs
                //     flex
                //     items-center
                //     justify-center
                //     text-xs
                //     sm:text-sm
                //     text-black
                //     py-2
                //     rounded-full"
                //       style={{
                //         border: "2px solid rgba(255, 0, 0, 0.25)"
                //         ,
                //         //  backgroundColor: "rgba(255, 0, 0, 0.05)"
                //       }}
                //     >
                //       {category}
                //     </button>
                //   ))

              ) : (
                <p><i>No categories available</i></p>
              )
            }
          </div>

          {/* Event Category/s */}
          <div className={`flex ${data?.subcategory_name ? "mb-3" : "mb-0"} gap-2`}>
            {
              eventItemsLoading ? (
                <>
                  <Shimmer width="90px" height="35px" borderRadius="rounded-full" />
                  {/* <Shimmer width="90px" height="35px" borderRadius="rounded-full" /> */}
                </>
              ) : data?.subcategory_name ? (
                <button className="
                    min-w-[90px]
                    max-w-full
                    h-[30px]
                    sm:max-w-xs
                    flex
                    items-center
                    justify-center
                    text-xs
                    text-black
                    py-2
                    px-2
                    rounded-full"
                  style={{
                    border: "2px solid rgba(255, 0, 0, 0.25)"
                    ,
                    //  backgroundColor: "rgba(255, 0, 0, 0.05)"
                  }}
                >
                  {data.subcategory_name}
                </button>
              ) : (
                null
              )
            }
          </div>

          {/* Ticket and Calendar Options */}
          {eventItemsLoading ? (
            <div className="flex flex-col sm:flex-row gap-4">
              <Shimmer width="100%" height="48px" borderRadius="rounded-full" />
              <Shimmer width="100%" height="48px" borderRadius="rounded-full" />
              <Shimmer width="100%" height="48px" borderRadius="rounded-full" />
            </div>
          ) : (
            <div className="flex flex-col sm:flex-row gap-4">
              <button
                className="btn-light-pink scaling-button w-full max-w-full sm:max-w-xs flex items-center justify-center bg-red-500 text-white py-2 rounded-full"
                onClick={handleGetTicketsClick}
              >
                Get Tickets <FaTicketAlt className="ms-2" />
              </button>
              <button className="btn-teal scaling-button w-full max-w-full sm:max-w-xs flex items-center justify-center bg-red-500 text-white py-2 rounded-full">
                Add to Calendar <FaCalendarAlt className="ms-2" />
              </button>
            </div>
          )}

          {/* Event Details */}
          <div className="mt-7">
            <p className="text-xl font-semibold mb-2">Event Details</p>
            {eventItemsLoading ? (
              <div className="space-y-4">
                <Shimmer width="100%" height="16px" borderRadius="rounded" />
                <Shimmer width="90%" height="16px" borderRadius="rounded" />
                <Shimmer width="95%" height="16px" borderRadius="rounded" />
                <Shimmer width="80%" height="16px" borderRadius="rounded" />
              </div>
            ) : (
              <p
                className="event-description text-gray-700 leading-relaxed space-y-4"
                dangerouslySetInnerHTML={{ __html: formatDescription(data?.description) }}
              />
            )}
            {/* Event Organizer */}
            <div className="h-[1px] w-full mx-auto my-4 bg-gray-200"></div>
            <div className="flex justify-start opacity-80 text-sm font-poppins">
              <span className="me-1">Organizator: </span>
              {eventItemsLoading ? (
                <Shimmer width="50%" height="20px" borderRadius="rounded" />
              ) : (
                <span className="font-semibold">
                  {data?.organizer_company ? data.organizer_company : <i>No information</i>}
                </span>
              )}
            </div>
          </div>

          {/* Location Section */}
          {eventItemsLoading ? (
            <div className="mt-7 space-y-4">
              <Shimmer width="30%" height="24px" borderRadius="rounded" />
              <Shimmer width="100%" height="200px" borderRadius="rounded" />
              <div className="flex justify-around w-full md:w-[550px]">
                <Shimmer width="60px" height="60px" borderRadius="rounded-full" />
                <Shimmer width="60px" height="60px" borderRadius="rounded-full" />
                <Shimmer width="60px" height="60px" borderRadius="rounded-full" />
                <Shimmer width="60px" height="60px" borderRadius="rounded-full" />
              </div>
            </div>
          ) : (
            locationQuery && (
              <div className="mt-7">
                <p className="text-xl font-semibold mb-2">Location</p>

                {/* Full Address with Icon */}
                <div className={`flex items-start text-gray-700 ${showMap || (!data?.extra_image && !data?.extra_image2) ? "mb-4" : "mb-0"}`}>
                  <CiLocationOn size={24} className="mr-2" />

                  <div className="flex flex-col gap-y-1">
                    <span className="block">
                      <strong>
                        {data?.venue_name || 'N/A'}
                      </strong>
                    </span>
                    <span className="block">
                      {data?.venue_address || "N/A"}, {data?.event_origin_country || 'N/A'}, {data?.venue_post_code || "N/A"}
                    </span>
                    {/* Show/Hide Map Button */}
                    <button
                      onClick={() => {
                        setShowMap(!showMap);
                        if (!showMap) {
                          setMapLoading(true); // Set loading to true when showing the map
                        }
                      }}
                      className="flex items-center text-blue-500 hover:underline focus:outline-none"
                    >
                      {showMap ? 'Hide Map' : 'Show Map'}
                      {showMap ? (
                        <IoChevronUpOutline size={20} className="ml-1" />
                      ) : (
                        <IoChevronDownOutline size={20} className="ml-1" />
                      )}
                    </button>
                  </div>
                </div>

                {/* Map and Navigation Buttons */}
                {showMap && (
                  <>
                    {/* Shimmer while map is loading */}
                    {mapLoading && (
                      <div className="w-full aspect-video md:w-[720px] md:aspect-[16/9] mb-4">
                        <Shimmer width="100%" height="100%" borderRadius="rounded-xl" />
                      </div>
                    )}

                    {/* Map iframe */}
                    <div className={`w-full aspect-video md:w-[720px] md:aspect-[16/9] mb-4 ${mapLoading ? 'hidden' : 'block'}`}>
                      <iframe
                        title="Event Location Map"
                        className="w-full h-full"
                        frameBorder="0"
                        style={{ border: 0 }}
                        src={`https://maps.google.com/maps?q=${encodeURIComponent(
                          locationQuery
                        )}&output=embed`}
                        allowFullScreen
                        onLoad={() => setMapLoading(false)} // Set loading to false once the map loads
                      ></iframe>
                    </div>

                    {/* Navigation Buttons */}
                    <div className="flex justify-around w-full md:w-[720px]">
                      <a
                        href={`https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(
                          locationQuery
                        )}&travelmode=driving`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="flex flex-col items-center text-gray-700 rounded-lg pt-2 px-2 pb-1 button-hover-effect"
                      >
                        <FaCar size={24} />
                        <span>Drive</span>
                      </a>
                      <a
                        href={`https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(
                          locationQuery
                        )}&travelmode=transit`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="flex flex-col items-center text-gray-700 rounded-lg pt-2 px-2 pb-1 button-hover-effect"
                      >
                        <FaBus size={24} />
                        <span>Transit</span>
                      </a>
                      <a
                        href={`https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(
                          locationQuery
                        )}&travelmode=walking`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="flex flex-col items-center text-gray-700 rounded-lg pt-2 px-2 pb-1 button-hover-effect"
                      >
                        <FaWalking size={24} />
                        <span>Walk</span>
                      </a>
                      <a
                        href={`https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(
                          locationQuery
                        )}&travelmode=bicycling`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="flex flex-col items-center text-gray-700 rounded-lg pt-2 px-3 pb-1 button-hover-effect"
                      >
                        <FaBicycle size={24} />
                        <span>Bike</span>
                      </a>
                    </div>
                  </>
                )}
              </div>
            )
          )}

          {/* Additional Images */}
          {eventItemsLoading ? (
            <div className="mt-8">
              <p className="text-xl font-semibold mb-2">Additional Images</p>

              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                <Shimmer width="100%" height="200px" borderRadius="rounded-md" />
                <Shimmer width="100%" height="200px" borderRadius="rounded-md" />
              </div>
            </div>
          ) : data?.extra_image || data?.extra_image2 ? (
            <div className="mt-8">
              <p className="text-xl font-semibold mb-2">Additional Images</p>

              <div className="flex flex-col sm:flex-row gap-4">
                {data?.extra_image ? (
                  <img
                    src={data?.extra_image}
                    alt="Additional 1"
                    className="rounded-md w-full h-auto max-w-[300px] max-h-[200px] object-cover"
                  />
                ) : null}

                {data?.extra_image2 ? (
                  <img
                    src={data?.extra_image2 || defaultImage}
                    alt="Additional 2"
                    className="rounded-md w-full h-auto max-w-[300px] max-h-[200px] object-cover"
                  />
                ) : null}

              </div>
            </div>
          ) : null}

        </div>
      </div>
      <Footer />
    </div >
  );
};

const formatDescription = (description) => {
  if (!description) return '';
  return description
    .replace(/\n/g, '<br/>') // Handle line breaks
    .replace(/•/g, '<ul class="list-disc ml-5"><li>') // Handle bullet points
    .replace(/<\/li>(?!<li>)/g, '</li></ul>'); // Close unordered lists properly
};

export default HomeDetail;
