import { toast } from "react-toastify";
import apiClient from "../../../apiClient/apiClient";
import { CREATE_TICKET_REQUEST, CREATE_TICKET_SUCCESS, CREATE_TICKET_FAILURE } from "./createTicketConstant";


export const createTicketRequest = () => ({
    type: CREATE_TICKET_REQUEST,
  });
  
export const createTicketSuccess = (event) => ({
    type: CREATE_TICKET_SUCCESS,
    payload: event,
  });
  
export const createTicketFailure = (error) => ({
    type: CREATE_TICKET_FAILURE,
    payload: error,
  });

export const createTicket = (formData) => async (dispatch) => {
    dispatch({
        type: CREATE_TICKET_REQUEST
    })
    try {
        const token = localStorage.getItem("access");
        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        };
    
        const response = await apiClient.post("api/tickets/", formData, config);
    
        dispatch({
          type: CREATE_TICKET_SUCCESS,
          payload: response.data,
        });
      } catch (error) {
        let errorMessage =
          "An error occurred while creating the ticket. Please try again.";
    
        if (error.response && error.response.data) {
          // Check for specific field errors
          const {
            event_name,
            price,
            total_quantity,
            available_quantity,
            ticket_sold,
            ticket_category,
            custom_category,
            ticket_description
          } = error.response.data;
          if (event_name) {
            errorMessage = `Event name: ${event_name}`;
          }
          if (price) {
            errorMessage = `Ticket Price: ${price}`;
          }
          if (total_quantity) {
            errorMessage = `Total Quantity:${total_quantity}`;
          }
          if (available_quantity) {
            errorMessage = `Available Quantity:${available_quantity}`;
          }
          if (ticket_sold) {
            errorMessage = `Ticket Sold:${ticket_sold}`;
          }
          if (ticket_category) {
            errorMessage = `Ticket Category:${ticket_category}`;
          }
          if (custom_category) {
            errorMessage = `Custom Category:${custom_category}`;
          }
          if (ticket_description) {
            errorMessage = `Ticket Description:${ticket_description}`;
          } else {
            errorMessage = error.response.data.message || errorMessage;
          }
        }
    
        dispatch({
          type: CREATE_TICKET_FAILURE,
          payload: errorMessage,
        });

        toast.error(errorMessage);
      }
} 