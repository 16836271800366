// src/components/CreateEventForm.jsx

import React, { useEffect, useState, useRef } from "react";
import { Formik, Field, Form, ErrorMessage, useFormikContext } from "formik";
import * as Yup from "yup";
import {
  IoChevronDownOutline,
  IoChevronUpOutline,
  IoCloudUploadSharp,
  IoSearch,
} from "react-icons/io5";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AddressFinder } from "@ideal-postcodes/address-finder";
import { useDispatch, useSelector } from "react-redux";
import { format } from "date-fns";
import { createEvent } from "../../redux/createEvent/createEventAction/createEventAction";
import { fetchSubcategories } from "../../redux/auth/action/subCategoryActions";
import { fetchCategories } from "../../redux/auth/action/categoryActions";
import Navbar from "../../components/layout/Navbar/Navbar";
import { getProfile } from "../../redux/auth/action/Profile";
import Footer from "../../components/layout/Footer/Footer";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const AddressFinderSetup = () => {
  const { setFieldValue } = useFormikContext();

  useEffect(() => {
    const setupAddressFinder = () => {
      AddressFinder.setup({
        inputField: "#line_1",
        apiKey: "ak_test", // Replace with your actual API key
        outputFields: {
          line_1: "#line_1",
          line_2: "#line_2",
          line_3: "#line_3",
          post_town: "#post_town",
          postcode: "#postcode",
        },
        onAddressSelected: (selectedAddress) => {
          // Update Formik fields using setFieldValue
          setFieldValue("line_1", selectedAddress.line_1 || "");
          setFieldValue("line_2", selectedAddress.line_2 || "");
          setFieldValue("line_3", selectedAddress.line_3 || "");
          setFieldValue("post_town", selectedAddress.post_town || "");
          setFieldValue("postcode", selectedAddress.postcode || "");
        },
      });
    };

    setupAddressFinder();
    return () => { };
  }, [setFieldValue]);

  return null;
};

const CreateEventForm = () => {
  const [showDropdown, setShowDropdown] = useState(false);
  const dispatch = useDispatch();
  const [fields, setFields] = useState([]);
  const [areaCode, setAreaCode] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedImage1, setSelectedImage1] = useState(null);
  const [selectedImage2, setSelectedImage2] = useState(null);
  const [selectedImage3, setSelectedImage3] = useState(null);
  const [countries, setCountries] = useState([]);
  const [showAdditionalFields, setShowAdditionalFields] = useState(false);
  const [showAdditionalManually, setShowAdditionalManually] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubcategory, setSelectedSubcategory] = useState(null);
  const [showEventTypeDropdown, setShowEventTypeDropdown] = useState(false); // New state variable
  const [selectedEventType, setSelectedEventType] = useState(""); // New state to hold selected event type
  const countryDropdownRef = useRef(null); // Reference for Country Dropdown
  const eventTypeDropdownRef = useRef(null); // Reference for Event Type Dropdown
  const eventTypeChoices = [
    { key: 'trending', label: 'Trending Event in UK' },
    { key: 'community', label: 'Community Events' },
    { key: 'new_listing', label: 'New Listing' },
    { key: 'most_popular_free', label: 'Most Popular Free Events' },
    { key: 'latest', label: 'Latest Events' },
    { key: 'virtual', label: 'Virtual Events' },
    { key: 'festivals', label: 'Festivals and Carnivals' },
    { key: 'concerts', label: 'Concerts & Gigs' },
    { key: 'workshops', label: 'Workshops & Seminars' },
    { key: 'last_minute_deals', label: 'Last-Minute Deals' },
    { key: 'local_favorites', label: 'Local Favorites' },
    { key: 'nightlife', label: 'Nightlife & Parties' },
    { key: 'sports', label: 'Sports Events' },
    { key: 'cultural', label: 'Cultural & Heritage Events' },
    { key: 'art_exhibitions', label: 'Art & Exhibitions' },
    { key: 'business_networking', label: 'Business & Networking Events' },
    { key: 'popular_external', label: 'Popular Across Other Platforms' },
  ];

  // Initial values for Formik
  const initialValues = {
    event_name: "",
    organizer: "",
    organizer_company: "",
    category_id: null,
    subcategory_id: null,
    description: "",
    start_date: "",
    start_time: "",
    end_time: "",
    event_origin_country: "",
    ticket_price: "",
    capacity: "",
    venue_name: "",
    share_link: "",
    external_url: "", // New field added here
    line_1: "",
    post_town: "",
    line_2: "",
    postcode: "",
    thumbnail_path: null,
    event_type: "", // New field added here
  };



  const validationSchema = Yup.object().shape({
    thumbnail_path: Yup.mixed().required("Thumbnail image is required"),
    event_name: Yup.string().required("Event name is required"),
    organizer: Yup.string(),
    organizer_company: Yup.string(),
    description: Yup.string().required("Description is required"),
    start_date: Yup.date()
      .nullable()
      .required("Start date is required")
      .min(new Date(), "Start date cannot be in the past"),
    start_time: Yup.string().required("Start time is required"),
    end_time: Yup.string().required("End time is required"),
    event_origin_country: Yup.string().required("Country is required"),
    category_id: Yup.number()
      .typeError("Category is required")
      .required("Category is required"),
    subcategory_id: Yup.number().nullable(),
    capacity: Yup.number()
      .typeError("Capacity must be a number")
      .required("Capacity is required")
      .integer("Capacity must be an integer")
      .min(1, "Capacity must be at least 1"),
    ticket_price: Yup.number()
      .typeError("Ticket price must be a number")
      .required("Ticket price is required")
      .min(0, "Ticket price cannot be negative"),
    line_1: Yup.string().required("Address line 1 is required"),
    post_code: Yup.string(),
    share_link: Yup.string()
      .when("event_type", ([eventType], schema) => {
        if (eventType === "trending") {
          return schema.url("Any external link must be a valid URL").nullable();
        }
        return schema.url("Any external link must be a valid URL").nullable();
      }),
    event_type: Yup.string().required("Event type is required"),

    external_url: Yup.string().when("event_type", ([eventType], schema) => {
      if (eventType !== "trending") {
        return schema
          .required("External URL is required for selected event type")
          .url("External URL must be a valid URL");
      }
      return schema.url("External URL must be a valid URL").nullable();
    }),
  });

  // Form submission handler
  const handleSubmit = (values, { resetForm, setSubmitting }) => {
    const formData = new FormData();

    // Add form data to FormData object    
    if (values.category_id) {
      formData.append('category', values.category_id); // Append single category ID
    }

    if (values.subcategory_id) {
      formData.append('subcategory', values.subcategory_id);
    }

    formData.append("event_origin_country", values.event_origin_country);

    if (selectedImage1) {
      formData.append("thumbnail_path", selectedImage1);
    }

    if (selectedImage2) {
      formData.append("extra_image", selectedImage2);
    }

    if (selectedImage3) {
      formData.append("extra_image2", selectedImage3);
    }

    formData.append("event_type", values.event_type);
    formData.append("venue_name", values.line_1);
    formData.append("event_name", values.event_name);
    formData.append("organizer", values.organizer);
    formData.append("organizer_company", values.organizer_company);
    formData.append("description", values.description);
    formData.append("start_date", values.start_date);
    formData.append("start_time", values.start_time);
    formData.append("end_time", values.end_time);
    formData.append("ticket_price", values.ticket_price);
    formData.append("capacity", values.capacity);
    formData.append("share_link", values.share_link);
    formData.append("external_url", values.external_url); // new external url
    formData.append("venue_address", values.line_2);
    formData.append("venue_post_code", values.postcode);

    console.log(formData);
    dispatch(createEvent(formData))
      .then(() => {
        resetForm();
        setSelectedImage1(null);
        setSelectedImage2(null);
        setSelectedImage3(null);
        setSelectedCategory("");
        setSelectedSubcategory("");
        // This is already handled in the redux action call
        // toast.success("Event created successfully!"); 
      })
      .catch((error) => {
        console.error(error);
        toast.error("Error creating event.");
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  // Fetch countries for the country dropdown
  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await axios.get("https://restcountries.com/v3.1/all");
        const countriesData = response.data.map((country) => ({
          name: country.name.common,
          areaCode: country.cca2,
        }));
        setCountries(countriesData);
      } catch (error) {
        console.error("Error fetching countries:", error);
      }
    };

    fetchCountries();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        countryDropdownRef.current &&
        !countryDropdownRef.current.contains(event.target)
      ) {
        setShowDropdown(false);
      }
      if (
        eventTypeDropdownRef.current &&
        !eventTypeDropdownRef.current.contains(event.target)
      ) {
        setShowEventTypeDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Handle country selection
  const handleSelectCountry = (country, setFieldValue) => {
    setSelectedCountry(country.name);
    setAreaCode(country.areaCode);
    setShowDropdown(false);
    setFieldValue("event_origin_country", country.name);
  };

  // Handle image uploads
  const onImageChange = (e, setImage) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);
    }
  };

  // Fetch subcategories
  const { subcategories, loading: subcategoryLoading, error: subcategoryError } = useSelector((state) => state.subcategory);
  useEffect(() => {
    dispatch(fetchSubcategories());
  }, [dispatch]);

  // Fetch categories
  const { categories, loading: categoryLoading, error: categoryError } = useSelector((state) => state.category);
  useEffect(() => {
    dispatch(fetchCategories());
  }, [dispatch]);

  const profileState = useSelector((state) => state.profile);
  useEffect(() => {
    dispatch(getProfile());
  }, [dispatch]);

  if (categoryLoading || subcategoryLoading) return <p>Loading...</p>;
  if (categoryError) return <p>{categoryError}</p>;

  // Handle category selection
  const handleCategoryClick = (category, setFieldValue) => {
    const newSelectedCategory = category.category_id === selectedCategory ? null : category.category_id;
    setSelectedCategory(newSelectedCategory);
    setFieldValue("category_id", newSelectedCategory);

    // Reset subcategory selection when category changes
    setSelectedSubcategory(null);
    setFieldValue("subcategory_id", null);
  };

  // Handle subcategory selection
  const handleSubcategoryClick = (subcategory, setFieldValue) => {
    const newSelectedSubcategory = subcategory.subcategory_id === selectedSubcategory ? null : subcategory.subcategory_id;
    setSelectedSubcategory(newSelectedSubcategory);
    setFieldValue("subcategory_id", newSelectedSubcategory);
  };

  const handleManualEntryClick = () => {
    setShowAdditionalManually(!showAdditionalManually);
  };

  // React Quill modules and formats for toolbar customization
  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ script: "sub" }, { script: "super" }],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ color: [] }, { background: [] }],
      ["clean"],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "script",
    "list",
    "bullet",
    "color",
    "background",
    "link",
  ];

  // Filter subcategories based on selected category
  const filteredSubcategories = subcategories
    ? subcategories.filter((sub) => sub.category === selectedCategory)
    : [];

  return (
    <div className="w-full h-full font-poppins text-[#565656] bg-[#f2f4f7]">
      <Navbar linkTo="/home" title="New Event Creation" />
      <div className="xl:flex xl:gap-10 gap-10 flex flex-col xl:flex-row lg:flex-row mt-8 lg:mt-20">
        <ToastContainer position="top-right" autoClose={5000} />
        <div className="w-full min-w-0">
          <div className="flex flex-col w-full">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ values, setFieldValue, isSubmitting }) => (
                <Form className="flex flex-col lg:flex-row gap-20">
                  {/* Left Section: Image Uploads */}
                  <div className="w-full p-5 lg:w-[50vw]">
                    <p className="font-medium text-base text-center tracking-wider mb-5">
                      Upload Event Thumbnail <span className="text-red-500">*</span>
                    </p>
                    {/* Thumbnail Image */}
                    <div className="flex justify-center items-center mb-4 h-auto shadow-md bg-gray-300 w-64 mx-auto rounded-lg">
                      <label
                        htmlFor="file-upload-1"
                        className="cursor-pointer block w-full h-full p-2"
                      >
                        <input
                          id="file-upload-1"
                          type="file"
                          accept="image/*"
                          onChange={(e) => {
                            const file = e.target.files[0];
                            if (file && file.size > 2 * 1024 * 1024) { // 2MB in bytes
                              toast.error("File size exceeds 2MB. Please upload a smaller file.");
                              e.target.value = null; // Reset the input
                              setFieldValue("thumbnail_path", null); // Reset Formik field value
                              setSelectedImage1(null); // Reset local state
                              return;
                            }
                            onImageChange(e, setSelectedImage1);
                            setFieldValue("thumbnail_path", file); // Set Formik field value
                          }}
                          style={{ display: "none" }}
                        />
                        <ErrorMessage
                          name="thumbnail_path"
                          component="div"
                          className="text-red-500 text-sm ml-2"
                        />

                        <div className="mt-1 block w-full">
                          <div className="flex justify-center mb-2 mt-8">
                            {selectedImage1 ? (
                              <img
                                src={URL.createObjectURL(selectedImage1)}
                                alt="Selected"
                                className="max-w-full h-auto aspect-[3/2] rounded-lg object-cover"
                              />
                            ) : (
                              <IoCloudUploadSharp
                                color="#707DFF"
                                size={30}
                                className="w-[73.44px] h-[58.82px]"
                              />
                            )}
                          </div>
                          {!selectedImage1 && (
                            <>
                              <p className="font-medium text-base leading-7 flex justify-center mb-12">
                                Drop your file here
                              </p>
                              <p className="font-light text-xs leading-5 text-[#0875e1] underline mt-2 flex justify-center">
                                Maximum file size 2 MB to be uploaded
                              </p>
                            </>
                          )}
                        </div>
                        <div className="bg-[#0875E1] text-white p-2 rounded-full w-[100px] h-[40px] flex items-center justify-center mx-auto mt-5">
                          <p>Browse</p>
                        </div>
                      </label>
                    </div>
                    <p className="font-medium text-base text-center tracking-wider mb-5 mt-8">
                      Upload More Pictures
                    </p>
                    <div className="flex flex-col md:flex-row gap-4 justify-between md:items-center md:justify-center">
                      {/* Extra Image 1 */}
                      <div className="flex justify-center items-center mb-4 shadow-md w-[250px] bg-gray-300 rounded-lg">
                        <label
                          htmlFor="file-upload-2"
                          className="cursor-pointer block w-full p-2 h-auto"
                        >
                          <input
                            id="file-upload-2"
                            type="file"
                            accept="image/*"
                            onChange={(e) => {
                              const file = e.target.files[0];
                              if (file && file.size > 2 * 1024 * 1024) { // 2MB in bytes
                                toast.error("File size exceeds 2MB. Please upload a smaller file.");
                                e.target.value = null; // Reset the input
                                setSelectedImage2(null); // Reset local state
                                return;
                              }
                              onImageChange(e, setSelectedImage2);
                            }}
                            style={{ display: "none" }}
                          />
                          <div className="mt-1 block w-full">
                            <div className="flex justify-center mt-8">
                              {selectedImage2 ? (
                                <img
                                  src={URL.createObjectURL(selectedImage2)}
                                  alt="Selected"
                                  className="max-w-full h-auto aspect-[3/2] rounded-lg object-cover"
                                />
                              ) : (
                                <IoCloudUploadSharp
                                  color="#707DFF"
                                  size={30}
                                  className="w-[73.44px] h-[58.82px]"
                                />
                              )}
                            </div>
                            {!selectedImage2 && (
                              <>
                                <p className="font-medium text-base leading-7 flex justify-center mb-10">
                                  Drop your file here
                                </p>
                                <p className="font-light text-xs leading-5 text-[#0875e1] underline mt-2 flex justify-center">
                                  Maximum file size 2 MB to be uploaded
                                </p>
                              </>
                            )}
                          </div>
                          <div className="bg-[#0875E1] text-white p-2 rounded-full w-[100px] h-[40px] flex items-center justify-center mx-auto mt-5">
                            <p>Browse</p>
                          </div>
                        </label>
                      </div>

                      {/* Extra Image 2 */}
                      <div className="flex justify-center items-center mb-4 shadow-md w-[250px] bg-gray-300 rounded-lg">
                        <label
                          htmlFor="file-upload-3"
                          className="cursor-pointer block w-full p-2 h-auto"
                        >
                          <input
                            id="file-upload-3"
                            type="file"
                            accept="image/*"
                            onChange={(e) => {
                              const file = e.target.files[0];
                              if (file && file.size > 2 * 1024 * 1024) { // 2MB in bytes
                                toast.error("File size exceeds 2MB. Please upload a smaller file.");
                                e.target.value = null; // Reset the input
                                setSelectedImage3(null); // Reset local state
                                return;
                              }
                              onImageChange(e, setSelectedImage3);
                            }}
                            style={{ display: "none" }}
                          />
                          <div className="mt-1 block w-full">
                            <div className="flex justify-center mt-8">
                              {selectedImage3 ? (
                                <img
                                  src={URL.createObjectURL(selectedImage3)}
                                  alt="Selected"
                                  className="max-w-full h-auto aspect-[3/2] rounded-lg object-cover"
                                />
                              ) : (
                                <IoCloudUploadSharp
                                  color="#707DFF"
                                  size={30}
                                  className="w-[73.44px] h-[58.82px]"
                                />
                              )}
                            </div>
                            {!selectedImage3 && (
                              <>
                                <p className="font-medium text-base leading-7 flex justify-center mb-10">
                                  Drop your file here
                                </p>
                                <p className="font-light text-xs leading-5 text-[#0875e1] underline mt-2 flex justify-center">
                                  Maximum file size 2 MB to be uploaded
                                </p>
                              </>
                            )}
                          </div>
                          <div className="bg-[#0875E1] text-white p-2 rounded-full w-[100px] h-[40px] flex items-center justify-center mx-auto mt-5">
                            <p>Browse</p>
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>

                  {/* Right Section: Form Fields */}
                  <div className="w-full p-5 lg:w-[50vw]">
                    {/* Event Name */}
                    <div className="mb-4">
                      <p className="font-normal text-base tracking-wider">
                        Name of the Event <span className="text-red-500">*</span>
                      </p>
                      <Field
                        type="text"
                        name="event_name"
                        placeholder="Event title goes here"
                        className="w-full text-sm p-3 rounded-full pl-4 tracking-wide border border-[#0875E1] mt-2"
                      />
                      <ErrorMessage
                        name="event_name"
                        component="div"
                        className="text-red-500 text-sm ml-2"
                      />
                    </div>

                    {/* Organizer */}
                    <div className="mb-4">
                      <p className="font-normal text-base tracking-wider">
                        Organized by <span className="text-red-500">*</span>
                      </p>
                      <Field
                        type="text"
                        name="organizer"
                        value={
                          profileState?.profile?.first_name
                            ? profileState.profile.first_name
                            : "N/A"
                        }
                        placeholder="Organizer name"
                        className="w-full text-sm p-3 rounded-full pl-4 tracking-wide border border-[#0875E1] mt-2"
                        disabled
                      />
                      <ErrorMessage
                        name="organizer"
                        component="div"
                        className="text-red-500 text-sm ml-2"
                      />
                    </div>

                    {/* Organizer Company */}
                    <div className="mb-4">
                      <p className="font-normal text-base tracking-wider">
                        Organizer Company <span className="text-red-500">*</span>
                      </p>
                      <Field
                        type="text"
                        name="organizer_company"
                        value={profileState?.profile?.company_name || "N/A"}
                        placeholder="Organizer company"
                        className="w-full text-sm p-3 rounded-full pl-4 tracking-wide border border-[#0875E1] mt-2"
                        disabled
                      />
                      <ErrorMessage
                        name="organizer_company"
                        component="div"
                        className="text-red-500 text-sm ml-2"
                      />
                    </div>

                    {/* Description */}
                    <div className="mb-4">
                      <p className="font-normal text-base tracking-wider">
                        Description <span className="text-red-500">*</span>
                      </p>
                      <ReactQuill
                        theme="snow"
                        value={values.description}
                        onChange={(content, delta, source, editor) => {
                          setFieldValue("description", editor.getHTML());
                        }}
                        modules={modules}
                        formats={formats}
                        className="mt-2 border-[#0875E1] rounded-full"
                      />
                      <ErrorMessage
                        name="description"
                        component="div"
                        className="text-red-500 text-sm ml-2"
                      />
                    </div>

                    {/* Country Selection */}
                    <div className="mb-4 relative" ref={countryDropdownRef}>
                      <p className="font-normal text-base tracking-wider">
                        Event Country <span className="text-red-500">*</span>
                      </p>
                      <div className="relative flex items-center">
                        <input
                          onClick={() => setShowDropdown(!showDropdown)}
                          type="text"
                          name="event_origin_country"
                          placeholder="Choose your country"
                          value={values.event_origin_country}
                          onChange={(e) => {
                            setSelectedCountry(e.target.value);
                            setFieldValue(
                              "event_origin_country",
                              e.target.value
                            );
                          }}
                          className="w-full text-sm p-3 rounded-full pl-4 tracking-wide border border-[#0875E1] mt-2"
                        />
                        {showDropdown ? (
                          <IoChevronUpOutline
                            className="absolute right-4 text-gray-500 w-5 h-5 top-5 cursor-pointer"
                            onClick={() => setShowDropdown(!showDropdown)}
                          />
                        ) : (
                          <IoChevronDownOutline
                            className="absolute right-4 text-gray-500 w-5 h-5 top-5 cursor-pointer"
                            onClick={() => setShowDropdown(!showDropdown)}
                          />
                        )}
                      </div>
                      {showDropdown && (
                        <div className="absolute z-10 bg-white border border-gray-300 mt-1 max-w-200 rounded-lg max-h-60 overflow-y-auto">
                          {countries
                            .filter((country) =>
                              country.name
                                .toLowerCase()
                                .includes(selectedCountry.toLowerCase())
                            )
                            .map((country, index) => (
                              <div
                                key={index}
                                className="cursor-pointer p-2 hover:bg-gray-200"
                                onClick={() =>
                                  handleSelectCountry(country, setFieldValue)
                                }
                              >
                                {country.name}
                              </div>
                            ))}
                        </div>
                      )}
                      <ErrorMessage
                        name="event_origin_country"
                        component="div"
                        className="text-red-500 text-sm ml-2"
                      />
                    </div>

                    {/* Event Type */}
                    <div className="mb-4 relative" ref={eventTypeDropdownRef}>
                      <p className="font-normal text-base tracking-wider">
                        Event Type <span className="text-red-500">*</span>
                      </p>
                      <div className="relative">
                        <div
                          onClick={() => setShowEventTypeDropdown(!showEventTypeDropdown)}
                          className="w-full text-sm p-3 rounded-full pl-4 tracking-wide border bg-white border-[#0875E1] mt-2 cursor-pointer flex justify-between items-center"
                        >
                          <span className={selectedEventType ? "text-black" : "text-gray-400"}>
                            {selectedEventType
                              ? eventTypeChoices.find(choice => choice.key === selectedEventType)?.label
                              : "Select the event type"}
                          </span>
                          {showEventTypeDropdown ? (
                            <IoChevronUpOutline size={20} />
                          ) : (
                            <IoChevronDownOutline size={20} />
                          )}
                        </div>
                        {showEventTypeDropdown && (
                          <div className="absolute z-10 bg-white border border-gray-300 mt-1 w-full rounded-lg shadow-lg max-h-60 overflow-y-auto">
                            {eventTypeChoices.map((choice) => (
                              <div
                                key={choice.key}
                                onClick={() => {
                                  setSelectedEventType(choice.key);
                                  setFieldValue("event_type", choice.key);
                                  setShowEventTypeDropdown(false);
                                }}
                                className="p-2 hover:bg-gray-200 cursor-pointer"
                              >
                                {choice.label}
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                      <ErrorMessage
                        name="event_type"
                        component="div"
                        className="text-red-500 text-sm ml-2"
                      />
                    </div>

                    {/* Category Selection */}
                    <div className="mb-3">
                      <p className={`font-normal text-base tracking-wider ${filteredSubcategories.length ? "mb-0" : "mb-2"}`}>
                        Pick a Category <span className="text-red-500">*</span>
                      </p>
                      <div className="flex flex-wrap gap-2">
                        {categories && categories.length > 0 ? (
                          categories.map((category) => (
                            <div key={category.category_id}>
                              <p
                                type="button"
                                onClick={() =>
                                  handleCategoryClick(category, setFieldValue)
                                }
                                className={`py-2 px-3 m-1 border rounded-full flex justify-center transition duration-200 hover:brightness-90 hover:cursor-pointer ${selectedCategory === category.category_id
                                  ? "border-white bg-[#0875E1] text-white"
                                  : "border-[#0875E1] bg-white text-gray-600"
                                  }`}
                              >
                                {category.category_name}
                              </p>
                            </div>
                          ))
                        ) : (
                          <p>No categories available.</p>
                        )}
                      </div>
                    </div>

                    {/* Subcategory Selection */}
                    <div className="mb-4">
                      <p className={`font-normal text-base tracking-wider ${filteredSubcategories.length ? "mb-0" : "mb-2"}`}>
                        Pick a Subcategory
                      </p>
                      {filteredSubcategories.length > 0 ? (
                        <div className="flex flex-wrap gap-2">
                          {filteredSubcategories.map((subcategory) => (
                            <p
                              type="button"
                              onClick={() => handleSubcategoryClick(subcategory, setFieldValue)}
                              className={`py-2 px-3 m-1 border rounded-full flex justify-center transition duration-200 hover:brightness-90 hover:cursor-pointer ${selectedSubcategory === subcategory.subcategory_id
                                ? "border-white bg-[#0875E1] text-white"
                                : "border-[#0875E1] bg-white text-gray-600"
                                }`}
                              key={subcategory.subcategory_id}
                            >
                              {subcategory.name}
                            </p>
                          ))}
                        </div>
                      ) : selectedCategory ? (
                        <p className="text-gray-500">No subcategories available for the selected category.</p>
                      ) : (
                        <p className="text-gray-500">Please select a category first.</p>
                      )}
                      <ErrorMessage
                        name="subcategory_id"
                        component="div"
                        className="text-red-500 text-sm ml-2"
                      />
                    </div>


                    {/* Dates and Times */}
                    <div className="mb-4">
                      <div className="flex flex-row gap-2 items-center">
                        {/* Start Date */}
                        <div>
                          <p>Start Date <span className="text-red-500">*</span></p>
                          <div className="w-[120px]">
                            <DatePicker
                              selected={
                                values.start_date
                                  ? new Date(values.start_date)
                                  : null
                              }
                              minDate={new Date()}
                              onChange={(date) => {
                                const formattedDate = format(
                                  date,
                                  "yyyy-MM-dd"
                                );
                                setFieldValue("start_date", formattedDate);
                              }}
                              name="start_date"
                              placeholderText="Start Date"
                              className="border text-sm border-[#0875E1] p-2 rounded-full text-center h-[40px] w-[100px]"
                              onClick={(e) => e.currentTarget.showPicker()}
                            />
                          </div>
                          <ErrorMessage
                            name="start_date"
                            component="div"
                            className="text-red-500 text-sm ml-2"
                          />
                        </div>

                        {/* Start Time */}
                        <div>
                          <p>Start Time <span className="text-red-500">*</span></p>
                          <div className="w-[120px]">
                            <Field
                              type="time"
                              name="start_time"
                              className="border text-sm border-[#0875E1] p-2 rounded-full text-center h-[40px] w-[100px]"
                              onClick={(e) => e.currentTarget.showPicker()}
                            />
                            <ErrorMessage
                              name="start_time"
                              component="div"
                              className="text-red-500 text-sm ml-2"
                            />
                          </div>
                        </div>

                        {/* End Time */}
                        <div>
                          <p>End Time <span className="text-red-500">*</span></p>
                          <div className="w-[120px]">
                            <Field
                              type="time"
                              name="end_time"
                              className="border text-sm border-[#0875E1] p-2 rounded-full text-center h-[40px] w-[100px]"
                              onClick={(e) => e.currentTarget.showPicker()}
                            />
                            <ErrorMessage
                              name="end_time"
                              component="div"
                              className="text-red-500 text-sm ml-2"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Venue */}
                    <div className="mb-4">
                      <div className="flex items-center justify-between">
                        <p className="font-normal text-base tracking-wider">
                          Venue <span className="text-red-500">*</span>
                        </p>
                      </div>
                      <AddressFinderSetup />
                      <div className="relative gap-4 flex flex-col">
                        <div className="relative">
                          <Field
                            type="text"
                            id="line_1"
                            name="line_1"
                            placeholder="Start typing your venue"
                            className="w-full text-sm p-3 rounded-full pl-4 tracking-wide border border-[#0875E1] mt-2"
                            onClick={() => setShowAdditionalFields(true)}
                          />
                          <IoSearch
                            onClick={() => setShowAdditionalFields(true)}
                            className="absolute right-4 text-gray-500 w-7 h-7 bottom-2 items-center flex justify-center text-center cursor-pointer"
                          />
                        </div>
                        <ErrorMessage
                          name="line_1"
                          component="div"
                          className="text-red-500 text-sm ml-2"
                        />
                        {showAdditionalFields && (
                          <div>
                            <div>
                              <label htmlFor="line_2">Address:</label>
                              <Field
                                type="text"
                                id="line_2"
                                name="line_2"
                                placeholder="Venue Address"
                                className="w-full text-sm p-3 rounded-full pl-4 tracking-wide border border-[#0875E1] mt-2"
                              />
                              <Field
                                type="text"
                                id="postcode"
                                name="postcode"
                                placeholder="Postcode"
                                className="w-full text-sm p-3 rounded-full pl-4 tracking-wide border border-[#0875E1] mt-2"
                              />
                            </div>
                          </div>
                        )}
                      </div>
                      {showAdditionalManually && (
                        <div>
                          <h3>Enter address manually:</h3>
                          <Field
                            type="text"
                            name="venue_name"
                            placeholder="Venue Name"
                            className="w-full text-sm p-3 rounded-full pl-4 tracking-wide border border-[#0875E1] mt-2"
                          />
                        </div>
                      )}
                      <p
                        onClick={handleManualEntryClick}
                        className="text-sm underline cursor-pointer mt-2"
                      >
                        {showAdditionalManually
                          ? "Hide manual address entry"
                          : "Enter address manually"}
                      </p>
                    </div>

                    {/* Ticket Price */}
                    <div className="mb-4">
                      <p className="font-normal text-base tracking-wider">
                        Ticket Price <span className="text-red-500">*</span>
                      </p>
                      <div className="flex gap-2 w-[300px]">
                        <Field
                          name="ticket_price"
                          placeholder="£ 0.00"
                          className="w-full text-center text-sm p-3 rounded-full pl-4 tracking-wide border border-[#0875E1] mt-2"
                          disabled={values.ticket_price === "0"}
                          onChange={(e) => {
                            setFieldValue("ticket_price", e.target.value);
                            if (e.target.value !== "") {
                              setFieldValue("ticket_price", e.target.value);
                            }
                          }}
                        />
                        <Field
                          type="button"
                          name="ticket_price"
                          value="Free Ticket"
                          className={`w-full text-center text-sm p-3 rounded-full pl-4 tracking-wide border border-[#0875E1] mt-2 ${values.ticket_price === "0"
                            ? "bg-blue-100"
                            : "bg-white"
                            }`}
                          disabled={
                            values.ticket_price !== "" &&
                            values.ticket_price !== "0"
                          }
                          onClick={() => {
                            if (values.ticket_price !== "0") {
                              setFieldValue("ticket_price", "0");
                            } else {
                              setFieldValue("ticket_price", "");
                            }
                          }}
                        />
                      </div>
                      <ErrorMessage
                        name="ticket_price"
                        component="div"
                        className="text-red-500 text-sm ml-2"
                      />
                    </div>

                    {/* Capacity */}
                    <div className="mb-4">
                      <p className="font-normal text-base tracking-wider">
                        Capacity <span className="text-red-500">*</span>
                      </p>
                      <Field
                        type="number"
                        name="capacity"
                        placeholder="0"
                        className="w-[96px] text-center text-sm p-3 rounded-full pl-4 tracking-wide border border-[#0875E1] mt-2"
                      />
                      <ErrorMessage
                        name="capacity"
                        component="div"
                        className="text-red-500 text-sm ml-2"
                      />
                    </div>

                    {/* External Links - Shown when event_type is 'trending' */}
                    {selectedEventType === "trending" && (
                      <div className="mb-4">
                        <p className="font-normal text-base tracking-wider">
                          Any External Links
                        </p>
                        <div className="flex items-center justify-between gap-4 mt-2">
                          <Field
                            type="text"
                            name="share_link"
                            placeholder="Any external link"
                            className="w-full text-sm p-3 rounded-full pl-4 tracking-wide border border-[#0875E1]"
                          />
                        </div>
                        <ErrorMessage
                          name="share_link"
                          component="div"
                          className="text-red-500 text-sm ml-2"
                        />
                      </div>
                    )}

                    {/* External URL - Shown when event_type is NOT 'trending' */}
                    {selectedEventType !== "trending" && (
                      <div className="mb-4">
                        <p className="font-normal text-base tracking-wider">
                          External URL <span className="text-red-500">*</span>
                        </p>
                        <Field
                          type="text"
                          name="external_url"
                          placeholder="Enter external URL"
                          className="w-full text-sm p-3 rounded-full pl-4 tracking-wide border border-[#0875E1] mt-2"
                        />
                        <ErrorMessage
                          name="external_url"
                          component="div"
                          className="text-red-500 text-sm ml-2"
                        />
                      </div>
                    )}

                    {/* Submit Button */}
                    <div className="flex justify-center items-center mb-4">
                      <button
                        type="submit"
                        className="h-10 bg-[#0875E1] rounded-full cursor-pointer w-36 p-2 text-base tracking-wide text-white"
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? "Submitting..." : "Submit"}
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
      <Footer />
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </div>
  );
};

export default CreateEventForm;
