import apiClient from "../../../apiClient/apiClient";
import {
  GET_PROFILE_FAILURE,
  GET_PROFILE_REQUEST,
  GET_PROFILE_SUCCESS,
} from "../constant/ProfileConstant";

export const getProfile = () => async (dispatch) => {
  dispatch({ type: GET_PROFILE_REQUEST });
  try {
    const accessToken = localStorage.getItem("access");

    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    };
    const response = await apiClient.get("api/organizer-profile/", config);
    
    dispatch({
      type: GET_PROFILE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: GET_PROFILE_FAILURE,
      payload:
        error.response?.data?.error ||
        "An error occurred while fetching the profile",
    });
  }
};
