import React, { useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PlaceholderCard from './PlaceholderCard';

const CardSlider = ({ loading, data }) => {
  const sliderRef = useRef(null);

  useEffect(() => {
    const slider = sliderRef.current;

    if (!slider) return;

    const handleWheel = (e) => {
      const { scrollWidth, clientWidth, scrollLeft } = slider;
      const isOverflowing = scrollWidth > clientWidth;

      if (isOverflowing) {
        e.preventDefault();
        slider.scrollLeft += e.deltaY;
      }
    };

    // Add wheel event listener with passive: false
    slider.addEventListener('wheel', handleWheel, { passive: false });

    // Clean up the event listener on component unmount
    return () => {
      slider.removeEventListener('wheel', handleWheel);
    };
  }, []);

  // Helper function to format the price
  const formatPrice = (price) => {
    if (price === '0.00' || price === 0 || price === null) return 'Free';
    const num = parseFloat(price);
    if (Number.isInteger(num)) {
      return `£${num}`;
    }
    return `£${num.toFixed(2)}`;
  };

  return (
    <div className="w-full mb-5">
      <div
        ref={sliderRef}
        className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mx-5 py-6"
      >
        {loading
          ? Array(4)
            .fill(0)
            .map((_, index) => <PlaceholderCard key={index} />)
          : (
            <>
              {
                data.map((EventData, index) => {
                  return EventData.external_url ? (
                    <a
                      href={EventData.external_url}
                      key={index}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="event-card bg-white rounded-lg shadow-md overflow-hidden cursor-pointer hover:shadow-lg hover:scale-105 transition duration-200 flex flex-col"
                    >
                      <div
                        className="card-image relative w-full aspect-video bg-cover bg-center"
                        style={{
                          backgroundImage: `url(${EventData?.thumbnail_path})`,
                        }}
                      />
                      <div className="card-body flex flex-col flex-grow p-4">
                        <p className="font-poppins text-xl text-center font-bold mt-3">
                          {EventData.event_name}
                        </p>
                        <p className="font-poppins text-sm text-center text-gray-600 mt-2">
                          {EventData.start_date} at{' '}
                          {EventData.start_time
                            .split(':')
                            .slice(0, 2)
                            .join(':')}
                        </p>
                        <p className="font-poppins text-sm text-center text-gray-600 mt-1">
                          {EventData.venue_name || 'Venue Name'}
                        </p>
                        <p className="font-poppins text-base text-center font-bold mt-2">
                          {formatPrice(EventData.ticket_price)}
                        </p>
                      </div>
                    </a>
                  ) : (
                    <Link to={`/event/${EventData.event_id}`} key={index}>
                      <div
                        className="
                    card-container
                    bg-white
                    rounded-md
                    shadow-md
                    hover:shadow-lg
                    hover:scale-105
                    transition
                    duration-200
                    overflow-hidden
                    flex
                    flex-col
                  "
                      >
                        <div
                          className="card-image relative w-full aspect-video bg-cover bg-center"
                          style={{ backgroundImage: `url(${EventData?.thumbnail_path})` }}
                        />
                        <div className="card-body flex flex-col flex-grow p-4">
                          <p className="font-poppins text-xl text-center font-bold mt-3">
                            {EventData.event_name}
                          </p>
                          <p className="font-poppins text-sm text-center text-gray-600 mt-2">
                            {EventData.start_date} at{' '}
                            {EventData.start_time.split(':').slice(0, 2).join(':')}
                          </p>
                          <p className="font-poppins text-sm text-center text-gray-600 mt-1">
                            {EventData.venue_name || "Venue Name"}
                          </p>
                          <p className="font-poppins text-base text-center font-bold mt-2">
                            {formatPrice(EventData.ticket_price)}
                          </p>
                        </div>
                      </div>
                    </Link>
                  )
                })
              }
            </>
          )
        }
      </div>
    </div>
  );
};

export default CardSlider;
