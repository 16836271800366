import React, {useState, useEffect } from 'react'
import axios from 'axios';
const TopEvent = () => {
   const [topEvent,setTopEvent]=useState()
  return (
    <div>
         <p>Top Event</p>
    </div>
  )
}

export default TopEvent