

import axios from "axios";
import {
  REGISTER_FAILURE,
  REGISTER_SUCCESS,
} from "../constant/RegisterConstant";
import apiClient from "../../../apiClient/apiClient";
import { toast } from "react-toastify";

export const registerRequest = () => ({
  type: 'REGISTER_REQUEST',
});

export const registerSuccess = (user) => ({
  type: 'REGISTER_SUCCESS',
  payload: user,
});

export const registerFailure = (error) => ({
  type: 'REGISTER_FAILURE',
  payload: error,
});

export const registerUser = (values) => async (dispatch) => {
  dispatch(registerRequest());

  try {

    if (localStorage.getItem("access")) {
      localStorage.removeItem("access")
    }

    console.log(values);
    // Include the role in the values object
    const response = await apiClient.post('register/', values);
    const user = response.data;
    console.log(user);

    

    dispatch(registerSuccess(user));

    window.location.href = `/emailConfirmation?email=${encodeURIComponent(user.email)}`;
    
  } catch (error) {
    console.error("Registration error:", error);

    if (error.response && error.response.data) {
      const { data } = error.response;

      if (data.first_name) {
        toast.error(data.first_name.join(", "));
      }
      if (data.last_name) {
        toast.error(data.last_name.join(", "));
      }
      if (data.password) {
        toast.error(data.password.join(", "));
      }
      if (data.email) {
        if (Array.isArray(data.email)) {
          toast.error(data.email.join(", "));
        } else if (data.email === "email already exists") {
          toast.error("Email already exists");
        } else {
          toast.error("Invalid email address");
        }
      }
      if (data.phone_number) {
        if (Array.isArray(data.phone_number)) {
          toast.error(data.phone_number.join(", "));
        } else if (data.phone_number === "phone number already exists") {
          toast.error("Phone number already exists");
        } else {
          toast.error("Invalid phone number format");
        }
      }
      if (data.role) {
        toast.error(data.role.join(", "));
      }

      if (!data.first_name && !data.last_name && !data.password && !data.email && !data.phone_number && !data.role) {
        toast.error("An error occurred. Please try again later.");
      }
    } else {
      toast.error("An error occurred. Please try again later.");
    }

    dispatch(registerFailure("Error registering user"));
  }
};

