import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetVenueAction } from "../../../redux/venue/VenueAction/GetVenueAction";
import { Link } from "react-router-dom";

const Venue = () => {
  const dispatch = useDispatch();

  const venueState = useSelector((state) => state.getVenue);
  useEffect(() => {
    dispatch(GetVenueAction());
  }, [dispatch]);

  const { data, loading, error } = venueState;
  console.log("pp", data);
  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div>
   <p className='flex justify-center items-center align-center font-semibold text-base font-poppins'>Venue</p>
<div className="flex ">
      <div className="grid xl:grid-cols-4 md:grid-cols-3 grid-cols-1 ">
        {data &&
          data.map((venueDetail, index) => (
            <div className="p-5">
                <Link  to={`/venueDetail/${index}`}>
                <div
                key={index} 
                className="shadow-md  bg-white  rounded-md hover:shadow-2xl  p-5 grid grid-cols-1 gap-2"
              >
                <p className="font-poppins text-base tracking-wider ">
                 Venue Name:<span className='text-sm font-poppins text-tighter'>{venueDetail.venue_name}</span> 
                </p>
                <p className="font-poppins text-base tracking-wider ">
                  {" "}
                 Capacity: <span>{venueDetail.capacity}</span>
                </p>
                <p className="font-poppins text-base tracking-wider ">
                  {" "}
                 Flat House Number:<span>{venueDetail.flat_house_number}</span> 
                </p>
                <p className="font-poppins text-base tracking-wider ">
                  {" "}
                  Street address :<span>{venueDetail.street_address}</span>
                </p>
                <p className="font-poppins text-base tracking-wider ">
                 City Town : <span>{venueDetail.city_town}</span>
                </p>
                <p className="font-poppins text-base tracking-wider ">
                 Post code:  <span>{venueDetail.post_code}</span>
                </p>
                <p  className="font-poppins text-base tracking-wider ">Country:<span>{venueDetail.country}</span></p>
                {/* <p>{venueDetail.country}</p> */}
              </div>
                </Link>
          
            </div>
          ))}
      </div>
    </div>
    </div>
  
  );
};

export default Venue;
