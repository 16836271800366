import axios from "axios";
import { refreshAccessToken } from "../redux/auth/action/LoginAction";
import store from "../redux/store";

const apiClient = axios.create({
  //baseURL: "http://127.0.0.1:8000/",
   baseURL: "https://test.eventfullsunday.co.uk/",
});

// for  access token
apiClient.interceptors.request.use(
  (config) => {
    const access = localStorage.getItem("access");
    if (access) {
      config.headers.Authorization = `Bearer ${access}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Response interceptor to handle 401 errors and refresh token
apiClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    // Prevent infinite loop by checking if the request has been retried
    if (originalRequest._retry) {
      return Promise.reject(error);
    }

    const status = error.response ? error.response.status : null;

    // TESTING WITHOUT SECOND STATEMENT
    if (status === 401) {
      // if (status === 401 && !originalRequest.url.includes("/")) {

      originalRequest._retry = true;

      try {
        // Try to refresh the token
        const newAccessToken = await store.dispatch(refreshAccessToken());

        // Update the Authorization header with the new access token
        originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;

        // Retry the original request with the new token
        return apiClient(originalRequest);
      } catch (refreshError) {
        // If the token refresh failed, redirect to login or handle the error
        return Promise.reject(refreshError);
      }
    }

    return Promise.reject(error);
  }
);

export default apiClient;
