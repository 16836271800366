
import axios from "axios";
import {
  LOGIN_FAILURE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT,
} from "../constant/LoginConstant";
import apiClient from "../../../apiClient/apiClient";
import { toast } from "react-toastify";

// Create an axios instance without interceptors for token refresh
const refreshClient = axios.create({
  baseURL: "http://127.0.0.1:8000/api/",
});


//for logout success
export const loginoutSuccess = (user) => ({
  type: "LOGIN_SUCCESS",
  payload: user,
});

// Action creators
export const loginRequest = () => ({ type: LOGIN_REQUEST });
export const loginSuccess = (data) => ({
  type: LOGIN_SUCCESS,
  payload: data,
});

//condition for login failure
export const loginFailure = (error) => ({
  type: LOGIN_FAILURE,
  payload: error,
});

//condition for logout
export const logout = () => {
  localStorage.removeItem("access");
  localStorage.removeItem("refresh");

  return { type: LOGOUT };
};

export const loginUser = (values) => async (dispatch) => {
  dispatch(loginRequest());

  try {

    // Remove any stored token to avoid issues with login
    if (localStorage.getItem("access")) {
      localStorage.removeItem("access")
    }
    if (localStorage.getItem("refresh")) {
      localStorage.removeItem("refresh")
    }

    const response = await apiClient.post("login/", values);
    const { access, refresh } = response.data;

    console.log("[LOGIN DEBUG] Login response: ", response.detail);

    if (access && refresh) {
      localStorage.setItem("access", access);
      localStorage.setItem("refresh", refresh);

      const config = {
        headers: {
          Authorization: `Bearer ${access}`,
          "Content-Type": "application/json",
        },
      };

      const { data } = await apiClient.get("/user/", config);
      if (data.role === "organizer") {
        dispatch(loginSuccess({ access, refresh }));
        window.location.href = "/";
      } else {
        toast.error('Only Organizers are allowed to login to this view');
        dispatch(logout());
      }

    } else {
      console.log("[LOGIN DEBUG] Else Response: ", response);

      throw new Error("Invalid response from the server");
    }
  } catch (error) {
    if (error.response && error.response.data) {
      const { data } = error.response;

      console.log("[TESTING] Error: ", error);


      if (data.email) {
        toast.error("No active account with this email");
      } else if (data.password) {
        toast.error("Invalid password");
      } else {
        toast.error(data.detail || "An error occurred. Please try again later.");
      }
    } else {
      toast.error("An error occurred. Please try again later.");
    }

    dispatch(loginFailure("Invalid email or password"));
  }
};

// Refresh Access Token
let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

export const refreshAccessToken = () => async (dispatch) => {
  try {
    if (isRefreshing) {
      // Return a promise that resolves once the token is refreshed
      return new Promise((resolve, reject) => {
        failedQueue.push({ resolve, reject });
      });
    }

    isRefreshing = true;

    const refreshToken = localStorage.getItem("refresh");

    if (!refreshToken) throw new Error("No refresh token available");

    const response = await refreshClient.post("/token/refresh/", { refresh: refreshToken });
    const { access: newAccessToken, refresh: newRefreshToken } = response.data;

    // Store the new tokens in localStorage
    localStorage.setItem("access", newAccessToken);
    localStorage.setItem("refresh", newRefreshToken);

    // Dispatch the new tokens to Redux store
    dispatch(
      loginSuccess({
        access: newAccessToken,
        refresh: newRefreshToken,
      })
    );

    processQueue(null, newAccessToken); // Resolve all pending requests with new access token
    console.log("[TESTING] Token refreshed successfully");
    isRefreshing = false;
    return newAccessToken;
  } catch (error) {
    processQueue(error, null);
    isRefreshing = false;

    // Handle failure (log out, clear tokens, etc.)
    localStorage.removeItem("access");
    localStorage.removeItem("refresh");
    dispatch(logout());

    console.log("[TESTING] Refresh token failed, user logged out:", error);
    return Promise.reject(error);
  }
};


//logout section
export const logoutUser = () => async (dispatch) => {
  try {
    const refresh = localStorage.getItem("refresh");
    if (refresh) {
      const response = await apiClient.post("logout/", { refresh });
      console.log(response);
      if (response.status === 205) {
        localStorage.removeItem("access");
        localStorage.removeItem("refresh");
        dispatch(logout());
        window.location.href = "/";
      } else {
        console.error("Unexpected response status:", response.status);
      }
    } else {
      console.log("No refresh token found.");
    }
  } catch (error) {
    console.error("Logout error:", error);
    // Handle error (e.g., show an alert to the user)
  }
};
