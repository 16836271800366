import { toast } from "react-toastify";
import apiClient from "../../../apiClient/apiClient";
import { EDIT_EVENT_CONSTANT_FAILURE, EDIT_EVENT_CONSTANT_REQUEST, EDIT_EVENT_CONSTANT_SUCCESS } from "./EditEventConstant"



export const editEventCreate = (id, data) => async (dispatch) => {
  dispatch({ type: EDIT_EVENT_CONSTANT_REQUEST });
  try {

    // Display the key/value pairs
    for (var pair of data.entries()) {
      console.log(pair[0] + ': ' + pair[1]);
    }


    const token = localStorage.getItem('access');
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': "multipart/form-data",
      },
    };
    const response = await apiClient.put(`api/events/${id}/`, data, config);
    dispatch({
      type: EDIT_EVENT_CONSTANT_SUCCESS,
      payload: response.data,
    });

    toast.success('Event Updated!');
    setTimeout(() => {
      window.location.href = '/';
    }, 2000);
    // window.location.href = '/';
  } catch (error) {
    dispatch({
      type: EDIT_EVENT_CONSTANT_FAILURE,
      payload: error.message,
    });
    toast.error('An error occurred while editing the event. Please try again.');

  }
};