import apiClient from "../../../apiClient/apiClient";
import { GET_VENUE_CONSTANT_FAILURE, GET_VENUE_CONSTANT_REQUEST, GET_VENUE_CONSTANT_SUCCESS } from "../VenueConstant/GetVenueConstant"



export const GetVenueAction=()=>async(dispatch)=>{
    dispatch({
        type:GET_VENUE_CONSTANT_REQUEST
    })
    try{
        const token=localStorage.getItem('access')
        console.log('register venue',token)
        const config={
            headers:{
                Authorization:`Bearer ${token}`
            }
        }
    const response=await apiClient.get('/register-venue/',config)
    console.log(response,'data')
    dispatch({
        type:GET_VENUE_CONSTANT_SUCCESS,
        payload:response.data
     })
    }
    catch(error){
        dispatch({
            type:GET_VENUE_CONSTANT_FAILURE,
            payload:error.message
        })
    }
}