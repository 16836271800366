import React, { useEffect, useState } from "react";
import Navbar from "../layout/Navbar/Navbar";
import Footer from "../layout/Footer/Footer";
import axios from "axios";

const Live = () => {
  const [data, setData] = useState();

  useEffect(() => {
    axios
      .get("http://localhost:4000/music")
      .then((response) => {
        setData(response.data);
        console.log("hhh", response.data);
      })
      .catch((error) => {
        console.log("Error is getting ");
      });
  }, []);
  return (
    <div>
      <Navbar />
      <div className="w-full flex justify-center">
        <div className="w-[95vw] ">
          <p className="font-poppins text-base text-wider tracking-wider font-semibold">
            Live Events
          </p>
          <div className="">
            <div className="grid xl:grid-cols-5 gap-4 mb-10 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4">
              {Array.isArray(data) &&
                data.map((value, index) => (
                  <div
                    key={index}
                    className="shadow-md p-2 rounded-md font-poppins hover:shadow-xl cursor-pointer"
                  >
                    <div className="max-h-[250px] w-full">
                      <img
                        src={value.image}
                        alt={value.name}
                        className="h-[200px] w-full"
                      />
                    </div>
                    <div className="text-center">
                      <p className="font-semibold leading-7">{value.name}</p>
                      <p className="font-normal leading-7">{value.time}</p>
                      <p className="font-normal leading-7">{value.location}</p>
                      <p className="font-semibold leading-7 outline outline-[#0875e1] text-[#0875e1] p-2 rounded-full">{value.paid}</p>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Live;
