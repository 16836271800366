import React from "react";
import organizer from "../../../assets/site-images/organizer.png"

const LeftSection = () => {
    return (
        <div className="p-6 lg:p-0 flex justify-center items-center w-full lg:w-1/2 font-poppins bg-[#f5f5f5]">
            <div className="gap-6 flex-col flex items-center text-center">
                <p className="text-2xl font-semibold md:text-3xl">
                    Welcome to the{" "}
                    <span className="block text-blue-600">Organizer End!</span>
                </p>
                <img src={organizer} alt="Organizer" className="w-full max-w-md" />
            </div>
        </div>
    )
}

export default LeftSection