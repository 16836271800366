// src/components/VenueDetsil.js
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { fetchVenueDetail } from "../../../redux/venue/VenueAction/VenueDetailAction";
import { IoSettingsOutline } from "react-icons/io5";
import { Field, Form, Formik } from "formik";
import {
  updateEditVenue,
  updateVenue,
} from "../../../redux/venue/VenueAction/VenueUpdateAction";

const VenueDetail = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const { id } = useParams();
  const dispatch = useDispatch();
  const { venueDetail, loading, error } = useSelector(
    (state) => state.venueDetail
  );

  useEffect(() => {
    dispatch(fetchVenueDetail(id)); // Fetch data based on the ID
  }, [dispatch, id]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  if (!venueDetail) return <div>No venue details available</div>;

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const initialValues = {
    venue_name: "",
    city_town: "",
    country: "",
    flat_house_number: "",
    post_code: "",
    street_address: "",
    venue_capacity: "",
    venue_name: "",
  };

  const handleSubmit = async (values) => {
    // Use the venue ID from the route parameters
    dispatch(updateEditVenue(id, values));
  };

  return (
    <div className="font-poppins">
      <h1>
        Venue Name:<span>{venueDetail.venue_name || "No Venue Name"}</span>
      </h1>
      <p>
        City Town:<span>{venueDetail.city_town || "No City_town"}</span>
      </p>
      <p>
        Country:<span>{venueDetail.country || "No Country"}</span>
      </p>
      <p>
        Flat house number:
        <span>{venueDetail.flat_house_number || "No flat house number"}</span>
      </p>
      <p>
        Post code :<span>{venueDetail.post_code || "No post_code"}</span>
      </p>
      <p>
        Address:{" "}
        <span>{venueDetail.street_address || "No street_address"}</span>
      </p>
      <p>
        Venue Capacity:
        <span>{venueDetail.venue_capacity || "No venue capacity"}</span>
      </p>
      <p>
        Venue Name:<span>{venueDetail.venue_name || "No venue capacity"}</span>
      </p>
      {/* Render other details */}

      <p onClick={openModal}>Edit event</p>

      {modalIsOpen && (
        <div className="fixed inset-0 flex items-start justify-end bg-black bg-opacity-50">
          <div className="bg-[#0875E1] p-10 rounded-lg shadow-lg h-full w-[400px] text-white font-poppins text-base text-wider tracking-wider ">
            <div className="flex justify-end items-end mb-10">
              <IoSettingsOutline
                size={25}
                color="white"
                className="flex justify-end items-end text-white"
                onClick={closeModal}
              />
            </div>
            <Formik initialValues={venueDetail} onSubmit={handleSubmit}>
              {({ values, setFieldValue, isSubmitting, isValid, dirty }) => (
                <Form className=" p-5 overflow-auto scrollbar-hidden w-full h-full">
                  {/* Extra Image 2 */}
                  <div>
                    <div className="mb-4">
                      <p className="font-normal font-poppins text-base tracking-wider">
                        Venue Name*
                      </p>
                      <Field
                        type="text"
                        name="venue_name"
                        placeholder="Event title goes here"
                        className="w-full text-sm p-3 rounded-full pl-4 font-poppins tracking-wide border border-[#0875E1] mt-2 text-black"
                      />
                    </div>

                    <div className="mb-4">
                      <p className="font-normal font-poppins text-base tracking-wider">
                        City Town *
                      </p>
                      <Field
                        type="text"
                        name="city_town"
                        placeholder="Organizer name please"
                        className="w-full text-black text-sm p-3 rounded-full pl-4 font-poppins tracking-wide border border-[#0875E1] mt-2"
                      />
                    </div>

                    <div className="mb-4">
                      <p className="font-normal font-poppins text-base tracking-wider">
                        Country *
                      </p>
                      <Field
                        type="text"
                        name="country"
                        placeholder="Organizer name please"
                        className="w-full text-black text-sm p-3 rounded-full pl-4 font-poppins tracking-wide border border-[#0875E1] mt-2 "
                      />
                    </div>

                    <div className="mb-4">
                      <p className="font-normal font-poppins text-base tracking-wider">
                        Flat House Number *
                      </p>
                      <Field
                        type="number"
                        name="flat_house_number"
                        placeholder="0"
                        className="w-[96px] text-black text-center text-sm p-3 rounded-full pl-4 font-poppins tracking-wide border border-[#0875E1] mt-2"
                      />
                    </div>

                    <div className="mb-4">
                      <p className="font-normal font-poppins text-base tracking-wider">
                        Post code *
                      </p>
                      <Field
                        type="number"
                        name="post_code"
                        placeholder="0"
                        className="w-[96px] text-black text-center text-sm p-3 rounded-full pl-4 font-poppins tracking-wide border border-[#0875E1] mt-2"
                      />
                    </div>

                    <div className="mb-4">
                      <p className="font-normal font-poppins text-base tracking-wider">
                        Address *
                      </p>
                      <Field
                        type="number"
                        name="street_address"
                        placeholder="0"
                        className="w-[96px] text-black text-center text-sm p-3 rounded-full pl-4 font-poppins tracking-wide border border-[#0875E1] mt-2"
                      />
                    </div>

                    <div className="mb-4">
                      <p className="font-normal font-poppins text-base tracking-wider">
                        Venue capacity *
                      </p>
                      <Field
                        type="number"
                        name="venue_capacity"
                        placeholder="0"
                        className="w-[96px] text-black text-center text-sm p-3 rounded-full pl-4 font-poppins tracking-wide border border-[#0875E1] mt-2"
                      />
                    </div>

                    <div className="mb-4">
                      <p className="font-normal font-poppins text-base tracking-wider">
                        Venue *
                      </p>
                      <Field
                        type="text"
                        name="venue_name"
                        placeholder="0"
                        className="w-[96px] text-black text-center text-sm p-3 rounded-full pl-4 font-poppins tracking-wide border border-[#0875E1] mt-2"
                      />
                    </div>

                    <div className="flex justify-center items-center mb-4">
                      <button
                        type="submit"
                        className="h-10 bg-[#0875E1] rounded-full cursor-pointer w-36 p-2 font-poppins text-base tracking-wide text-white outline outline-white"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      )}
    </div>
  );
};

export default VenueDetail;
