import React, { useEffect, useState } from 'react'
import Navbar from '../../components/layout/Navbar/Navbar'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { EventGetItems } from '../../redux/createEvent/createEventAction/EventDetailAction';
import { createTicket } from '../../redux/CreateTicket/createTicketAction/createTicketAction';
import { Field, Form, Formik } from 'formik';
import { toast, ToastContainer } from 'react-toastify';

function CreateTicketForm() {

  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //const [showDropdown, setShowDropdown] = useState(false);
  //const [totalQuantity, setTotalQuantity] = useState();
  const eventItem = useSelector((state) => state.eventItems);

  const { data, loading, error } = eventItem;
  console.log(data);
  //const [eventName, setEventName] = useState(data?.event_name);
  const [ticketPrice, setTicketPrice] = useState(data?.ticket_price);
  const [totalQuantity, setTotalQuantity] = useState(data?.capacity);
  const [availableQuantity, setAvailableQuantity] = useState(data?.capacity);
  const [ticketSold, setTicketSold] = useState('0');
  const [ticketCategory, setTicketCategory] = useState("");
  //const [ticketDescription, setTicketDescription] = useState("");*/

  const handleSubmit = (values) => {
    const formData = new FormData();
    formData.append("event", id);
    formData.append("event_name", values.event_name);
    formData.append("price", values.ticket_price);
    formData.append("total_quantity", values.total_quantity);
    formData.append("available_quantity", values.available_quantity);
    formData.append("ticket_sold", values.ticket_sold);
    formData.append("ticket_category", values.ticket_category);
    formData.append("ticket_description", values.ticket_description);

    dispatch(createTicket(formData)).then(() => navigate('/dashboard'));
  }

  useEffect(() => {
    if(id) {
      dispatch(EventGetItems(id));
    }
  }, [dispatch, id])

  const handleSelectedTicket = (value) => {
    setTicketCategory(value);
  }

  /*const handleChange = (e) => {
    const sold = parseInt(e.target.value) || 0;
    setTicketSold(sold);
    // Update available quantity
    setAvailableQuantity(data?.capacity - sold);
  }*/

  if(loading) return <p>Loading...</p>
  if(error) return <p>{error}</p>

  return (
    <div className="w-full h-full font-poppins text-[#565656] bg-[#f2f4f7]">
      <Navbar linkTo="/home" title="create ticket" />
      <div className="max-w-lg mx-auto p-6 space-y-4 bg-white shadow-md rounded-lg mt-20">
        <Formik 
        enableReinitialize 
        initialValues={{
          event_name: data?.event_name,
          ticket_price: ticketPrice || "",
          total_quantity: totalQuantity || "",
          available_quantity: availableQuantity || "",
          ticket_sold: ticketSold || "",
          ticket_category: ticketCategory,
          ticket_description: "",
        }}
        onSubmit={handleSubmit}
        >
          {({
            values,
            setFieldValue,
            handleChange,
            handleBlur,
            handleSubmit
          }) => (
            <Form className='flex flex-col'>
              <div className="mb-2">
                <p className="font-normal font-poppins text-base tracking-wider">
                  Event Name
                </p>
                <Field
                  id="event_name"
                  name="event_name"
                  type="text"
                  placeholder="Event title goes here"
                  className="w-full text-sm p-3 rounded-full pl-4 font-poppins tracking-wide border border-[#0875E1]"
                  disabled
                  />
              </div>

              <div className="mb-2">
                <p className="font-normal font-poppins text-base tracking-wider">
                  Ticket Price
                </p>
                <Field
                  id="ticket_price"
                  name="ticket_price"
                  type="text"
                  placeholder="Ticket Price"
                  className="w-full text-sm p-3 rounded-full pl-4 font-poppins tracking-wide border border-[#0875E1]"
                  />
              </div>

              <div className="mb-2">
                <p className="font-normal font-poppins text-base tracking-wider">
                  Total Quantity
                </p>
                <Field
                  id="total_quantity"
                  name="total_quantity"
                  type="text"
                  placeholder="Total Tickets"
                  className="w-full text-sm p-3 rounded-full pl-4 font-poppins tracking-wide border border-[#0875E1]"
                  />
              </div>

              <div className="mb-2">
                <p className="font-normal font-poppins text-base tracking-wider">
                  Available Quantity
                </p>
                <Field
                  id="available_quantity"
                  name="available_quantity"
                  type="text"
                  placeholder="Available Tickets"
                  className="w-full text-sm p-3 rounded-full pl-4 font-poppins tracking-wide border border-[#0875E1]"
                  />
              </div>

              <div className="mb-2">
                <p className="font-normal font-poppins text-base tracking-wider">
                  Ticket Sold
                </p>
                <Field
                  id="ticket_sold"
                  name="ticket_sold"
                  type="text"
                  placeholder="Tickets Sold"
                  className="w-full text-sm p-3 rounded-full pl-4 font-poppins tracking-wide border border-[#0875E1]"
                  />
              </div>

              <div className="mb-2">
                <label className="font-normal font-poppins text-base tracking-wider">Ticket Category</label>
                <select
                  name="ticket_category"
                  onChange={(e) => handleSelectedTicket(e.target.value)}
                  className="w-full text-sm p-3 rounded-full pl-4 font-poppins tracking-wide border border-[#0875E1] mt-2"
                >
                  <option value="">---------</option>
                  <option value="free">free</option>
                  <option value="basic">basic</option>
                  <option value="standard">standard</option>
                  <option value="premium">premium</option>
                </select>
              </div>

              <div className="mb-2">
                <p className="font-normal font-poppins text-base tracking-wider">
                  Ticket Description
                </p>
                <Field
                  id="ticket_description"
                  name="ticket_description"
                  as="textarea"
                  placeholder="Ticket Description"
                  className="w-full text-sm p-3 rounded-full pl-4 font-poppins tracking-wide border border-[#0875E1]"
                  />
              </div>

              <div className="flex justify-center items-center mb-4">
                      <button
                        type="submit"
                        className="h-10 bg-[#0875E1] rounded-full cursor-pointer w-36 p-2 font-poppins text-base tracking-wide text-white"
                      >
                        Create Ticket
                      </button>
                    </div>
            </Form>
          )}
        </Formik>
        <ToastContainer position='top-right' autoClose={5000} />
      </div>
      {/*<form className="max-w-lg mx-auto p-6 space-y-4 bg-white shadow-md rounded-lg mt-20" onSubmit={handleSubmit}>
        <div>
          <label className="font-normal font-poppins text-base tracking-wider">Event Name</label>
          <input
            type="text"
            value={eventName}
            className="w-full text-sm p-3 rounded-full pl-4 font-poppins tracking-wide border border-[#0875E1] mt-2"
            placeholder="Enter text"
            disabled
          />
        </div>
        <div>
          <label className="font-normal font-poppins text-base tracking-wider">Ticket Price</label>
          <input
            type="number"
            value={ticketPrice}
            className="w-full text-sm p-3 rounded-full pl-4 font-poppins tracking-wide border border-[#0875E1] mt-2"
            placeholder="Enter text"
            disabled
          />
        </div>
        <div>
          <label className="font-normal font-poppins text-base tracking-wider">Total Quantity</label>
          <input
            type="number"
            value={totalQuantity}
            className="w-full text-sm p-3 rounded-full pl-4 font-poppins tracking-wide border border-[#0875E1] mt-2"
            placeholder="Enter text"
            disabled
          />
        </div>
        <div>
          <label className="font-normal font-poppins text-base tracking-wider">Available Quantity</label>
          <input
            type="number"
            value={availableQuantity}
            className="w-full text-sm p-3 rounded-full pl-4 font-poppins tracking-wide border border-[#0875E1] mt-2"
            placeholder="Enter text"
          />
        </div>
        <div>
          <label className="font-normal font-poppins text-base tracking-wider">Tickets Sold</label>
          <input
            type="number"
            value={ticketSold}
            className="w-full text-sm p-3 rounded-full pl-4 font-poppins tracking-wide border border-[#0875E1] mt-2"
            placeholder="Enter text"
            onChange={handleChange}
          />
        </div>
        <div>
        <label className="font-normal font-poppins text-base tracking-wider">Ticket Category</label>
        <select
          name="select"
          value={ticketCategory}
          onChange={(e) => setTicketCategory(e.target.value)}
          className="w-full text-sm p-3 rounded-full pl-4 font-poppins tracking-wide border border-[#0875E1] mt-2"
        >
          <option value="">---------</option>
          <option value="free">free</option>
          <option value="basic">basic</option>
          <option value="standard">standard</option>
          <option value="premium">premium</option>
        </select>
      </div>
      <div>
        <label className="font-normal font-poppins text-base tracking-wider">Ticket Description</label>
        <textarea
          name="textarea"
          value={ticketDescription}
          onChange={(e) => setTicketDescription(e.target.value)}
          className="w-full text-sm p-3 rounded-full pl-4 font-poppins tracking-wide border border-[#0875E1] mt-2"
          placeholder="Write something..."
        />
      </div>
      <div className="flex justify-center items-center mb-4">
        <button
          type="submit"
          className="h-10 bg-[#0875E1] rounded-full cursor-pointer w-36 p-2 font-poppins text-base tracking-wide text-white"
        >
          Submit
        </button>
      </div>
      </form>*/}
    </div>
  )
}

export default CreateTicketForm
