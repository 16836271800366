import { GET_TICKET_FAILURE, GET_TICKET_REQUEST, GET_TICKET_SUCCESS } from "../createTicketAction/createTicketConstant"

const initialState = {
    loading: false,
    data: null,
    error: null,
}

const getEventTicketReducer = (state=initialState, action) => {
    switch(action.type) {
        case GET_TICKET_REQUEST: 
            return {
                ...state,
                loading: true,
                error: null
            };
        case GET_TICKET_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
            };
        case GET_TICKET_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        default:
            return state;
    }
}

export default getEventTicketReducer;