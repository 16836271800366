import React, { useState } from "react";
import organizer from "../../assets/site-images/organizer.png";
import { Field, Form, Formik } from "formik";
import {
  IoPersonCircle,
  IoLockOpenOutline,
  IoEyeOutline,
  IoEyeOffOutline,
  IoLockClosedOutline,
} from "react-icons/io5";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { loginUser } from "../../redux/auth/action/LoginAction";
import { toast } from "react-toastify";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//import { GoogleLogin } from "@react-oauth/google";
import { useGoogleLogin } from '@react-oauth/google';
import { googleLogin } from "../../redux/auth/action/GoogleLogin";
import { FcGoogle } from "react-icons/fc";
import LeftSection from "../../components/layout/LeftSection/LeftSection"


const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();

  const login = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      const { access_token } = tokenResponse;
      console.log('Access Token:', access_token);
      dispatch(googleLogin(tokenResponse));
    },
    onError: error => toast.error(error),
    scope: 'openid email profile',
  });


  /*const login = useGoogleLogin({
    flow: 'auth-code',
    onSuccess: async (codeResponse) => {
      console.log(codeResponse);
      dispatch(googleLogin(codeResponse));
      /*const tokens = await axios.post(
          'http://localhost:3001/auth/google', {
              code: codeResponse.code,
          });

      console.log(tokens);-
    },
    onError: errorResponse => console.log(errorResponse),
  });*/


  // showing the password
  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  // set up initial value
  const initialValues = {
    email: "",
    password: "",
  };

  // For
  const validationSchema = Yup.object({
    email: Yup.string().email("invalid email format").required(""),
    password: Yup.string().required("password is required"),
  });

  const handleLogin = (values, { setSubmitting }) => {
    dispatch(loginUser(values)).catch(() => toast.error("Failed to log in")); // Handle login errors
    setSubmitting(false);
  };

  return (
    <div className="flex flex-col lg:flex-row h-screen font-poppins">
      {/* Left Section */}
      <LeftSection />

      {/* Right Section: Login Form */}
      <div className="flex flex-col justify-center items-center w-full lg:w-1/2 bg-white p-6 lg:m-0 lg:p-0">
        <Formik initialValues={initialValues} onSubmit={handleLogin}>
          {({ handleSubmit, handleBlur, handleChange, values }) => (
            <Form
              onSubmit={handleSubmit}
              className="w-full md:w-[60vw] lg:w-[35vw] xl:w-[30vw]"
            >
              <p className="text-xl md:text-2xl font-semibold text-wider tracking-wider font-poppins text-center mb-4 2xl:text-3xl">
                Login
              </p>
              <div className="mb-4 gap-2 grid grid-cols-1 ">
                <p className="font-[400px] text-base text-wider tracking-wider font-poppins 2xl:text-lg">
                  EMAIL/PHONE NUMBER
                </p>
                <div className="relative flex items-center mb-4">
                  <IoPersonCircle className="absolute left-4 text-gray-500 w-6 h-6" />
                  <Field
                    id="email"
                    name="email"
                    type="text"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.email}
                    placeholder="nepalieventorg@gmail.com"
                    className="w-full text-base p-3 pl-12 font-poppins rounded-full tracking-wide border-2 border-[#0875E1] h-12"
                  />
                </div>
              </div>
              <div className="mb-4 grid grid-cols-1 gap-2">
                <p className="font-[400px] text-base 2xl:text-lg text-wider tracking-wider font-poppins">
                  PASSWORD
                </p>
                <div className="relative flex items-center ">
                  <IoLockClosedOutline className="absolute left-4 text-gray-500 w-6 h-6" />
                  <Field
                    type={showPassword ? "text" : "password"}
                    id="password"
                    name="password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.password}
                    placeholder="iampassword"
                    className="w-full text-base p-3 pl-12 font-poppins rounded-full tracking-wide border-2 border-[#0875E1] h-12"
                  />
                  <button
                    type="button"
                    onClick={togglePasswordVisibility}
                    className="text-gray-500 cursor-pointer absolute right-4"
                  >
                    {!showPassword ? (
                      <IoEyeOffOutline className="w-7 h-7" />
                    ) : (
                      <IoEyeOutline className="w-7 h-7" />
                    )}
                  </button>
                </div>
              </div>

              {/* Remember me & Forgot password */}
              <div className="w-full grid grid-cols-2  mb-4">
                <div className="w-full flex items-center gap-2">
                  <Field
                    type="checkbox"
                    id="remember"
                    name="remember"
                    value="remember"
                  />
                  <label
                    htmlFor="remember"
                    className="font-poppins text-xs md:text-base"
                  >
                    Remember Me
                  </label>
                </div>
                {/* Forgot password */}
                <div className="flex justify-end">
                  <p className="font-poppins underline cursor-pointe text-xs md:text-base text-blue-500">
                    <Link to="/ForgotPassword">Forgot Password?</Link>
                  </p>
                </div>
              </div>
              {/* Login button */}
              <div className="flex justify-center items-center mt-5 mb-4">
                <button
                  type="submit"
                  className="h-10 bg-[#0875E1] rounded-full cursor-pointer w-36 p-2 font-poppins text-base tracking-wide text-white transition duration-200 hover:brightness-90"
                >
                  Login
                </button>
              </div>

              <div className="flex justify-center items-center text-sm">
                <Link to="/register" className="text-blue-500">
                  <p className="underline text-blue-500 underline-[#0875E1] cursor-pointer text-base 2xl:font-poppins">
                    Don’t have an account?{" "}
                    Register Here
                  </p>
                </Link>
              </div>
            </Form>
          )}
        </Formik>
        {/*<p className="mb-2 mt-2">or</p>
        <button onClick={() => login()} className="flex justify-center h-10
         rounded-full cursor-pointer w-[45%] p-2
          font-poppins text-base tracking-wide bg-white text-blue-400 hover:brightness-90 transition duration-200"
          style={{ boxShadow: "0 0 6px rgba(0,0,0,0.25)" }}
        ><FcGoogle size={25} className="mx-2" />Sign In with Google</button>*/}
        <ToastContainer position="top-right" autoClose={5000} />
      </div>
    </div>
  );
};

export default Login;
