import { toast } from "react-toastify";
import apiClient from "../../../apiClient/apiClient";
import { GOOGLE_LOGIN_FAILURE, GOOGLE_LOGIN_REQUEST, GOOGLE_LOGIN_SUCCESS } from "../constant/googleLoginConstants"

export const googleLogin = (token) => async (dispatch) => {
    dispatch({
        type: GOOGLE_LOGIN_REQUEST
    })
    try {
        /*const access_token = {
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ access_token: token })
        }*/
        const body = {
            access_token: token.access_token,
            role: "organizer"
        } 
        const response = await apiClient.post("dj-rest-auth/google/", body);
        console.log(response);
        const { access_token, refresh_token } = response.data;
        
        if (access_token && refresh_token) {
            localStorage.setItem("access", access_token);
            localStorage.setItem("refresh", refresh_token);

            dispatch({
                type: GOOGLE_LOGIN_SUCCESS,
                payload: { access: access_token, refresh: refresh_token }
            })
            toast.success("Login successful");

            setTimeout(() => {
                window.location.href = "/home";
            }, 2000);
        } else {
            throw new Error("Invalid response from the server");
        }
    } catch (error) {
        if (error.response && error.response.data) {
            const { data } = error.response;
            console.log(error);
      
            if (data.email) {
              toast.error("No active account with this email");
            } else if (data.password) {
              toast.error("Invalid password");
            } else {
              toast.error(data.non_field_errors[0] || "An error occurred. Please try again later.");
            }
        } else {
            toast.error("An error occurred. Please try again later.");
        }
      
        dispatch({
            type: GOOGLE_LOGIN_FAILURE,
            payload: "Invalid mail or password."
        });
    }
}
