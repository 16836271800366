import React, { useState, useEffect } from "react";

import axios from "axios";

const RecentEvent = () => {
  const [recentEvent, setRecentEvent] = useState();


  return (
    <div>
      <p>Recent Event</p>
    </div>
  );
};

export default RecentEvent;
