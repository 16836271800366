import { ACTIVATION_LINK_FAILURE, ACTIVATION_LINK_REQUEST, ACTIVATION_LINK_SUCCESS } from "../constant/ResendLinkConstant"



const initialState={
    link:null,
    loading:false,
    error:null
}

const activationLinkReducer=(state=initialState,action)=>{
  switch(action.type){
    case ACTIVATION_LINK_REQUEST:
        return{
            ...state,
            loading:true,
            error:null
        }
    case ACTIVATION_LINK_SUCCESS:
        return{
            ...state,
            loading:false,
            profile:action.payload
        }
    case ACTIVATION_LINK_FAILURE:
        return {
            ...state,
            loading:false,
            error:action.payload
        }
    default:
        return state
  }
}
export default activationLinkReducer