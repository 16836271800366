// src/App.js

import React from "react";
import { Route, BrowserRouter, Routes } from "react-router-dom";
import Login from "./auth/Login/Login";
import Register from "./auth/Register/Register";
import ForgotPassword from "./auth/Password/ForgotPassword";
import ResetPassword from "./auth/Password/ResetPassword";
import ForgotPasswordConfirm from "./auth/Password/ForgotPasswordConfirm"
import EmailConfirmation from "./auth/Register/EmailConfirmation";
import SuccessfulVerification from "./auth/Login/SuccessfulVerification";
import Home from "./components/pages/Home";
import ComingEvent from "./components/pages/Event/comingEvent/ComingEvent";
import TopUpComing from "./components/pages/Event/TopUpcomingEvent/TopUpComing";
import ExpireEvent from "./components/pages/Event/ExpireEvent/ExpireEvent";
import HelpCenter from "./components/pages/SettingSection/HelpCenter/HelpCenter";
import PrivacyPolicy from "./components/pages/SettingSection/PrivacyPolicy/PrivacyPolicy";
import ShareTheApp from "./components/pages/SettingSection/ShareTheApp/ShareTheApp";
import Logout from "./auth/Logout/Logout";
import Update from "./components/pages/SettingSection/Update/Update";
import TermOfuse from "./components/pages/SettingSection/TermOfuse/TermOfuse";
import EventDetail from "./components/pages/Event/EventDetail/EventDetail";
import UpComingEvent from "./components/pages/Event/UpComingEvent/UpComingEvent";
import TopUpComingEvent from "./components/pages/Event/TopUpcomingEvent/TopUpComingEvent";
import Expire from "./components/pages/Expire/Expire";
import UpComing from "./components/pages/UpComing/UpComing";
import TopEvent from "./components/pages/TopEvent/TopEvent";
import RecentEvent from "./components/pages/RecentEvent/RecentEvent";
import NotFound from "./components/pages/pageNotFound/PageNotFound";
import PrivateRoute from "./ProtectedRoute/ProtectedRoutes";
import RegisterVenueForm from "./Form/VenueForm/RegisterVenueForm";
import Venue from "./components/pages/Venue/Venue";
import VenuDetail from "./components/pages/Venue/VenuDetail";
import CreateEventForm from "./Form/CreateEventForm/CreateEventForm";
import EditCreateEventForm from "./Form/EditCreateEventForm/EditCreateEventForm";
import Dashboard from "./components/pages/Dashboard/Dashboard";
import CreateTicketForm from "./Form/CreateTicketForm/CreateTicketForm";
import ViewTicket from "./components/pages/Organizer/ViewTicket";
import OrganizerRoute from "./ProtectedRoute/OrganizerRoute";
import NotAuthorized from "./components/pages/NotAuthorized";
import Music from "./components/pages/Music";
import Arts from "./components/pages/Arts";
import Tech from "./components/pages/Tech";
import Business from "./components/pages/Business";
import Live from "./components/pages/Live";
import Shows from "./components/pages/Shows";
import Sports from "./components/pages/Sports";


const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        {/* Public Routes */}
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/register" element={<Register />} />
        <Route exact path="/ForgotPassword" element={<ForgotPassword />} />
        <Route exact path="/password-reset-confirm/:uid/:token" element={<ResetPassword />} />
        <Route exact path="/passwordResetConfirmation" element={<ForgotPasswordConfirm />} />
        <Route exact path="/emailConfirmation" element={<EmailConfirmation />} />
        <Route exact path="/activate/:uid/:token" element={<SuccessfulVerification />} />
        <Route path="/registerVenueForm" element={<RegisterVenueForm />} />
        <Route path="/Venue" element={<Venue />} />
        <Route path="/VenueDetail/:id" element={<VenuDetail />} />
        <Route path="" element={<Home />} />
        <Route path="/not-authorized" element={<NotAuthorized />} />
        <Route exact path="/music" element={<Music />} />
        <Route exact path="/arts" element={<Arts />} />
        <Route exact path="/tech" element={<Tech />} />
        <Route exact path="/business" element={<Business />} />
        <Route exact path="/live" element={<Live />} />
        <Route exact path="/shows" element={<Shows />} />
        <Route exact path="/sports" element={<Sports />} />
        <Route />
        {/* Private Routes */}
        <Route
          path="/createEventForm"
          element={
            <PrivateRoute>
              <CreateEventForm />
            </PrivateRoute>
          }
        />
        
        <Route path="/event/:id" element={<EventDetail />} />

        <Route
          exact
          path="/upcomingEvent"
          element={
            <PrivateRoute>
              <ComingEvent />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/UpComingEvent/:id"
          element={
            <PrivateRoute>
              <UpComingEvent />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/topUpComingEvent/:id"
          element={
            <PrivateRoute>
              <TopUpComingEvent />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/expireEvent"
          element={
            <PrivateRoute>
              <ExpireEvent />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/expireEvent/:id"
          element={
            <PrivateRoute>
              <ExpireEvent />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/update"
          element={
            <PrivateRoute>
              <Update />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/helpCenter"
          element={
            <PrivateRoute>
              <HelpCenter />
            </PrivateRoute>
          }
        />

        <Route
          exact
          path="/privacyPolicy"
          element={
            <PrivateRoute>
              <PrivacyPolicy />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/sharetheApp"
          element={
            <PrivateRoute>
              <ShareTheApp />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/termOfuse"
          element={
            <PrivateRoute>
              <TermOfuse />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/logout"
          element={
            <PrivateRoute>
              <Logout />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/expire"
          element={
            <PrivateRoute>
              <Expire />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/upComing"
          element={
            <PrivateRoute>
              <UpComing />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/topevent"
          element={
            <PrivateRoute>
              <TopEvent />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/RecentEvent"
          element={
            <PrivateRoute>
              <RecentEvent />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/dashboard"
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/create-ticket/:id"
          element={
            <PrivateRoute>
              <CreateTicketForm />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/view-ticket/:id"
          element={
            <PrivateRoute>
              <ViewTicket />
            </PrivateRoute>
          }
        />

        {/* Organizer Only Route */}
        <Route
          exact
          path="/editForm/:id"
          element={
            <OrganizerRoute>
              <EditCreateEventForm />
            </OrganizerRoute>
          }
        />

        {/* Fallback Route */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
