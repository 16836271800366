import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import axios from "axios";

const ComingEvent = () => {
  const [events, setEvents] = useState();
  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await axios.get("http://localhost:4000/upComingEvent");
        setEvents(response.data);
      } catch (error) {
        console.error("Error fetching events:", error);
      }
    };

    fetchEvents();
  }, []);
  return (
    <div className="">
      <div className=" rounded-md ">
        <div className="grid xl:grid-cols-5 md:grid-cols-3 grid-cols-1 gap-4">
          {Array.isArray(events) &&
            events.map((event, index) => (
              <Link to={`/organizerDetail/${event.id}`} key={event.id}>
                <div
                  className="shadow-md  bg-white  rounded-md hover:shadow-2xl"
                  key={index}
                >
                  <img
                    src={event.image}
                    className="object-fit  h-[150px]"
                    alt={event.name}
                  />

                  <div className="p-3 flex flex-col ">
                    <p className="font-poppins text-base text-center font-medium leading-5   h-[40px] flex justify-center items-center text-[#333333]">
                      {event.name}
                    </p>
                    <p className="font-poppins text-sm text-center font-medium leading-6 ">
                      {event.time}
                    </p>
                    <p className="font-poppins text-sm text-center font-normal leading-6">
                      {event.location}
                    </p>
                    <p className=" font-poppins text-sm text-center font-medium outline  outline-[#707DFF] leading-9  rounded-full">
                      {event.price}
                    </p>
                  </div>
                </div>
              </Link>
            ))}
        </div>
      </div>
    </div>
  );
};

export default ComingEvent;
