// src/components/layout/Footer/Footer.jsx

import React from "react";
import event from "../../../assets/site-images/eventfullsunday.png";
import { Field, Form, Formik } from "formik";
import { IoPersonCircle } from "react-icons/io5";
import * as Yup from "yup";
import { MdOutlineMail } from "react-icons/md";
import { FaFacebook, FaInstagram, FaWhatsapp, FaGooglePlay, FaApple } from "react-icons/fa";
import { BsTwitterX } from "react-icons/bs";
import { useDispatch } from "react-redux";
import { subscription } from "../../../redux/auth/action/SubscriptionActions";

const Footer = () => {
  const dispatch = useDispatch();

  const initialValues = {
    email: "",
    full_name: "",
  };

  const validationSchema = Yup.object({
    email: Yup.string().email("Invalid email format").required("Required"),
    full_name: Yup.string().required("Name is required").matches(/^[a-zA-Z]+ [a-zA-Z]+$/, "Please enter your full name (first and last name)"),
  });

  const handleFooter = (values, { setSubmitting, resetForm }) => {
    // Handle form submission logic here (e.g., send data to API)
    console.log("Footer Form Values:", values);
    dispatch(subscription(values));
    setSubmitting(false);
    resetForm();
  };

  return (
    <footer className="w-full bg-white" style={{ boxShadow: "0px -1px 16px 0px rgba(0,0,0,0.2)" }}>
      {/* Container for max-width and centered alignment */}
      <div className="relative py-8 max-w-[1200px] mx-auto px-4">
        {/* Footer Content */}
        <div className="relative z-10">
          <div className="flex flex-col md:flex-row justify-between gap-10">
            {/* Left Section */}
            <div className="flex flex-col gap-4">
              <p className="text-xl font-bold text-black font-poppins text-center md:text-left">
                Eventful Sundays
              </p>
              <p className="text-base tracking-wider font-light font-poppins text-center md:text-left">
                Your go-to place for community events
              </p>
              <div className="flex justify-center md:justify-start">
                <img
                  src={event}
                  alt="Organizer"
                  className="max-w-[150px] max-h-[150px]"
                />
              </div>
              <p className="text-xs md:text-left text-center text-black font-poppins">
                &copy; 2024 Eventful Sundays. All rights reserved.
              </p>
            </div>

            {/* Middle Section: Links */}
            {/* Uncomment if needed */}
            
            <div className="text-base font-poppins tracking-wider text-black flex flex-col gap-6 items-center md:items-start justify-center">
              {/*<Link to="/about" className="hover:underline">About us</Link>
              <Link to="/terms" className="hover:underline">Terms and conditions</Link>
              <Link to="/privacy" className="hover:underline">Privacy Policy</Link>
              <Link to="/contact" className="hover:underline">Contact us</Link>*/}
              <a
                href="https://events.eventfullsunday.co.uk/"
                target="_blank"
                rel="noopener noreferrer"
                className="hover:underline"
              >About us</a>
              <a
                href="https://events.eventfullsunday.co.uk/"
                target="_blank"
                rel="noopener noreferrer"
                className="hover:underline"
              >Terms and conditions</a>
              <a
                href="https://events.eventfullsunday.co.uk/"
                target="_blank"
                rel="noopener noreferrer"
                className="hover:underline"
              >Privacy Policy</a>
              <a
                href="https://events.eventfullsunday.co.uk/"
                target="_blank"
                rel="noopener noreferrer"
                className="hover:underline"
              >Contact us</a>
            </div>
           

            {/* Right Section: Footer Form */}
            <div className="flex flex-col items-center md:items-start">
              <Formik
                initialValues={initialValues}
                onSubmit={handleFooter}
                validationSchema={validationSchema}
              >
                {({ isSubmitting, errors, touched }) => (
                  <Form className="w-full md:w-auto p-4 rounded-lg bg-white">
                    <p className="font-bold tracking-wider font-poppins text-center mb-4 text-black text-lg">
                      Subscribe to our Newsletter
                    </p>
                    <div className={errors.full_name && touched.full_name ? "mb-2" : "mb-4"}>
                      <label className="block font-medium text-base text-wider tracking-wider font-poppins">
                        Your Name
                      </label>

                      <div>
                        <div className="relative flex flex-col justify-center items-center">
                          <IoPersonCircle className="absolute left-4 text-gray-500 w-5 h-5" />
                          <Field
                            type="text"
                            name="full_name"
                            placeholder="John Doe"
                            className={`w-full md:text-base text-sm p-2 pl-10 font-poppins rounded-full tracking-wide border-2 ${errors.full_name && touched.full_name ? "border-red-500" : "border-[#0875E1]"
                              } h-10 placeholder:text-xs`}
                          />
                        </div>

                        <div>
                          {errors.full_name && touched.full_name && (
                            <span className=" text-red-500 text-xs">
                              {errors.full_name}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className={errors.email && touched.email ? "mb-2" : "mb-4"}>
                      <label className="block font-medium text-base text-wider tracking-wider font-poppins">
                        Email Address
                      </label>

                      <div>
                        <div className="relative flex items-center">
                          <MdOutlineMail className="absolute left-4 text-gray-500 w-5 h-5" />
                          <Field
                            type="email"
                            name="email"
                            placeholder="johndoe@gmail.com"
                            className={`w-full md:text-base text-sm p-2 pl-10 font-poppins rounded-full tracking-wide border-2 ${errors.email && touched.email ? "border-red-500" : "border-[#0875E1]"
                              } h-10 placeholder:text-xs`}
                          />
                        </div>

                        {errors.email && touched.email && (
                          <span className="text-red-500 text-xs">
                            {errors.email}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="flex justify-center md:justify-start mb-2">
                      <button
                        type="submit"
                        disabled={isSubmitting}
                        className="h-10 bg-[#0875E1] rounded-full cursor-pointer w-36 p-2 text-base tracking-wide text-white"
                      >
                        {isSubmitting ? "Submitting..." : "Subscribe"}
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>

            {/* Social Media & App Links */}
            <div className="flex flex-col gap-6">
              <div className="text-black gap-4">
                <p className="font-semibold mb-4 text-center md:text-left">Follow us on Social Media</p>
                <div className="flex justify-center md:justify-start gap-4">
                  <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
                    <FaFacebook size={30} className="hover:text-blue-700 transition-colors duration-200" />
                  </a>
                  <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
                    <FaInstagram size={30} className="text-[#fd334e] hover:text-pink-600 transition-colors duration-200" />
                  </a>
                  <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
                    <BsTwitterX size={30} className="text-[#000000] hover:text-blue-400 transition-colors duration-200" />
                  </a>
                  <a href="https://whatsapp.com" target="_blank" rel="noopener noreferrer">
                    <FaWhatsapp size={30} className="text-[#25d366] hover:text-green-500 transition-colors duration-200" />
                  </a>
                </div>
              </div>
              <div className="flex flex-col gap-4">
                <p className="text-black font-poppins text-base tracking-wider font-semibold text-center md:text-left">
                  Download our app
                </p>
                <div className="flex justify-center md:justify-start gap-4">
                  <a href="https://play.google.com" target="_blank" rel="noopener noreferrer">
                    <FaGooglePlay size={30} className="hover:text-green-600 transition-colors duration-200" />
                  </a>
                  <a href="https://apple.com/app-store" target="_blank" rel="noopener noreferrer">
                    <FaApple size={30} className="hover:text-gray-700 transition-colors duration-200" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
