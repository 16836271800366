// ProfileUpdateModal.jsx
import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import {
  IoPersonCircle,
  IoCallOutline,
} from "react-icons/io5";
import { FaMapMarkerAlt } from "react-icons/fa";
import { MdOutlineMailOutline, MdClose } from "react-icons/md";
import { useDispatch } from "react-redux";
import { updateOrganizerProfile } from "../../../redux/auth/action/OrganizationProfile";
import { toast } from "react-toastify";

Modal.setAppElement("#root"); // Set the root element for accessibility

const ProfileUpdateModal = ({ isOpen, onRequestClose, initialValues }) => {
  const dispatch = useDispatch();
  const [selectedImage, setSelectedImage] = useState(null);

  // Handle image preview
  const handleImageChange = (e, setFieldValue) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedImage(file);
      setFieldValue("company_logo", file);
    }
  };

  // Cleanup object URL to prevent memory leaks
  useEffect(() => {
    return () => {
      if (selectedImage) {
        URL.revokeObjectURL(selectedImage.preview);
      }
    };
  }, [selectedImage]);

  const handleUpdate = async (values, { setSubmitting }) => {
    try {
      // Create FormData object to hold the profile data
      const formData = new FormData();

      // Required Fields
      formData.append('first_name', values.first_name);
      formData.append('last_name', values.last_name);
      formData.append('email', values.email);
      formData.append('phone_number', values.phone_number);
      formData.append('company_name', values.company_name);
      formData.append('description', values.description);
      formData.append('business_email', values.business_email);
      formData.append('business_phone', values.business_phone);

      // Optional Fields: Always append, sending empty strings if they are cleared
      formData.append('flat_house_number', values.flat_house_number.trim() || '');
      formData.append('street_address', values.street_address.trim() || '');
      formData.append('city_town', values.city_town.trim() || '');
      formData.append('county', values.county.trim() || '');
      formData.append('post_code', values.post_code.trim() || '');
      formData.append('country', values.country.trim() || '');

      // Company Logo (File Upload)
      if (values.company_logo instanceof File) {
        console.log("yeppers: ",values.company_logo);
        
        formData.append('company_logo', values.company_logo);
      }

      // Dispatch the update action
      await dispatch(updateOrganizerProfile(formData));

      toast.success("Profile updated successfully!");
      setSubmitting(false);
      onRequestClose(); // Close the modal after updating
    } catch (error) {
      console.error("Error updating profile:", error);
      toast.error("Failed to update profile. Please try again.");
      setSubmitting(false);
    }
  };

  // Updated Validation Schema
  const validationSchema = Yup.object().shape({
    // Required Fields
    first_name: Yup.string().required("First name is required"),
    last_name: Yup.string().required("Last name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    phone_number: Yup.string()
      .matches(/^[0-9]+$/, "Phone number must contain only digits")
      .min(10, "Phone number must be at least 10 digits")
      .max(15, "Phone number must be at most 15 digits")
      .required("Phone number is required"),
    company_name: Yup.string().required("Company name is required"),
    description: Yup.string()
      .required("Description is required")
      .max(500, "Description can't exceed 500 characters"),
    business_email: Yup.string()
      .email("Invalid business email address")
      .required("Business email is required"),
    business_phone: Yup.string()
      .matches(/^[0-9]+$/, "Business phone must contain only digits")
      .min(10, "Business phone must be at least 10 digits")
      .max(12, "Business phone must be at most 12 digits")
      .required("Business phone is required"),

    // Optional Fields
    flat_house_number: Yup.string().nullable().notRequired(),
    street_address: Yup.string().nullable().notRequired(),
    city_town: Yup.string().nullable().notRequired(),
    county: Yup.string().nullable().notRequired(),
    post_code: Yup.string().nullable().notRequired(),
    country: Yup.string().nullable().notRequired(),
    company_logo: Yup.mixed()
      .nullable()
      .notRequired()
      .test(
        "fileSize",
        "File size is too large (Max 2MB)",
        value => {
          // If no file is uploaded, pass the test
          if (!value || typeof value === "string") return true;
          // Only validate if the value is a File
          return value instanceof File && value.size <= 2 * 1024 * 1024;
        }
      )
      .test(
        "fileFormat",
        "Unsupported file format",
        value => {
          // If no file is uploaded, pass the test
          if (!value || typeof value === "string") return true;
          // Only validate if the value is a File
          return (
            value instanceof File &&
            ["image/jpg", "image/jpeg", "image/gif", "image/png"].includes(value.type)
          );
        }
      ),
  });

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className="flex justify-center items-center p-6"
      overlayClassName="fixed inset-0 bg-gray-600 bg-opacity-75 flex justify-center items-center z-50"
    >
      <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-2xl relative overflow-y-auto max-h-[90vh]">
        {/* Close Button */}
        <button
          onClick={onRequestClose}
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
        >
          <MdClose className="w-6 h-6" />
        </button>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleUpdate}
        >
          {({ isSubmitting, setFieldValue, values }) => (
            <Form className="w-full">
              {/* Title */}
              <p className="text-2xl font-semibold text-center mb-6">Update Profile</p>

              {/* Form Fields */}
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">

                {/* First Name */}
                <div className="mb-4">
                  <label htmlFor="first_name" className="block mb-2 text-sm font-medium">First Name <span className="text-red-500">*</span></label>
                  <div className="relative">
                    <IoPersonCircle className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 w-5 h-5" />
                    <Field
                      id="first_name"
                      name="first_name"
                      type="text"
                      placeholder="First Name"
                      className="w-full p-3 pl-10 border rounded-full focus:outline-none focus:ring-2 focus:ring-[#0875E1]"
                    />
                  </div>
                  <ErrorMessage name="first_name" component="div" className="text-red-500 text-sm mt-1" />
                </div>

                {/* Last Name */}
                <div className="mb-4">
                  <label htmlFor="last_name" className="block mb-2 text-sm font-medium">Last Name <span className="text-red-500">*</span></label>
                  <div className="relative">
                    <IoPersonCircle className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 w-5 h-5" />
                    <Field
                      id="last_name"
                      name="last_name"
                      type="text"
                      placeholder="Last Name"
                      className="w-full p-3 pl-10 border rounded-full focus:outline-none focus:ring-2 focus:ring-[#0875E1]"
                    />
                  </div>
                  <ErrorMessage name="last_name" component="div" className="text-red-500 text-sm mt-1" />
                </div>

                {/* Email */}
                <div className="mb-4">
                  <label htmlFor="email" className="block mb-2 text-sm font-medium">Email <span className="text-red-500">*</span></label>
                  <div className="relative">
                    <MdOutlineMailOutline className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 w-5 h-5" />
                    <Field
                      type="email"
                      name="email"
                      placeholder="Email"
                      className="w-full p-3 pl-10 border rounded-full focus:outline-none focus:ring-2 focus:ring-[#0875E1]"
                    />
                  </div>
                  <ErrorMessage name="email" component="div" className="text-red-500 text-sm mt-1" />
                </div>

                {/* Phone Number */}
                <div className="mb-4">
                  <label htmlFor="phone_number" className="block mb-2 text-sm font-medium">Phone Number <span className="text-red-500">*</span></label>
                  <div className="relative flex items-center">
                    <IoCallOutline className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 w-5 h-5" />
                    <span className="absolute left-8 top-1/2 transform -translate-y-1/2 text-gray-500">+44</span>
                    <Field
                      type="tel"
                      name="phone_number"
                      placeholder="Phone Number"
                      className="w-full p-3 pl-16 border rounded-full focus:outline-none focus:ring-2 focus:ring-[#0875E1]"
                    />
                  </div>
                  <ErrorMessage name="phone_number" component="div" className="text-red-500 text-sm mt-1" />
                </div>

                {/* Company Name (Required) */}
                <div className="mb-4 md:col-span-2">
                  <label htmlFor="company_name" className="block mb-2 text-sm font-medium">Company Name <span className="text-red-500">*</span></label>
                  <div className="relative">
                    <Field
                      id="company_name"
                      name="company_name"
                      type="text"
                      placeholder="Company Name"
                      className="w-full p-3 pl-5 border rounded-full focus:outline-none focus:ring-2 focus:ring-[#0875E1]"
                    />
                  </div>
                  <ErrorMessage name="company_name" component="div" className="text-red-500 text-sm mt-1" />
                </div>

                {/* Description (Required) */}
                <div className="mb-4 md:col-span-2">
                  <label htmlFor="description" className="block mb-2 text-sm font-medium">Description <span className="text-red-500">*</span></label>
                  <div className="relative">
                    <Field
                      id="description"
                      name="description"
                      as="textarea"
                      placeholder="Brief description"
                      className="w-full p-3 pl-5 border rounded-lg focus:outline-none focus:ring-2 focus:ring-[#0875E1] resize-none h-24"
                    />
                  </div>
                  <ErrorMessage name="description" component="div" className="text-red-500 text-sm mt-1" />
                </div>

                {/* Business Email (Required) */}
                <div className="mb-4 md:col-span-2">
                  <label htmlFor="business_email" className="block mb-2 text-sm font-medium">Business Email <span className="text-red-500">*</span></label>
                  <div className="relative">
                    <MdOutlineMailOutline className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 w-5 h-5" />
                    <Field
                      type="email"
                      name="business_email"
                      placeholder="Business Email"
                      className="w-full p-3 pl-10 border rounded-full focus:outline-none focus:ring-2 focus:ring-[#0875E1]"
                    />
                  </div>
                  <ErrorMessage name="business_email" component="div" className="text-red-500 text-sm mt-1" />
                </div>

                {/* Business Phone (Required) */}
                <div className="mb-4 md:col-span-2">
                  <label htmlFor="business_phone" className="block mb-2 text-sm font-medium">Business Phone <span className="text-red-500">*</span></label>
                  <div className="relative flex items-center">
                    <IoCallOutline className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 w-5 h-5" />
                    <span className="absolute left-8 top-1/2 transform -translate-y-1/2 text-gray-500">+44</span>
                    <Field
                      type="tel"
                      name="business_phone"
                      placeholder="Business Phone"
                      className="w-full p-3 pl-16 border rounded-full focus:outline-none focus:ring-2 focus:ring-[#0875E1]"
                    />
                  </div>
                  <ErrorMessage name="business_phone" component="div" className="text-red-500 text-sm mt-1" />
                </div>

                {/* Flat/House Number */}
                <div className="mb-4">
                  <label htmlFor="flat_house_number" className="block mb-2 text-sm font-medium">Flat/House Number</label>
                  <div className="relative">
                    <FaMapMarkerAlt className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 w-5 h-5" />
                    <Field
                      id="flat_house_number"
                      name="flat_house_number"
                      type="text"
                      placeholder="Flat/House Number"
                      className="w-full p-3 pl-10 border rounded-full focus:outline-none focus:ring-2 focus:ring-[#0875E1]"
                    />
                  </div>
                  <ErrorMessage name="flat_house_number" component="div" className="text-red-500 text-sm mt-1" />
                </div>

                {/* Street Address */}
                <div className="mb-4">
                  <label htmlFor="street_address" className="block mb-2 text-sm font-medium">Street Address</label>
                  <div className="relative">
                    <FaMapMarkerAlt className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 w-5 h-5" />
                    <Field
                      id="street_address"
                      name="street_address"
                      type="text"
                      placeholder="Street Address"
                      className="w-full p-3 pl-10 border rounded-full focus:outline-none focus:ring-2 focus:ring-[#0875E1]"
                    />
                  </div>
                  <ErrorMessage name="street_address" component="div" className="text-red-500 text-sm mt-1" />
                </div>

                {/* City/Town */}
                <div className="mb-4">
                  <label htmlFor="city_town" className="block mb-2 text-sm font-medium">City/Town</label>
                  <div className="relative">
                    <FaMapMarkerAlt className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 w-5 h-5" />
                    <Field
                      id="city_town"
                      name="city_town"
                      type="text"
                      placeholder="City/Town"
                      className="w-full p-3 pl-10 border rounded-full focus:outline-none focus:ring-2 focus:ring-[#0875E1]"
                    />
                  </div>
                  <ErrorMessage name="city_town" component="div" className="text-red-500 text-sm mt-1" />
                </div>

                {/* County */}
                <div className="mb-4">
                  <label htmlFor="county" className="block mb-2 text-sm font-medium">County</label>
                  <div className="relative">
                    <FaMapMarkerAlt className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 w-5 h-5" />
                    <Field
                      id="county"
                      name="county"
                      type="text"
                      placeholder="County"
                      className="w-full p-3 pl-10 border rounded-full focus:outline-none focus:ring-2 focus:ring-[#0875E1]"
                    />
                  </div>
                  <ErrorMessage name="county" component="div" className="text-red-500 text-sm mt-1" />
                </div>

                {/* Postcode */}
                <div className="mb-4">
                  <label htmlFor="post_code" className="block mb-2 text-sm font-medium">Postcode</label>
                  <div className="relative">
                    <FaMapMarkerAlt className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 w-5 h-5" />
                    <Field
                      id="post_code"
                      name="post_code"
                      type="text"
                      placeholder="Postcode"
                      className="w-full p-3 pl-10 border rounded-full focus:outline-none focus:ring-2 focus:ring-[#0875E1]"
                    />
                  </div>
                  <ErrorMessage name="post_code" component="div" className="text-red-500 text-sm mt-1" />
                </div>

                {/* Country */}
                <div className="mb-4">
                  <label htmlFor="country" className="block mb-2 text-sm font-medium">Country</label>
                  <div className="relative">
                    <FaMapMarkerAlt className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 w-5 h-5" />
                    <Field
                      id="country"
                      name="country"
                      type="text"
                      placeholder="Country"
                      className="w-full p-3 pl-10 border rounded-full focus:outline-none focus:ring-2 focus:ring-[#0875E1]"
                    />
                  </div>
                  <ErrorMessage name="country" component="div" className="text-red-500 text-sm mt-1" />
                </div>

                {/* Company Logo */}
                <div className="mb-4 md:col-span-2">
                  <label htmlFor="company_logo" className="block mb-2 text-sm font-medium">Company Logo</label>
                  <input
                    type="file"
                    id="company_logo"
                    name="company_logo"
                    onChange={(e) => handleImageChange(e, setFieldValue)}
                    accept="image/*"
                    className="w-full p-3 border rounded-full focus:outline-none focus:ring-2 focus:ring-[#0875E1]"
                  />
                  {selectedImage && (
                    <img
                      src={URL.createObjectURL(selectedImage)} // Display the new image
                      alt="Preview"
                      className="mt-2 w-24 h-24 object-cover rounded-full border-2 border-[#0875E1]"
                    />
                  )}
                  {!selectedImage && initialValues.company_logo && (
                    <div className="mt-2 flex items-center">
                      <img
                        src={initialValues.company_logo} // Display the existing image
                        alt="Company Logo"
                        className="w-24 h-24 object-cover rounded-full border-2 border-[#0875E1]"
                      />

                      {/* REMOVE BUTTON THAT CAN BE ADDED IN THE FUTURE */}
                      {/* <button
                        type="button"
                        onClick={() => setFieldValue("company_logo", null)}
                        className="ml-4 bg-red-500 text-white px-2 py-1 rounded-full"
                        title="Remove Company Logo"
                      >
                        Remove
                      </button> */}

                    </div>
                  )}
                  <ErrorMessage name="company_logo" component="div" className="text-red-500 text-sm mt-1" />
                </div>

              </div>

              {/* Footer - Buttons */}
              <div className="flex justify-between mt-6">
                <button
                  type="button"
                  onClick={onRequestClose}
                  className="bg-gray-500 hover:bg-gray-600 text-white rounded-full px-6 py-2 transition duration-200"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className={`bg-[#0875E1] hover:bg-blue-700 text-white rounded-full px-6 py-2 transition duration-200 ${isSubmitting ? 'opacity-50 cursor-not-allowed' : ''
                    }`}
                >
                  {isSubmitting ? "Updating..." : "Update"}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default ProfileUpdateModal;
