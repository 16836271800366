import React, { useState, useEffect } from "react";

import axios from "axios";

const UpComing = () => {
  const [upcoming, setUpComing] = useState();

  return (
    <div>
     <p>Up Coming Event</p>
    </div>
  );
};

export default UpComing;
