import apiClient from "../../../apiClient/apiClient";
import {
  VENUE_UPDATE_EDIT_FAILURE,
  VENUE_UPDATE_EDIT_REQUEST,
  VENUE_UPDATE_EDIT_SUCCESS,
} from "../VenueConstant/VenueUpdataConstant";

// export const updateVenue=(id)=>async(dispatch)=>{
//     dispatch({
//         type:VENUE_UPDATE_EDIT_REQUEST
//     })
//     try{
//         const token=localStorage.getItem("access")
//         const config={
//             headers:{
//                 Authorization:`Bearer ${token}`,
//                 'Content-Type':'multipart/form-data'
//             }
//         }
//         const response=await apiClient.put(`/register-venue/${id}`,config)
//         dispatch({
//             type:VENUE_UPDATE_EDIT_SUCCESS,
//             payload:response.data
//         })
//     }
//     catch(error){
//        dispatch({
//         type:VENUE_UPDATE_EDIT_FAILURE,
//         payload:error.message
//        })
//     }
// }

// export const updateEditVenue = (id) => async (dispatch) => {
//     dispatch({ type: VENUE_UPDATE_EDIT_REQUEST });

//     try {
//         const token = localStorage.getItem('access');
//         const config = {
//             headers: {
//                 Authorization: `Bearer ${token}`,
//             },
//         };

//         const response = await apiClient.put(`/register-venue/${id}`, config);
//         dispatch({
//             type: VENUE_UPDATE_EDIT_SUCCESS,
//             payload: response.data,
//         });
//     } catch (error) {
//         dispatch({
//             type: VENUE_UPDATE_EDIT_FAILURE,
//             payload: error.message,
//         });
//     }
// };

// Action creator to update venue
// export const updateEditVenue = (id, data) => async (dispatch) => {
//     dispatch({ type: VENUE_UPDATE_EDIT_REQUEST });

//     try {
//         const token = localStorage.getItem('access');
//         const config = {
//             headers: {
//                 Authorization: `Bearer ${token}`,
//                 'Content-Type': 'application/json', // Ensure content type is JSON
//             },
//         };

//         // Make the PUT request to update the venue
//         const response = await apiClient.put(`/register-venue/${id}`, data, config);

//         dispatch({
//             type: VENUE_UPDATE_EDIT_SUCCESS,
//             payload: response.data,
//         });
//     } catch (error) {
//         dispatch({
//             type: VENUE_UPDATE_EDIT_FAILURE,
//             payload: error.message,
//         });
//     }
// };

export const updateEditVenue = (id, data) => async (dispatch) => {
  dispatch({ type: VENUE_UPDATE_EDIT_REQUEST });

  try {
    const token = localStorage.getItem("access");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    // Make the PUT request to update the venue with a trailing slash
    const response = await apiClient.put(
      `/register-venue/${id}/`,
      data,
      config
    );

    dispatch({
      type: VENUE_UPDATE_EDIT_SUCCESS,
      payload: response.data,
    });
    window.location.href = "/venue";
  } catch (error) {
    dispatch({
      type: VENUE_UPDATE_EDIT_FAILURE,
      payload: error.message,
    });
  }
};
