import {
  REGISTER_FAILURE,
  REGISTER_SUCCESS,
} from "../constant/RegisterConstant";



const initialState = {
  isLoading: false,
  user: null,
  error: null,
};

 const registerReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'REGISTER_REQUEST':
      return {
        ...state,
        isLoading: true,
      };
    case 'REGISTER_SUCCESS':
      return {
        ...state,
        isLoading: false,
        user: action.payload,
      };
    case 'REGISTER_FAILURE':
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default registerReducer;