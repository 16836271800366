import React, { useEffect } from 'react'
import { MdOutlineDashboardCustomize } from "react-icons/md";
import { CgProfile } from "react-icons/cg";
import {IoLogInOutline} from "react-icons/io5";
import { Link, useLocation } from "react-router-dom";

function LeftPane() {

  const location = useLocation();

  const isActive = (path) => location.pathname === path;

  return (
    <div className="w-[25vw] bg-gray-800 text-white mt-5 bg-white">
        <h2 className="text-xl font-semibold m-5">Left Pane</h2>
        <ul>
          <li className={`hover:bg-blue-100 text-black p-2 ${isActive('/dashboard') ? 'bg-blue-100' : 'bg-white'}`}>
          <Link
                to="/dashboard"
                className="block px-4 py-4 hover:bg-blue-100 text-gray-700"
              >
                <div className="flex">
                  <p className='pr-1'>
                    <MdOutlineDashboardCustomize size={25} />
                  </p>
                  <p className='pl-1'>Dashboard</p>
                </div>
              </Link>
          </li>
          <li className={`hover:bg-blue-100 text-black p-2 ${isActive('/update') ? 'bg-blue-100' : 'bg-white'}`}>
          <Link
                to="/update"
                className="block hover:bg-blue-100 px-4 py-4 text-gray-700"
              >
                <div className="flex">
                  <p className='pr-1'>
                    <CgProfile size={25} />
                  </p>
                  <p className='pl-1'>Profile</p>
                </div>
              </Link>
          </li>
          <li className="hover:bg-blue-100 text-black p-2">
          <Link
                to="/logout"
                className="block hover:bg-blue-100 px-4 py-4 text-gray-700"
              >
                <div className="flex">
                    <p className='pr-1'>
                      <IoLogInOutline size={25} />
                    </p>
                    <p className='pl-1'>Logout</p>
                  </div>
              </Link>
          </li>
        </ul>
      </div>
  )
}

export default LeftPane
