import React, { useState } from "react";
import organizer from "../../assets/site-images/organizer.png";
import { ErrorMessage, Field, Form, Formik } from "formik";
import {
  IoPersonCircle,
  IoEyeOutline,
  IoEyeOffOutline,
  IoCallOutline,
  IoLockClosedOutline,
} from "react-icons/io5";
import { MdOutlineMailOutline } from "react-icons/md";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { registerUser } from "../../redux/auth/action/RegisterAction";
//import CustomField from "./Custom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Yup from "yup";

const Register = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState(false);

  const dispatch = useDispatch();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmPassword(!confirmPassword);
  };

  const handleRegister = (values, { setSubmitting }) => {
    const transformedValues = { ...values, phone_number: values.phone_number === "" ? null : values.phone_number }
    console.log(transformedValues);
    
    dispatch(registerUser(transformedValues));
    setSubmitting(false);
  };

  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required("First name is required"),
    last_name: Yup.string().required("Last name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    phone_number: Yup.string()
      .matches(/^[0-9]+$/, "Phone number must be only digits")
      .min(10, "Phone number must be at least 10 digits")
      .max(15, "Phone number must be at most 15 digits")
      .required("Phone number is required"),
    password: Yup.string()
      .min(8, "Password must be at least 8 characters")
      .required("Password is required"),
    confirm_password: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm password is required"),
  });

  return (
    <div className="flex flex-col lg:flex-row h-screen font-poppins">
      {/* Left Section */}
      <div className="flex justify-center items-center w-full lg:w-1/2 bg-[#f5f5f5] p-6 lg:p-0">
        <div className="flex flex-col items-center gap-6 text-center">
          <p className="text-2xl font-semibold md:text-3xl">
            Welcome to the <span className="block text-blue-600">Organizer End!</span>
          </p>
          <img src={organizer} alt="Organizer" className="w-full max-w-md" />
        </div>
      </div>

      {/* Right Section: Register Form */}
      <div className="flex justify-center items-center w-full lg:w-1/2 bg-white p-6 lg:m-0 lg:p-0">
        <div className="bg-white p-6 lg:p-8 rounded-lg w-full max-w-md overflow-y-auto">
          <Formik
            initialValues={{
              first_name: "",
              last_name: "",
              email: "",
              phone_number: "",
              password: "",
              confirm_password: "",
              role: "",
            }}
            validationSchema={validationSchema}
            onSubmit={handleRegister}
            className="flex justify-center items-center"
          >
            {({ isSubmitting }) => (
              <Form className="w-full">
                <p className="text-2xl font-semibold text-center mb-4">
                  Register
                </p>
                <div className="mb-2">
                  <label
                    htmlFor="first_name"
                    className="block mb-1 text-sm font-medium"
                  >
                    First Name
                  </label>
                  <div className="relative">
                    <IoPersonCircle className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 w-5 h-5" />
                    <Field
                      id="first_name"
                      name="first_name"
                      type="text"
                      placeholder="Joshuwa"
                      className="w-full p-2 pl-10 border rounded-full focus:outline-none focus:ring-2 focus:ring-blue-600"
                    />
                  </div>
                  <ErrorMessage
                    name="first_name"
                    component="div"
                    className="text-red-500 text-sm mt-1"
                  />
                </div>
                <div className="mb-2">
                  <label
                    htmlFor="last_name"
                    className="block mb-1 text-sm font-medium"
                  >
                    Last Name
                  </label>
                  <div className="relative">
                    <IoPersonCircle className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 w-5 h-5" />
                    <Field
                      id="last_name"
                      name="last_name"
                      type="text"
                      placeholder="Mashon"
                      className="w-full p-2 pl-10 border rounded-full focus:outline-none focus:ring-2 focus:ring-blue-600"
                    />
                  </div>
                  <ErrorMessage
                    name="last_name"
                    component="div"
                    className="text-red-500 text-sm mt-1"
                  />
                </div>
                <div className="mb-2">
                  <label
                    htmlFor="email"
                    className="block mb-1 text-sm font-medium"
                  >
                    Email
                  </label>
                  <div className="relative">
                    <MdOutlineMailOutline className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 w-5 h-5" />
                    <Field
                      type="email"
                      name="email"
                      placeholder="joshuwamason@gmail.com"
                      className="w-full p-2 pl-10 border rounded-full focus:outline-none focus:ring-2 focus:ring-blue-600"
                    />
                  </div>
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="text-red-500 text-sm mt-1"
                  />
                </div>
                <div className="mb-2">
                  <label
                    htmlFor="phone_number"
                    className="block mb-1 text-sm font-medium"
                  >
                    Phone Number
                  </label>
                  <div className="relative flex items-center">
                    <IoCallOutline className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 w-5 h-5" />
                    <span className="absolute left-9 top-1/2 transform -translate-y-1/2 text-gray-500">
                      +44
                    </span>
                    <Field
                      type="tel"
                      name="phone_number"
                      placeholder="   075********"
                      className="w-full p-2 pl-16 border rounded-full focus:outline-none focus:ring-2 focus:ring-blue-600"
                      style={{ paddingLeft: "70px" }}
                    />
                  </div>
                  <ErrorMessage
                    name="phone_number"
                    component="div"
                    className="text-red-500 text-sm mt-1"
                  />
                </div>

                <div className="mb-2">
                  <label
                    htmlFor="password"
                    className="block mb-1 text-sm font-medium"
                  >
                    Password
                  </label>
                  <div className="relative">
                    <IoLockClosedOutline className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 w-5 h-5" />
                    <Field
                      type={showPassword ? "text" : "password"}
                      name="password"
                      placeholder="Iampassword123"
                      className="w-full p-2 pl-10 border rounded-full focus:outline-none focus:ring-2 focus:ring-blue-600"
                    />
                    <button
                      type="button"
                      onClick={togglePasswordVisibility}
                      className="text-gray-500 cursor-pointer absolute right-3 top-1/2 transform -translate-y-1/2"
                    >
                      {showPassword ? (
                        <IoEyeOffOutline className="w-5 h-5" />
                      ) : (
                        <IoEyeOutline className="w-5 h-5" />
                      )}
                    </button>
                  </div>
                  <ErrorMessage
                    name="password"
                    component="div"
                    className="text-red-500 text-sm mt-1"
                  />
                </div>
                <div className="mb-2">
                  <label
                    htmlFor="confirm_password"
                    className="block mb-1 text-sm font-medium"
                  >
                    Confirm Password
                  </label>
                  <div className="relative">
                    <IoLockClosedOutline className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 w-5 h-5" />
                    <Field
                      type={confirmPassword ? "text" : "password"}
                      name="confirm_password"
                      placeholder="Iampassword123"
                      className="w-full p-2 pl-10 border rounded-full focus:outline-none focus:ring-2 focus:ring-blue-600"
                    />
                    <button
                      type="button"
                      onClick={toggleConfirmPasswordVisibility}
                      className="text-gray-500 cursor-pointer absolute right-3 top-1/2 transform -translate-y-1/2"
                    >
                      {confirmPassword ? (
                        <IoEyeOffOutline className="w-5 h-5" />
                      ) : (
                        <IoEyeOutline className="w-5 h-5" />
                      )}
                    </button>
                  </div>
                  <ErrorMessage
                    name="confirm_password"
                    component="div"
                    className="text-red-500 text-sm mt-1"
                  />
                </div>
                <div className="mb-2">
                  <label
                    htmlFor="role"
                    className="block mb-1 text-sm font-medium"
                  >
                    Role
                  </label>
                  <div className="relative">
                    <Field
                      as="select"
                      id="role"
                      name="role"
                      className="w-full p-2 border rounded-full focus:outline-none focus:ring-2 focus:ring-blue-600"
                    >
                      <option value="" label="Select role" />
                      <option value="user" label="User" />
                      <option value="organizer" label="Organzier" />
                    </Field>
                  </div>
                  <ErrorMessage
                    name="role"
                    component="div"
                    className="text-red-500 text-sm mt-1"
                  />
                </div>
                <div className="flex justify-center mt-7 mb-5">
                  <button
                    type="submit"
                    className="w-full p-2 bg-blue-600 text-white rounded-full hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-600"
                  >
                    Register
                  </button>
                </div>
                <div className="text-center">
                  <p className="text-sm">
                    Already have an account?{" "}
                    <Link to="/" className="text-blue-600 underline">
                      Login Here
                    </Link>
                  </p>
                </div>
              </Form>
            )}
          </Formik>
          <ToastContainer position="top-right" autoClose={5000} />
        </div>
      </div>
    </div>
  );
};

export default Register;
