import apiClient from "../../../apiClient/apiClient";
import { VENUE_FAILURE, VENUE_REQUEST, VENUE_SUCCESS } from "../VenueConstant/VenueConstant";


export const venueRequest=()=>({
    type:VENUE_REQUEST
})

export const venueSuccess=(venue)=>({
    type:VENUE_SUCCESS,
    payload:venue
})

export const venueFailure=(error)=>({
    type:VENUE_FAILURE,
    payload:error
})


export const venueRegister=(formData)=>async(dispatch)=>{
    dispatch({type:VENUE_REQUEST})
    try{
        const token=localStorage.getItem('access')
        const config={
            headers:{
                'Content-Type':'multipart/form-data',
                Authorization:`Bearer ${token}`,
            }
        }
        const response=await apiClient.post('/register-venue/',formData,config)
        dispatch({
            type:VENUE_SUCCESS,
            payload:response.data
        })
    }
    catch(error){
        dispatch({
            type:VENUE_FAILURE,
            payload:error.message
        })
    }
}