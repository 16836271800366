// src/components/layout/Navbar/FilterModal.jsx

import React, { useState } from "react";
import { IoClose } from "react-icons/io5";
import { IoFilterSharp } from "react-icons/io5";
import { motion, AnimatePresence } from "framer-motion";
import { toast } from 'react-toastify';

const FilterModal = ({ isOpen, onClose, onFilter }) => {
  const initialFilterState = {
    name: "",
    description: "",
    country: "United Kingdom",
    subCategory: "",
    date: "",
    startTime: "",
    endTime: "",
    venueName: "",
    venueAddress: "",
    ticketPrice: "",
    isFreeTicket: false,
    capacity: "",
  };

  const [filter, setFilter] = useState(initialFilterState);

  const handleFilterChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFilter((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubCategoryClick = (subCategory) => {
    setFilter((prev) => ({
      ...prev,
      subCategory: prev.subCategory === subCategory ? "" : subCategory,
    }));
  };

  const handleClearFilters = () => {
    setFilter(initialFilterState);
  };

  const handleSearch = () => {
    onFilter(filter);
    onClose();
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          className="fixed inset-0 bg-black bg-opacity-50 z-50 overflow-y-auto"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          onClick={onClose} // Close when clicking outside modal
        >
          <div className="flex items-center justify-center min-h-screen p-4">
            <motion.div
              className="bg-white rounded-lg w-full max-w-3xl p-6 relative max-h-[calc(100vh-4rem)] overflow-y-auto"
              initial={{ scale: 0.8, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.8, opacity: 0 }}
              transition={{ duration: 0.3 }}
              onClick={(e) => e.stopPropagation()} // Prevent click inside modal from closing
            >
              {/* Header */}
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-2xl font-semibold flex items-center">
                  <IoFilterSharp size={24} className="mr-2" />
                  Filter Events
                </h2>
                <button
                  onClick={onClose}
                  className="text-gray-600 hover:text-gray-800 focus:outline-none"
                  aria-label="Close Filter Modal"
                >
                  <IoClose size={24} />
                </button>
              </div>

              {/* Modal Body */}
              <form className="space-y-4">
                {/* Event Name */}
                <div>
                  <label className="block text-gray-700 font-medium mb-2" htmlFor="name">
                    Name of the Event
                  </label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    placeholder="Event Name"
                    value={filter.name}
                    onChange={handleFilterChange}
                    className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600"
                  />
                </div>

                {/* Event Description */}
                <div>
                  <label className="block text-gray-700 font-medium mb-2" htmlFor="description">
                    Event Description
                  </label>
                  <textarea
                    name="description"
                    id="description"
                    placeholder="Event Description"
                    rows={4}
                    value={filter.description}
                    onChange={handleFilterChange}
                    className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600"
                  />
                </div>

                {/* Event Country */}
                <div>
                  <label className="block text-gray-700 font-medium mb-2" htmlFor="country">
                    Event Country
                  </label>
                  <select
                    name="country"
                    id="country"
                    value={filter.country}
                    onChange={handleFilterChange}
                    className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600"
                  >
                    <option>United Kingdom</option>
                    <option>United States</option>
                    <option>Canada</option>
                    {/* Add more countries as needed */}
                  </select>
                </div>

                {/* Sub Category */}
                <div>
                  <label className="block text-gray-700 font-medium mb-2">
                    Sub Category
                  </label>
                  <div className="flex flex-wrap gap-2">
                    {['Music', 'Sports', 'Live Events', 'Business', 'Arts', 'Shows', 'Festival'].map((subCategory) => (
                      <button
                        key={subCategory}
                        type="button"
                        onClick={() => handleSubCategoryClick(subCategory)}
                        className={`px-4 py-2 rounded-full border ${
                          filter.subCategory === subCategory
                            ? 'bg-blue-600 text-white border-blue-600'
                            : 'bg-white text-gray-700 border-gray-300 hover:bg-blue-100'
                        } focus:outline-none`}
                      >
                        {subCategory}
                      </button>
                    ))}
                  </div>
                </div>

                {/* Date and Time */}
                <div>
                  <label className="block text-gray-700 font-medium mb-2">
                    Date and Time
                  </label>
                  <div className="flex flex-col sm:flex-row sm:items-center sm:space-x-4">
                    <div className="flex flex-col sm:flex-row sm:items-center sm:space-x-2">
                      <div className="flex flex-col">
                        <label className="text-gray-600 mb-1" htmlFor="date">
                          Date
                        </label>
                        <input
                          type="date"
                          name="date"
                          id="date"
                          value={filter.date}
                          onChange={handleFilterChange}
                          className="px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600"
                        />
                      </div>
                      <div className="flex flex-col">
                        <label className="text-gray-600 mb-1" htmlFor="startTime">
                          Start Time
                        </label>
                        <input
                          type="time"
                          name="startTime"
                          id="startTime"
                          value={filter.startTime}
                          onChange={handleFilterChange}
                          className="px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600"
                        />
                      </div>
                      <span className="self-end sm:self-center text-gray-600 mx-2">to</span>
                      <div className="flex flex-col">
                        <label className="text-gray-600 mb-1" htmlFor="endTime">
                          End Time
                        </label>
                        <input
                          type="time"
                          name="endTime"
                          id="endTime"
                          value={filter.endTime}
                          onChange={handleFilterChange}
                          className="px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {/* Venue Name */}
                <div>
                  <label className="block text-gray-700 font-medium mb-2" htmlFor="venueName">
                    Venue Name
                  </label>
                  <input
                    type="text"
                    name="venueName"
                    id="venueName"
                    placeholder="Enter the name of the venue"
                    value={filter.venueName}
                    onChange={handleFilterChange}
                    className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600"
                  />
                </div>

                {/* Venue Address */}
                <div>
                  <label className="block text-gray-700 font-medium mb-2" htmlFor="venueAddress">
                    Venue Address
                  </label>
                  <input
                    type="text"
                    name="venueAddress"
                    id="venueAddress"
                    placeholder="Enter the address of the venue"
                    value={filter.venueAddress}
                    onChange={handleFilterChange}
                    className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600"
                  />
                </div>

                {/* Max Ticket Price */}
                <div>
                  <label className="block text-gray-700 font-medium mb-2" htmlFor="ticketPrice">
                    Max Ticket Price
                  </label>
                  <div className="flex items-center space-x-2">
                    <input
                      type="number"
                      name="ticketPrice"
                      id="ticketPrice"
                      placeholder="£0.00"
                      value={filter.ticketPrice}
                      onChange={handleFilterChange}
                      min="0"
                      step="0.01"
                      disabled={filter.isFreeTicket}
                      className="w-32 px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600"
                    />
                    <button
                      type="button"
                      onClick={() => setFilter((prev) => ({ ...prev, isFreeTicket: !prev.isFreeTicket, ticketPrice: prev.isFreeTicket ? "" : prev.ticketPrice }))}
                      className={`px-4 py-2 rounded-lg border focus:outline-none ${
                        filter.isFreeTicket
                          ? 'bg-blue-600 text-white border-blue-600'
                          : 'bg-white text-gray-700 border-gray-300 hover:bg-blue-100'
                      }`}
                    >
                      {filter.isFreeTicket ? 'Free Ticket' : 'Set as Free'}
                    </button>
                  </div>
                </div>

                {/* Min Capacity */}
                <div>
                  <label className="block text-gray-700 font-medium mb-2" htmlFor="capacity">
                    Min Capacity
                  </label>
                  <input
                    type="number"
                    name="capacity"
                    id="capacity"
                    placeholder="0"
                    min="0"
                    step="1"
                    value={filter.capacity}
                    onChange={handleFilterChange}
                    className="w-24 px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600"
                  />
                </div>
              </form>

              {/* Modal Footer */}
              <div className="mt-6 flex justify-end space-x-4">
                <button
                  onClick={onClose}
                  className="px-4 py-2 bg-gray-300 text-gray-700 rounded-lg hover:bg-gray-400 focus:outline-none"
                >
                  Close
                </button>
                <button
                  onClick={handleClearFilters}
                  className="px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 focus:outline-none"
                >
                  Clear Filters
                </button>
                <button
                  onClick={handleSearch}
                  className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 focus:outline-none"
                >
                  Search
                </button>
              </div>
            </motion.div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default FilterModal;
