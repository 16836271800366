import React, { useEffect, useState } from "react";
import { Formik, Field, Form } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { IoCallOutline, IoPersonCircle } from "react-icons/io5";
import { FaMapMarkerAlt } from "react-icons/fa";
import { MdOutlineMailOutline } from "react-icons/md";
import { updateOrganizerProfile } from "../../../../redux/auth/action/OrganizationProfile";
import { getProfile } from "../../../../redux/auth/action/Profile";
import Navbar from "../../../layout/Navbar/Navbar";
import LeftPane from "../../../layout/LeftPane/LeftPane"
import { Link } from "react-router-dom";
import { resendLinkAction } from "../../../../redux/auth/action/ResendLinkAction";
import { toast } from "react-toastify";

const Update = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const dispatch = useDispatch();
  const profileState = useSelector((state) => state.profile);
  const verifiedUser = useSelector((state) => state.verified);
  const linkSend = useSelector((state) => state.resendLink);

  console.log(linkSend);

  // Open modal
  const openModal = () => {
    setModalIsOpen(true);
  };

  // Close modal
  const closeModal = () => {
    setModalIsOpen(false);
  };

  // Fetch profile data
  useEffect(() => {
    dispatch(getProfile());
  }, [dispatch]);

  if (!profileState.profile) {
    return <div>Loading...</div>;
  }

  console.log(verifiedUser);

  const {
    first_name,
    last_name,
    company_name,
    description,
    business_email,
    business_phone,
    flat_house_number,
    street_address,
    city_town,
    county,
    company_logo,
    country,
    post_code,
  } = profileState.profile || {};

  // Handle image file change
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setSelectedImage(file);
  };

  const handleVerification = () => {
      window.location.href = `mailto:${verifiedUser.data.email}`
  }

  const handleResend = () => {
    if(verifiedUser.data.email) {
      const email = verifiedUser.data.email;
      dispatch(resendLinkAction({ email }));
      toast.success("New verification mail sent");
    }
    if(!verifiedUser.data.email) {
      toast.error("Email not found. Try sending new verification email.")
    }
  }

  // Handle form submission
  const handleUpdateProfile = (values) => {
    const formData = new FormData();
    formData.append("first_name", values.first_name);
    formData.append("last_name", values.last_name);
    formData.append("company_name", values.company_name);
    formData.append("description", values.description);
    formData.append("business_email", values.business_email);
    formData.append("business_phone", values.business_phone);
    formData.append("flat_house_number", values.flat_house_number);
    formData.append("street_address", values.street_address);
    formData.append("city_town", values.city_town);
    formData.append("county", values.county)
    formData.append("post_code", values.post_code);
    formData.append("country", values.country);

    if (selectedImage) {
      formData.append("company_logo", selectedImage);
    } /*else {
      formData.append("company_logo", values.company_logo);
    }*/

    dispatch(updateOrganizerProfile(formData));
    closeModal();
  };

  return (
    <div className="w-full h-full font-poppins text-[#565656]">
      <Navbar linkTo="/home" title="profile" />
      <div className="flex h-full w-full">
        <LeftPane />
      <div className="min-h-screen bg-gray-100 flex items-center justify-center px-4 sm:px-6 lg:px-8 mt-8 w-[75vw]">
      <div className="bg-white shadow-lg rounded-lg p-8 w-full max-w-md lg:max-w-lg">
      <div className="mt-2 flex items-center">
          {verifiedUser && verifiedUser?.data?.is_verified ? (
            <span className="flex items-center text-green-600 font-semibold">
              <svg
                className="w-4 h-4 mr-1 text-green-600"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M16.707 5.293a1 1 0 00-1.414 0L9 11.586 6.707 9.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l7-7a1 1 0 000-1.414z"
                  clipRule="evenodd"
                />
              </svg>
              Verified
            </span>
          ) : (
            <>
            <span className="flex items-center text-red-600 font-semibold">
              <svg
                className="w-4 h-4 mr-1 text-red-600"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-10.707a1 1 0 00-1.414 0L9 11.586l-1.293-1.293a1 1 0 10-1.414 1.414l2 2a1 1 0 001.414 0l5-5a1 1 0 000-1.414z"
                  clipRule="evenodd"
                />
              </svg>
              Not Verified
            </span>
            <button onClick={handleVerification} className=" p-1 items-center text-blue-600 text-sm">Verify your account.</button>
            <button onClick={handleResend} className=" p-1 items-center text-blue-600 text-sm">Resend link</button>
          </>
          )}
        </div>
        <div className="flex flex-col items-center">
        {!selectedImage && company_logo ? (
        <img
          src={company_logo}
          alt="Company Logo"
          className="w-32 h-32 sm:w-64 sm:h-64 rounded-full border-4 border-blue-500"
        />
      ) : (
        selectedImage && (
          <img
            src={selectedImage}
            alt="Company Logo"
            className="w-32 h-32 sm:w-64 sm:h-64 rounded-full border-4 border-blue-500"
          />
        )
      )}
          
          <h1 className="text-xl sm:text-2xl font-bold mt-4 text-center">{`${first_name} ${last_name}`}</h1>
          <p className="text-sm sm:text-base text-gray-600">{company_name}</p>
          <p className="text-sm sm:text-base text-gray-600">Email: {business_email}</p>
          <p className="text-sm sm:text-base text-gray-600">{business_phone}</p>
          <p className="text-sm sm:text-base text-gray-600">{flat_house_number &&`${flat_house_number}, `}{street_address && `${street_address}, `}{city_town &&`${city_town} `}</p>
          <p className="text-sm sm:text-base text-gray-600">{county &&`${county}, `}{post_code && `${post_code}, `}{country &&`${country} `}</p>
          <p className="mt-4 text-sm sm:text-base text-gray-800 text-center w-full break-words">{description}</p>
        </div>
        <button onClick={openModal} className="mt-6 w-full bg-blue-600 text-white py-2 rounded-lg hover:bg-blue-700 transition duration-200 text-sm sm:text-base">
          Update Profile
        </button>
      </div>
    </div>

      {/*<div className="flex justify-between font-poppins text-base p-6 bg-white shadow-lg rounded-lg max-w-3xl mx-auto mt-20">
  
  <div className="space-y-4">
    <div className="text-lg">
      <p className="font-semibold text-xl leading-7 tracking-wide text-gray-700">
        First Name: <span className="text-base font-medium text-gray-500">{first_name}</span>
      </p>
    </div>
    <div className="text-lg">
      <p className="font-semibold text-xl leading-7 tracking-wide text-gray-700">
        Last Name: <span className="text-base font-medium text-gray-500">{last_name}</span>
      </p>
    </div>
    <div className="text-lg">
      <p className="font-medium text-xl leading-7 tracking-wide text-gray-700">
        Company: <span className="text-base font-normal text-gray-500">{company_name}</span>
      </p>
    </div>
    <div className="text-lg">
      <p className="font-medium text-xl leading-7 tracking-wide text-gray-700">
        Business Email: <span className="text-base font-normal text-gray-500">{business_email}</span>
      </p>
    </div>
    <div className="text-lg">
      <p className="font-medium text-xl leading-7 tracking-wide text-gray-700">
        Business Phone: <span className="text-base font-normal text-gray-500">{business_phone}</span>
      </p>
    </div>
    <div className="text-lg">
      <p className="font-medium text-xl leading-7 tracking-wide text-gray-700">
        Street: <span className="text-base font-normal text-gray-500">{street_address}</span>
      </p>
    </div>
    <div className="text-lg">
      <p className="font-medium text-xl leading-7 tracking-wide text-gray-700">
        City: <span className="text-base font-normal text-gray-500">{city_town}</span>
      </p>
    </div>
    <div className="text-lg">
      <p className="font-medium text-xl leading-7 tracking-wide text-gray-700">
        County: <span className="text-base font-normal text-gray-500">{county}</span>
      </p>
    </div>
    <div className="text-lg">
      <p className="font-medium text-xl leading-7 tracking-wide text-gray-700">
        Country: <span className="text-base font-normal text-gray-500">{country}</span>
      </p>
    </div>
    <div className="text-lg">
      <p className="font-medium text-xl leading-7 tracking-wide text-gray-700">
        PostCode: <span className="text-base font-normal text-gray-500">{post_code}</span>
      </p>
    </div>
    <div className="text-lg">
      <p className="font-medium text-xl leading-7 tracking-wide text-gray-700">Company Logo:</p>
      {!selectedImage && company_logo ? (
        <img
          src={company_logo}
          alt="Company Logo"
          className="mt-2 w-24 h-24 object-cover rounded-md shadow"
        />
      ) : (
        selectedImage && (
          <img
            src={selectedImage}
            alt="Company Logo"
            className="mt-2 w-24 h-24 object-cover rounded-md shadow"
          />
        )
      )}
    </div>
  </div>

  
  <div className="flex items-start">
    <button
      onClick={openModal}
      className="bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 px-6 rounded-md shadow-md transition duration-300 ease-in-out"
    >
      Update Profile
    </button>
  </div>
</div>*/}
      {/*<div className="flex justify-between font-poppins text-base">
        <div>
          <p className="font-semibold text-xl font-poppins leading-7 tracking-wider text-wider">
            First Name:{" "}
            <span className="text-base font-medium">{first_name}</span>
          </p>
          <p className="font-semibold text-xl font-poppins leading-7 tracking-wider text-wider">
            Last Name:{" "}
            <span className="text-base font-medium">{last_name}</span>
          </p>
          <p className="font-medium text-xl font-poppins leading-7 tracking-wider text-wider">Company: {company_name}</p>
          <p className="font-medium text-xl font-poppins leading-7 tracking-wider text-wider">Business Email: {business_email}</p>
          <p className="font-medium text-xl font-poppins leading-7 tracking-wider text-wider">Business Phone: {business_phone}</p>
          {/*<p className="font-medium text-xl font-poppins leading-7 tracking-wider text-wider">Flat/House number: {flat_house_number}</p>--}
          <p className="font-medium text-xl font-poppins leading-7 tracking-wider text-wider">Street: {street_address}</p>
          <p className="font-medium text-xl font-poppins leading-7 tracking-wider text-wider">City: {city_town}</p>
          <p className="font-medium text-xl font-poppins leading-7 tracking-wider text-wider">County: {county}</p>
          <p className="font-medium text-xl font-poppins leading-7 tracking-wider text-wider">Country: {country}</p>
          <p className="font-medium text-xl font-poppins leading-7 tracking-wider text-wider">PostCode: {post_code}</p>
          <p className="font-medium text-xl font-poppins leading-7 tracking-wider text-wider">Company Logo:</p>
          {!selectedImage && company_logo ? (
            <img
              src={company_logo}
              alt="Company Logo"
              width="100"
              height="100"
              className="mt-2"
            />
          ) : (
            selectedImage && (
              <img
                src={selectedImage}
                alt="Company Logo"
                width="100"
                height="100"
                className="mt-2"
              />
            )
          )}
        </div>

        <button
          onClick={openModal}
          className="bg-[#0875E1] text-white rounded-md flex justify-center items-center p-2 h-[40px]"
        >
          Update Profile
        </button>
      </div>*/}
      {modalIsOpen && (
        <div className="fixed inset-0 flex items-start justify-center bg-black bg-opacity-50 overflow-hidden">
        <div className="flex flex-col justify-center items-center w-full sm:w-[90%] md:w-3/4 lg:w-1/2 bg-[#0875e1] max-h-[90vh] sm:max-h-[80vh] overflow-y-auto pt-5 pb-10 px-4 sm:px-6 lg:px-8 mb-10 rounded-lg shadow-lg">
          <Formik
            initialValues={{
              first_name: first_name,
              last_name: last_name,
              company_name: company_name || "",
              description: description || "",
              business_email: business_email || "",
              business_phone: business_phone || "",
              flat_house_number: flat_house_number || "",
              street_address: street_address || "",
              city_town: city_town || "",
              county: county || "",
              post_code: post_code || "",
              country: country || "",
              company_logo: company_logo || "",
            }}
            onSubmit={handleUpdateProfile}
          >
            {({ handleSubmit, handleBlur, handleChange, setFieldValue, values }) => (
              <Form
                onSubmit={handleSubmit}
                className="w-full text-white flex flex-col mt-[900px]"
              >
                {/* Title */}
                <p className="text-xl font-semibold tracking-wider font-poppins text-center mb-4">
                  Update Organization Profile
                </p>
      
                {/* Form Fields - Responsive Grid Layout */}
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-4">
                  {/* First Name */}
                  <div>
                    <label className="font-medium text-base tracking-wider">
                      First Name
                    </label>
                    <div className="relative flex items-center mt-1">
                      <IoPersonCircle className="absolute left-3 text-gray-500 w-6 h-6" />
                      <Field
                        id="first_name"
                        name="first_name"
                        type="text"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.first_name}
                        placeholder="Enter your First Name"
                        className="w-full text-sm p-3 pl-10 font-poppins rounded-full tracking-wide border border-[#0875E1] text-black"
                      />
                    </div>
                  </div>
      
                  {/* Last Name */}
                  <div>
                    <label className="font-medium text-base tracking-wider">
                      Last Name
                    </label>
                    <div className="relative flex items-center mt-1">
                      <IoPersonCircle className="absolute left-3 text-gray-500 w-6 h-6" />
                      <Field
                        id="last_name"
                        name="last_name"
                        type="text"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.last_name}
                        placeholder="Enter your Last Name"
                        className="w-full text-sm p-3 pl-10 font-poppins rounded-full tracking-wide border border-[#0875E1] text-black"
                      />
                    </div>
                  </div>
      
                  {/* Company Name */}
                  <div className="sm:col-span-2">
                    <label className="font-medium text-base tracking-wider">
                      Company Name
                    </label>
                    <div className="relative flex items-center mt-1">
                      
                      <Field
                        id="company_name"
                        name="company_name"
                        type="text"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.company_name}
                        placeholder="Enter your Company Name"
                        className="w-full text-sm p-3 pl-10 font-poppins rounded-full tracking-wide border border-[#0875E1] text-black"
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-2">
                    <label className="font-medium text-base tracking-wider">
                      Description
                    </label>
                    <div className="relative flex items-center mt-1">
                      <Field
                        id="description"
                        name="description"
                        type="text"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.description}
                        placeholder="Brief description"
                        className="w-full text-sm p-3 pl-10 font-poppins rounded-full tracking-wide border border-[#0875E1] h-15 text-black"
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-2">
                    <label className="font-medium text-base tracking-wider">
                      Business Email
                    </label>
                    <div className="relative flex items-center mt-1">
                      
                      <Field
                        id="business_email"
                        name="business_email"
                        type="text"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.business_email}
                        placeholder="Enter your Business Email"
                        className="w-full text-sm p-3 pl-10 font-poppins rounded-full tracking-wide border border-[#0875E1] h-15 text-black"
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-2">
                    <label className="font-medium text-base tracking-wider">
                      Business Phone
                    </label>
                    <div className="relative flex items-center mt-1">
                      
                      <Field
                        id="business_phone"
                        name="business_phone"
                        type="text"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.business_phone}
                        placeholder="Enter your Business Phone"
                        className="w-full text-sm p-3 pl-10 font-poppins rounded-full tracking-wide border border-[#0875E1] h-15 text-black"
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-2">
                    <label className="font-medium text-base tracking-wider">
                      Flat/House number
                    </label>
                    <div className="relative flex items-center mt-1">
                      
                      <Field
                        id="flat_house_number"
                        name="flat_house_number"
                        type="text"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.flat_house_number}
                        placeholder="Enter your Flat/House number"
                        className="w-full text-sm p-3 pl-10 font-poppins rounded-full tracking-wide border border-[#0875E1] h-15 text-black"
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-2">
                    <label className="font-medium text-base tracking-wider">
                      Street Address
                    </label>
                    <div className="relative flex items-center mt-1">
                      
                    <Field
                        id="street_address"
                        name="street_address"
                        type="text"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.street_address}
                        placeholder="Enter your Street"
                        className="w-full text-sm p-3 pl-10 font-poppins rounded-full tracking-wide border border-[#0875E1] h-15 text-black"
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-2">
                    <label className="font-medium text-base tracking-wider">
                      City/Town
                    </label>
                    <div className="relative flex items-center mt-1">
                      
                      <Field
                        id="city_town"
                        name="city_town"
                        type="text"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.city_town}
                        placeholder="Enter your city/town"
                        className="w-full text-sm p-3 pl-10 font-poppins rounded-full tracking-wide border border-[#0875E1] h-15 text-black"
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-2">
                    <label className="font-medium text-base tracking-wider">
                      County
                    </label>
                    <div className="relative flex items-center mt-1">
                      
                    <Field
                        id="county"
                        name="county"
                        type="text"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.county}
                        placeholder="Enter your county"
                        className="w-full text-sm p-3 pl-10 font-poppins rounded-full tracking-wide border border-[#0875E1] h-15 text-black"
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-2">
                    <label className="font-medium text-base tracking-wider">
                      Country
                    </label>
                    <div className="relative flex items-center mt-1">
                      
                    <Field
                        id="country"
                        name="country"
                        type="text"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.country}
                        placeholder="Eg: United Kingdom"
                        className="w-full text-sm p-3 pl-10 font-poppins rounded-full tracking-wide border border-[#0875E1] h-15 text-black"
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-2">
                    <label className="font-medium text-base tracking-wider">
                      Postcode
                    </label>
                    <div className="relative flex items-center mt-1">
                      
                      <Field
                        id="post_code"
                        name="post_code"
                        type="text"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.post_code}
                        placeholder="Enter your postcode"
                        className="w-full text-sm p-3 pl-10 font-poppins rounded-full tracking-wide border border-[#0875E1] h-15 text-black"
                      />
                    </div>
                  </div>

                  <div className="mb-2">
                    <p className="font-[400px] text-base text-wider tracking-wider font-poppins">
                      Company Logo
                    </p>
                    <input
                      type="file"
                      id="company_logo"
                      name="company_logo"
                      onChange={(e) => {
                        handleImageChange(e);
                        setFieldValue("company_logo", e.currentTarget.files[0]); // Set the field value
                      }}
                      accept="image/*"
                      className="w-full text-sm p-3 font-poppins rounded-full tracking-wide border border-[#0875E1] h-15 text-black"
                    />
                    {selectedImage && (
                      <img
                        src={URL.createObjectURL(selectedImage)} // Display the new image
                        alt="Preview"
                        width="100"
                        className="mt-2"
                      />
                    )}
                    {!selectedImage && company_logo && (
                      <img
                        src={company_logo} // Display the existing image
                        alt="Company Logo"
                        width="100"
                        className="mt-2"
                      />
                    )}
                  </div>
      
                  {/* Additional fields... */}
                </div>
      
                {/* Footer - Buttons */}
                <div className="flex justify-between mt-5">
                  <button
                    type="button"
                    onClick={closeModal}
                    className="bg-gray-500 hover:bg-gray-600 text-white rounded-md px-4 py-2 transition duration-200"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="bg-[#0875E1] hover:bg-blue-700 text-white rounded-md px-4 py-2 transition duration-200"
                  >
                    Update
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>)}
      </div>
      
        {/*<div className="fixed inset-0 flex items-start justify-end bg-black bg-opacity-50 overflow-hidden">
          <div className="flex flex-col justify-center items-center lg:w-1/2 bg-[#0875e1] max-h-[70vh] overflow-y-auto pt-[100px] mb-10">
            <Formik
              initialValues={{
                first_name: first_name,
                last_name: last_name,
                company_name: company_name,
                description: description,
                business_email: business_email,
                business_phone: business_phone,
                flat_house_number: flat_house_number,
                street_address: street_address,
                city_town: city_town,
                county: county,
                post_code: null,
                country: null,
                company_logo: company_logo || "",
              }}
              onSubmit={handleUpdateProfile}
            >
              {({
                handleSubmit,
                handleBlur,
                handleChange,
                setFieldValue,
                values,
              }) => (
                <Form
                  onSubmit={handleSubmit}
                  className="w-full max-w-md text-white flex flex-col mt-[150px] mb-10"
                >
                  <p className="text-xl font-semibold text-wider tracking-wider font-poppins text-center mb-2">
                    Update Organization Profile
                  </p>
                  <div className="mb-2 flex flex-col">
                    <div className="flex gap-4">
                      <div>
                        <p className="font-[400px] text-base text-wider tracking-wider">
                          First Name
                        </p>
                        <div className="relative flex items-center">
                          <IoPersonCircle className="absolute left-3 text-gray-500 w-6 h-6" />
                          <Field
                            id="first_name"
                            name="first_name"
                            type="text"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.first_name}
                            placeholder="Enter your First Name"
                            className="w-full text-sm p-3 pl-10 font-poppins rounded-full tracking-wide border border-[#0875E1] h-15 text-black"
                          />
                        </div>
                      </div>
                      <div>
                        <p className="font-[400px] text-base text-wider tracking-wider">
                          Last Name
                        </p>
                        <div className="relative flex items-center">
                          <IoPersonCircle className="absolute left-3 text-gray-500 w-6 h-6" />
                          <Field
                            id="last_name"
                            name="last_name"
                            type="text"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.last_name}
                            placeholder="Enter your Last Name"
                            className="w-full text-sm p-3 pl-10 font-poppins rounded-full tracking-wide border border-[#0875E1] h-15 text-black"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mb-2">
                    <p className="font-[400px] text-base text-wider tracking-wider font-poppins">
                      Company Name
                    </p>
                    <div className="relative flex items-center">
                      <MdOutlineMailOutline className="absolute left-3 text-gray-500 w-6 h-6" />
                      <Field
                        id="company_name"
                        name="company_name"
                        type="text"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.company_name}
                        placeholder="Enter your Company Name"
                        className="w-full text-sm p-3 pl-10 font-poppins rounded-full tracking-wide border border-[#0875E1] h-15 text-black"
                      />
                    </div>
                  </div>

                  <div className="mb-2">
                    <p className="font-[400px] text-base text-wider tracking-wider font-poppins">
                      Description
                    </p>
                    <div className="relative flex items-center">
                      <MdOutlineMailOutline className="absolute left-3 text-gray-500 w-6 h-6" />
                      <Field
                        id="description"
                        name="description"
                        type="text"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.description}
                        placeholder="Brief description"
                        className="w-full text-sm p-3 pl-10 font-poppins rounded-full tracking-wide border border-[#0875E1] h-15 text-black"
                      />
                    </div>
                  </div>

                  <div className="mb-2">
                    <p className="font-[400px] text-base text-wider tracking-wider font-poppins">
                      Business Email
                    </p>
                    <div className="relative flex items-center">
                      <MdOutlineMailOutline className="absolute left-3 text-gray-500 w-6 h-6" />
                      <Field
                        id="business_email"
                        name="business_email"
                        type="text"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.business_email}
                        placeholder="Enter your Business Email"
                        className="w-full text-sm p-3 pl-10 font-poppins rounded-full tracking-wide border border-[#0875E1] h-15 text-black"
                      />
                    </div>
                  </div>

                  <div className="mb-2">
                    <p className="font-[400px] text-base text-wider tracking-wider font-poppins">
                      Business Phone
                    </p>
                    <div className="relative flex items-center">
                      <IoCallOutline className="absolute left-3 text-gray-500 w-6 h-6" />
                      <Field
                        id="business_phone"
                        name="business_phone"
                        type="text"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.business_phone}
                        placeholder="Enter your Business Phone"
                        className="w-full text-sm p-3 pl-10 font-poppins rounded-full tracking-wide border border-[#0875E1] h-15 text-black"
                      />
                    </div>
                  </div>

                  <div className="mb-2">
                    <p className="font-[400px] text-base text-wider tracking-wider font-poppins">
                      Flat/House number
                    </p>
                    <div className="relative flex items-center">
                      <FaMapMarkerAlt className="absolute left-3 text-gray-500 w-6 h-6" />
                      <Field
                        id="flat_house_number"
                        name="flat_house_number"
                        type="text"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.flat_house_number}
                        placeholder="Enter your City/Town"
                        className="w-full text-sm p-3 pl-10 font-poppins rounded-full tracking-wide border border-[#0875E1] h-15 text-black"
                      />
                    </div>
                  </div>

                  <div className="mb-2">
                    <p className="font-[400px] text-base text-wider tracking-wider font-poppins">
                      Street Address
                    </p>
                    <div className="relative flex items-center">
                      <FaMapMarkerAlt className="absolute left-3 text-gray-500 w-6 h-6" />
                      <Field
                        id="street_address"
                        name="street_address"
                        type="text"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.street_address}
                        placeholder="Enter your State"
                        className="w-full text-sm p-3 pl-10 font-poppins rounded-full tracking-wide border border-[#0875E1] h-15 text-black"
                      />
                    </div>
                  </div>

                  <div className="mb-2">
                    <p className="font-[400px] text-base text-wider tracking-wider font-poppins">
                      City Town
                    </p>
                    <div className="relative flex items-center">
                      <FaMapMarkerAlt className="absolute left-3 text-gray-500 w-6 h-6" />
                      <Field
                        id="city_town"
                        name="city_town"
                        type="text"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.city_town}
                        placeholder="Enter your Street Address 1"
                        className="w-full text-sm p-3 pl-10 font-poppins rounded-full tracking-wide border border-[#0875E1] h-15 text-black"
                      />
                    </div>
                  </div>

                  <div className="mb-2">
                    <p className="font-[400px] text-base text-wider tracking-wider font-poppins">
                      County
                    </p>
                    <div className="relative flex items-center">
                      <FaMapMarkerAlt className="absolute left-3 text-gray-500 w-6 h-6" />
                      <Field
                        id="county"
                        name="county"
                        type="text"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.county}
                        placeholder="Enter your Street Address 1"
                        className="w-full text-sm p-3 pl-10 font-poppins rounded-full tracking-wide border border-[#0875E1] h-15 text-black"
                      />
                    </div>
                  </div>

                  <div className="mb-2">
                    <p className="font-[400px] text-base text-wider tracking-wider font-poppins">
                      POst Code
                    </p>
                    <div className="relative flex items-center">
                      <FaMapMarkerAlt className="absolute left-3 text-gray-500 w-6 h-6" />
                      <Field
                        id="post_code"
                        name="post_code"
                        type="text"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.post_code}
                        placeholder="Enter your Street Address 2"
                        className="w-full text-sm p-3 pl-10 font-poppins rounded-full tracking-wide border border-[#0875E1] h-15 text-black"
                      />
                    </div>
                  </div>

                  <div className="mb-2">
                    <p className="font-[400px] text-base text-wider tracking-wider font-poppins">
                      Country
                    </p>
                    <div className="relative flex items-center">
                      <FaMapMarkerAlt className="absolute left-3 text-gray-500 w-6 h-6" />
                      <Field
                        id="country"
                        name="country"
                        type="text"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.country}
                        placeholder="Enter your Origin Country"
                        className="w-full text-sm p-3 pl-10 font-poppins rounded-full tracking-wide border border-[#0875E1] h-15 text-black"
                      />
                    </div>
                  </div>

                  <div className="mb-2">
                    <p className="font-[400px] text-base text-wider tracking-wider font-poppins">
                      Company Logo
                    </p>
                    <input
                      type="file"
                      id="company_logo"
                      name="company_logo"
                      onChange={(e) => {
                        handleImageChange(e);
                        setFieldValue("company_logo", e.currentTarget.files[0]); // Set the field value
                      }}
                      accept="image/*"
                      className="w-full text-sm p-3 font-poppins rounded-full tracking-wide border border-[#0875E1] h-15 text-black"
                    />
                    {selectedImage && (
                      <img
                        src={URL.createObjectURL(selectedImage)} // Display the new image
                        alt="Preview"
                        width="100"
                        className="mt-2"
                      />
                    )}
                    {!selectedImage && company_logo && (
                      <img
                        src={company_logo} // Display the existing image
                        alt="Company Logo"
                        width="100"
                        className="mt-2"
                      />
                    )}
                  </div>

                  <div className="flex justify-between mt-5">
                    <button
                      type="button"
                      onClick={closeModal}
                      className="bg-[#B5B5B5] text-white rounded-md px-4 py-2"
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="bg-[#0875E1] text-white rounded-md px-4 py-2"
                    >
                      Update
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>*/}
    </div>
  );
};

export default Update;
